/** @format */

import React, { ReactNode } from "react";
import PuffLoader from "react-spinners/PuffLoader";
import { SerializedStyles, css } from "@emotion/react";

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
 className: string;
 disabled?: boolean;
 loading?: boolean;
 children?: ReactNode;
 theme?: string;
}

const Button = ({
 className,
 disabled,
 loading,
 children,
 theme,
 ...props
}: Props) => {
 return (
  <button
   className={`btn  ${className}`}
   {...props}
   disabled={disabled || loading}
  >
   {loading ? (
    <PuffLoader size={35} color={theme == "dark" ? "#000" : "#fff"} />
   ) : (
    children
   )}
  </button>
 );
};

export default Button;
