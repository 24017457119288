/** @format */

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import AuthComponent from "@components/AuthComponent";
import Button from "@components/Buttons";
import { forgotPasswordAction } from "@redux/actions/authentication";
import { ForgotValidator } from "@helpers/Validator";
import { hasValues } from "@helpers/String";
import { InputField } from "@components/Inputs";
import { AlertResponseComponent } from "@components/AlertComponenr";

type User = {
 email?: string;
};

const ForgotPassword = (props: any) => {
 const [state, setState] = useState({} as User);
 const [inputError, setInputError] = useState({} as User);
 //  const [error, setError] = useState(null);

 const handleInputChange = (e: any) => {
  setState((prev) => ({ ...prev, [e.target.name]: e.target.value }));
 };

 const handleSubmit = (e: any) => {
  e.preventDefault();

  const checkForErrors = ForgotValidator(state);
  if (!hasValues(checkForErrors)) {
   setInputError(checkForErrors);
   return;
  }
  props.forgotPassword(state);
  setInputError({});
 };

 return (
  <>
   <AuthComponent align="justify-content-center" maxWidth="700px">
    <div className="mt-auto" style={{ maxWidth: "700px" }}>
     {props.response && <AlertResponseComponent context={props.response} />}
     <h1 className="pt-3 pb-2 pb-lg-3">Forgot your password?</h1>
     <p className="pb-2">
      Change your password in three easy steps. This helps to keep your new
      password secure.
     </p>
     <ul className="list-unstyled pb-2 pb-lg-0 mb-4 mb-lg-5">
      <li className="d-flex mb-2">
       <span className="text-primary fw-semibold me-2">1.</span>Fill in your
       email address below.
      </li>
      <li className="d-flex mb-2">
       <span className="text-primary fw-semibold me-2">2.</span>We'll email you
       a temporary code.
      </li>
      <li className="d-flex mb-2">
       <span className="text-primary fw-semibold me-2">3.</span>Use the code to
       change your password.
      </li>
     </ul>
     <div className="card dark-mode border-0 bg-primary">
      <form className="card-body">
       <div className="mb-4">
        <InputField
         className="ps-5"
         icon="ai-mail fs-lg"
         name="email"
         errorMessage={inputError.email}
         onChange={handleInputChange}
        />
       </div>
       <Button
        className="btn-lg btn-light"
        loading={props.isLoading}
        theme="dark"
        onClick={handleSubmit}
       >
        Get new password
       </Button>
      </form>
     </div>
    </div>
   </AuthComponent>
  </>
 );
};

const mapStateToProps = (state: any) => ({
 response: state.auth.response,
 isLoading: state.auth.isLoading,
});

const mapDispatchToProps = (dispatch: any) => ({
 forgotPassword: (data: any) => dispatch(forgotPasswordAction(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
