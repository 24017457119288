/** @format */

import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PAGES_URL from "./routes";

interface Props {
 // TODO: correct type for this
 component: any;
 isAuthenticated?: boolean;
 exact?: boolean;
 path?: string;
 session?: any;
}

const AuthRoute = ({
 component: Component,
 isAuthenticated,
 session,
 ...rest
}: Props) => (
 <>
  <Route
   {...rest}
   render={(props) => {
    return isAuthenticated ? (
     <Route render={() => <Component {...props} />} />
    ) : (
     <Redirect to={{ pathname: PAGES_URL.LOGIN }} />
    );
   }}
  />
 </>
);

const mapStatetoProps = (state: any) => ({
 isAuthenticated: state.auth.isAuthenticated,
 session: state.auth.session,
});

export default connect(mapStatetoProps, null)(AuthRoute);
