/** @format */

// Loads persisted state from local storage
const loadState = () => {
 try {
  const serializedState = localStorage.getItem("state");
  if (serializedState === null) {
   return undefined;
  }
  return JSON.parse(serializedState);
 } catch (err) {
  return undefined;
 }
};

// Stores persisted state to local storage
const saveState = (state) => {
 try {
  const serializedState = JSON.stringify(state);
  localStorage.setItem("state", serializedState);
 } catch (err) {
  //
 }
};

export { loadState, saveState };
