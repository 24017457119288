/** @format */

import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import FeatherIcon from "feather-icons-react";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PAGES_URL from "@routers/routes";
import supabase from "@lib/supabase/client";
import { BsListColumnsReverse, BsPlusCircleFill } from "react-icons/bs";

interface PotItem {
 pot_id: string;
 user_id: string;
 pot_type_id: string;
 name: string;
 monthly_contribution_start_date: string;
 monthly_contribution_fee: number;
 starting_contribution_fee: number;
 base_currency: string;
 risk_mandate_id: string;
 meta_data: {
  pot_type_name: string;
  risk_mandate_name: string;
 };
 created_at: string;
}

const RecentPots = () => {
 const [items, setItems] = useState<PotItem[]>([]);
 const [loading, setLoading] = useState(true);

 const slider = React.useRef<Slider | null>(null);

 const next = () => {
  slider.current?.slickNext();
 };

 const previous = () => {
  slider.current?.slickPrev();
 };

 useEffect(() => {
  const fetchPots = async () => {
   const { data, error } = await supabase.functions.invoke("pots/list", {
    method: "GET",
   });

   if (error) {
    console.error(error);
    setLoading(false); // Set loading to false on error
    return;
   }

   setItems((data.data as PotItem[]) || []);
   setLoading(false); // Set loading to false once data has been set
  };

  fetchPots();
 }, []);

 return (
  <>
   {loading ? (
    <div>Loading...</div> // Show a loading message or spinner while loading
   ) : items.length > 0 ? (
    <>
     <div className="d-flex  mb-4">
      <h6 className="fw-medium h5">Recent Pots</h6>
      <div className="ms-auto d-none d-lg-block">
       <Link
        to={PAGES_URL.NEW_INVESTMENT}
        className="fw-bold text-underline me-lg-3"
       >
        <BsPlusCircleFill /> Create pot
       </Link>
       <Link to={PAGES_URL.VIEW_ALL_POT} className="fw-bold text-underline">
        <BsListColumnsReverse /> View all
       </Link>
      </div>
     </div>
     <Slider
      ref={slider}
      infinite={false} // Disable infinite loop
      slidesToShow={items.length < 2 ? items.length : 2}
      speed={500}
      className="mb-5"
      centerMode={false}
      centerPadding={"70px"}
      responsive={[
       {
        breakpoint: 500,
        settings: "unslick",
       },
      ]}
     >
      {items.map((item, idx) => (
       <div className="col" key={idx}>
        <div
         className={`me-5 card rounded-1`}
         style={{ borderLeft: "3px solid #5f236e" }}
        >
         <div className="card-body p-4">
          <h5 className="h6">
           {item.name}{" "}
           <span className="fw-light">({item.meta_data.pot_type_name})</span>
          </h5>
          <h4 className="pt-3 mb-0 text-primary">
           $ {item.starting_contribution_fee}
          </h4>
          <span className="text-success">+34%</span>

          <div className="d-flex mt-4">
           <p>{item.meta_data.risk_mandate_name}</p>
           <Link
            className="ms-auto text-underline"
            to={{
             pathname: PAGES_URL.DETAILS,
             search: `id=${item.pot_id}`,
             state: { potId: item.pot_id },
            }}
           >
            View
           </Link>
          </div>
         </div>
        </div>
       </div>
      ))}
     </Slider>
    </>
   ) : (
    <div className="card card-body  bg-white mb-5 " style={{ height: "31%" }}>
     <div className="d-flex justify-content-center align-items-center h-100">
      <div className="col-lg-6">
       <h4 className="fw-normal  text-center text-dark h4">
        Get started with your financial investments by creating a pot
       </h4>
       <Link to={PAGES_URL.NEW_INVESTMENT} className="text-decoration-none">
        <figcaption className="blockquote-footer text-primary ">
         Get started now
        </figcaption>
       </Link>
      </div>
     </div>
    </div>
   )}
  </>
 );
};

export default RecentPots;
