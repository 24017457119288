/** @format */

import { InvestmentPotActionTypes } from "@redux/reducers/types";

const initialState = {
 potTypeData: {
  potType: "",
 },
 potData: {},
 error: null,
};

function potsReducer(state = initialState, action: any) {
 switch (action.type) {
  case InvestmentPotActionTypes.SET_POT_DATA:
   return {
    ...state,
    potData: {
     ...state.potData,
     ...action.payload,
    },
   };
  case InvestmentPotActionTypes.SET_POT_ERROR:
   return {
    ...state,
    error: action.payload,
   };
  case InvestmentPotActionTypes.RESET_INVESTMENT_POT:
   return initialState;
  case InvestmentPotActionTypes.SET_POT_TYPE_DATA:
   return {
    ...state,
    potTypeData: action.payload,
   };
  default:
   return state;
 }
}

export default potsReducer;
