/** @format */

export async function setHeaders(session: any) {
 localStorage.setItem("accessToken", session.access_token);
 localStorage.setItem("refreshToken", session.refresh_token);
}

export const headers = {
 "Content-Type": "application/x-www-form-urlencoded",
 Accept: "application/json",
 Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
 "X-Supabase-Auth": `${localStorage.getItem("refreshToken")}`,
};
