/** @format */
import React from "react";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { BsFillInfoCircleFill } from "react-icons/bs";

type Props = {
 options?: any;
 seriesData?: any;
};

const ProjectionChart: React.FC<Props> = () => {
 const options: ApexOptions = {
  chart: {
   height: 500,
   type: "area",
   fontFamily: "Satoshi",
   toolbar: {
    show: false,
   },
   zoom: {
    enabled: false,
   },
  },
  stroke: {
   curve: "smooth",
   colors: ["#5F236E"],
  },
  dataLabels: {
   enabled: false,
  },
  fill: {
   type: "gradient",
   colors: ["#dbdecf"],
  },
  series: [
   {
    data: [
     2450, 3000, 3034, 3004, 3043, 3229, 3104, 3267, 3176, 3267, 3389, 3177,
    ],
   },
  ],
  labels: [
   "Jan",
   "Feb",
   "Mar",
   "Apr",
   "May",
   "Jun",
   "Jul",
   "Aug",
   "Sept",
   "Aug",
   "Nov",
   "Dec",
  ],
  xaxis: {
   labels: {
    datetimeFormatter: {
     year: "yyyy",
     month: "MMM 'yy",
     day: "dd MMM",
     hour: "HH:mm",
    },
   },
  },
 };

 return (
  <div className="card rounded-1 border-0">
   <div className="card-body">
    <div id="chart">
     <ReactApexChart
      options={options}
      series={options.series}
      type="area"
      height={500}
     />
    </div>
   </div>
  </div>
 );
};

export default ProjectionChart;
