/** @format */

import React from "react";
import { Link } from "react-router-dom";
import {
 iPhone13,
 Banner01,
 AppstoreLight,
 PlaystoreLight,
 Hero06,
 Logo,
} from "@assets/images";
import AppComponent from "@components/AppComponent";
import PAGES_URL from "@routers/routes";

const Home = () => {
 return (
  <>
   <AppComponent>
    <section className="d-flex min-vh-100 position-relative overflow-hidden py-5">
     <div className="container d-flex flex-column justify-content-center position-relative zindex-2 pt-sm-3 pt-md-4 pt-xl-5 pb-1 pb-sm-3 pb-lg-4 pb-xl-5">
      <div className="row flex-lg-nowrap align-items-center pb-5 pt-2 pt-lg-4 pt-xl-0 mt-lg-4 mt-xl-0">
       <div className="col-lg-6 order-lg-2 ms-lg-4 mb-2 mb-lg-0">
        <div
         className="parallax order-lg-2 mx-auto"
         style={{ maxWidth: "740px" }}
        >
         <img className="img-fluid" src={iPhone13} alt="" />
        </div>
       </div>
       <div className="col-lg-6 order-lg-1 text-center text-lg-start me-xl-5">
        <span className="badge bg-light text-primary fs-sm">
         Africa's fastest growing financial platform&nbsp; 🚀
        </span>
        <h1 className="display-4 py-3 my-2 mb-xl-3">Investing Made Easy</h1>
        <ul className="list-unstyled d-table text-start mx-auto mx-lg-0 mb-0">
         <li className="d-flex pb-2 mb-1">
          <i className="ai-check-alt text-primary lead me-2"></i>
          Easy to use App
         </li>
         <li className="d-flex pb-2 mb-1">
          <i className="ai-check-alt text-primary lead me-2"></i>
          Ready-made portfolios
         </li>
         <li className="d-flex pb-2 mb-1">
          <i className="ai-check-alt text-primary lead me-2"></i>
          Easily withdraw and deposit cash
         </li>
        </ul>
        <div className="d-flex pt-4 pt-xl-5">
         <Link
          className="btn btn-lg btn-primary w-100 w-sm-auto"
          to={PAGES_URL.SIGNUP}
         >
          Create a free account
         </Link>
        </div>
        <div className="d-flex  justify-content-lg-start mt-4">
         <a className="btn btn-dark btn-lg px-3 py-2 me-3" href="#">
          <img
           className="d-dark-mode-none mx-1"
           src={AppstoreLight}
           width="136"
           alt="App Store"
          />
         </a>
         <a className="btn btn-dark btn-lg px-3 py-2" href="#">
          <img
           className="d-dark-mode-none mx-1"
           src={PlaystoreLight}
           width="135"
           alt="Google Play"
          />
         </a>
        </div>
       </div>
      </div>
      <div className="row row-cols-3 row-cols-sm-4 row-cols-lg-5 row-cols-xl-6 justify-content-center align-items-center g-4 pt-lg-3 pt-xl-4">
       <div className="col">
        <img
         className="d-block my-1 mx-auto"
         src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b5/PayPal.svg/2560px-PayPal.svg.png"
         width="145"
         alt="Foster"
        />
       </div>
       <div className="col">
        <img
         className="d-block my-1 mx-auto"
         src="https://upload.wikimedia.org/wikipedia/commons/9/9e/Flutterwave_Logo.png"
         width="140"
         alt="Klinos"
        />
       </div>
       <div className="col">
        <img
         className="d-block my-1 mx-auto"
         src="https://www.freepnglogos.com/uploads/mastercard-png/mastercard-logo-logotype-logok-25.png"
         width="160"
         alt="Champion"
        />
       </div>
       <div className="col">
        <img
         className="d-block my-1 mx-auto"
         src="https://upload.wikimedia.org/wikipedia/commons/0/0b/Paystack_Logo.png"
         width="130"
         alt="Airbnb"
        />
       </div>
       <div className="col">
        <img
         className="d-block my-1 mx-auto"
         src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/ba/Stripe_Logo%2C_revised_2016.svg/2560px-Stripe_Logo%2C_revised_2016.svg.png"
         width="160"
         alt="Starcraft"
        />
       </div>
       <div className="col">
        <img
         className="d-block my-1 mx-auto"
         src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/1b/Skrill_logo.svg/2560px-Skrill_logo.svg.png"
         width="150"
         alt="Alpine"
        />
       </div>
      </div>
     </div>
    </section>
    <section className="container py-5 my-md-2 my-lg-3 my-xl-4 my-xxl-5">
     <div className="row justify-content-between align-items-center py-1 py-sm-3 py-md-4 my-lg-2">
      <div className="col-lg-5 ">
       <img src={Banner01} alt="" />
       {/* <h2 className="h1 text-center text-lg-start pb-3 pb-lg-1">
        Our servises
       </h2>
       <ul className="list-unstyled d-none d-lg-block pb-3 mb-3 mb-lg-4">
        <li className="d-flex pt-2">
         <i className="ai-check-alt fs-4 text-primary mt-n1 me-2"></i>Individual
         approach to the brand
        </li>
        <li className="d-flex pt-2">
         <i className="ai-check-alt fs-4 text-primary mt-n1 me-2"></i>We
         guarantee the result after a month
        </li>
        <li className="d-flex pt-2">
         <i className="ai-check-alt fs-4 text-primary mt-n1 me-2"></i>Completing
         tasks exactly on time
        </li>
       </ul>
       <a className="btn btn-primary d-none d-lg-inline-flex" href="#">
        See all servises
       </a> */}
      </div>
      <div className="col-lg-6 col-xl-7 col-xxl-6">
       <div className="row row-cols-1 row-cols-sm-2">
        <div className="col">
         <div className="card border-0 bg-faded-primary">
          <div className="card-body">
           <i className="ai-chart fs-1 text-primary d-block mb-3"></i>
           <h3 className="h4">Investing</h3>
           <p className="fs-sm">
            Our seamless design lets you open an account, deposit cash and
            choose a suitable investment portfolio.
           </p>
          </div>
         </div>
         <div className="card border-0 bg-faded-info mt-4">
          <div className="card-body">
           <i className="ai-bulb-alt fs-1 text-info d-block mb-3"></i>
           <h3 className="h4">Safety of Assets</h3>
           <p className="fs-sm">
            A regulated by SEC, Zambia financial authority and use of trusted
            and experienced partners to manage client assets.
           </p>
          </div>
         </div>
        </div>
        <div className="col pt-lg-3">
         <div className="card border-0 bg-faded-warning mt-4 mt-sm-0 mt-lg-4">
          <div className="card-body">
           <i className="ai-mail-filled fs-1 text-warning d-block mb-3"></i>
           <h3 className="h4">Support</h3>
           <p className="fs-sm">
            We understand investing can be both exciting and daunting. We
            support our clients throughout their entire investment journey
           </p>
          </div>
         </div>
         <div className="card border-0 bg-faded-danger mt-4">
          <div className="card-body">
           <i className="ai-telegram fs-2 text-danger d-block mb-3"></i>
           <h3 className="h4">Secure</h3>
           <p className="fs-sm">--- empty ----</p>
          </div>
         </div>
        </div>
       </div>
       <div className="d-lg-none text-center pt-3 mt-3 mt-sm-4">
        <a className="btn btn-primary" href="#">
         See all servises
        </a>
       </div>
      </div>
     </div>
    </section>
    <section className="container pt-5 pb-sm-3 mt-2 mt-sm-4 mb-2 mb-md-3 mb-lg-4 mb-xxl-5">
     <div className="row align-items-center justify-content-between  pt-xl-2 pb-5 mb-lg-2 mb-xl-3 mb-xxl-4">
      <div className="col-md-8 order-lg-2 col-lg-7 mb-4 mb-md-0">
       <a className="d-block position-relative" href="portfolio-single-v1.html">
        <div
         className="bg- rounded-5 position-absolute top-0 start-0 w-100 h-100"
         data-aos="zoom-in"
         data-aos-duration="600"
         data-aos-offset="250"
        ></div>
        <img
         className="d-block position-relative zindex-2 mx-auto"
         src={Hero06}
         alt="Image"
         data-aos="fade-in"
         data-aos-duration="400"
         data-aos-offset="250"
        />
       </a>
      </div>
      <div
       className="col-md-5 col-lg-6 order-1 col-xl-4 "
       data-aos="fade-up"
       data-aos-duration="400"
       data-aos-offset="170"
      >
       <div className="ps-md-3 ps-lg-0">
        <img
         className="d-block d-dark-mode-none mb-2 mb-lg-3"
         src={Logo}
         width={70}
         alt="Champion"
        />
        <img
         className="d-none d-dark-mode-block mb-2 mb-lg-3"
         src="assets/img/portfolio/brands/champion-blue-light.svg"
         alt="Champion"
        />
        <h2 className="h4">Seamless funds withdrawal</h2>
        <p className="fs-sm pb-3 pb-lg-4 mb-3">
         Senga offers a wide variety of secure and seamless payment gateways
         tailored to your needs, you can credit and withdraw funds into your
         wallet with ease.
        </p>
        <a className="btn btn btn-primary" href="portfolio-single-v1.html">
         Start Investing
        </a>
        <div className="d-flex align-items-center pt-2 pt-lg-3 mt-3">
         <img
          className="col d-block me-4"
          src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b5/PayPal.svg/2560px-PayPal.svg.png"
          width="90"
          alt="Vue.js"
         />
         <img
          className="col me-4"
          src="https://upload.wikimedia.org/wikipedia/commons/9/9e/Flutterwave_Logo.png"
          width="90"
          alt="Vue.js"
         />
         <img
          className="col d-block me-4"
          src="https://www.freepnglogos.com/uploads/mastercard-png/mastercard-logo-logotype-logok-25.png"
          width="95"
          alt="Deloitte"
         />
         {/* <img
          className="col"
          src="https://upload.wikimedia.org/wikipedia/commons/0/0b/Paystack_Logo.png"
          width="95"
          alt="Deloitte"
         /> */}
        </div>
       </div>
      </div>
     </div>
    </section>
    <section className="container">
     <div className="bg-primary rounded-5 position-relative overflow-hidden py-5 px-4 px-sm-5">
      <div
       className="d-none d-dark-mo position-absolute top-0 start-0 w-100 h-100"
       style={{ backgroundColor: "rgba(255,255,255, .02)" }}
      ></div>
      <svg
       className="position-absolute start-0 bottom-0 zindex-2"
       width="713"
       height="410"
       viewBox="0 0 713 410"
       fill="none"
       stroke="white"
       strokeOpacity="0.12"
       xmlns="http://www.w3.org/2000/svg"
      >
       <path d="M682.907 484.533C695.503 444.57 705.228 400.872 691.238 361.386C672.894 309.65 617.745 278.967 563.532 270.375C509.32 261.768 454.065 270.505 399.207 272.603C344.348 274.702 285.906 268.882 241.992 235.917C181.111 190.21 163.777 104.969 109.285 51.7863C63.8512 7.45308 -6.63234 -9.27787 -67.1491 9.9424"></path>
       <path d="M641.482 482.967C653.4 445.169 662.6 403.82 649.367 366.472C632.013 317.53 579.846 288.506 528.564 280.379C477.267 272.236 425.011 280.507 373.121 282.492C321.231 284.477 265.935 278.97 224.403 247.786C166.796 204.54 150.415 123.91 98.8593 73.6098C55.8807 31.6733 -10.8023 15.8433 -68.0415 34.0275"></path>
       <path d="M600.052 481.4C611.277 445.751 619.968 406.784 607.477 371.559C591.113 325.409 541.928 298.044 493.561 290.383C445.194 282.705 395.92 290.508 346.984 292.363C298.047 294.234 245.913 289.041 206.762 259.639C152.445 218.868 137.002 142.834 88.3989 95.4004C47.876 55.8447 -14.9912 40.9323 -68.9688 58.0798"></path>
       <path d="M558.622 479.833C569.168 446.349 577.336 409.73 565.601 376.644C550.227 333.287 504.025 307.582 458.589 300.385C413.153 293.173 366.862 300.493 320.893 302.251C274.925 304.009 225.953 299.128 189.168 271.507C138.142 233.198 123.636 161.774 77.9686 117.223C39.9006 80.0639 -19.166 66.0526 -69.8661 82.164"></path>
       <path d="M517.195 478.267C527.064 446.932 534.691 412.678 523.729 381.732C509.361 341.167 466.125 317.121 423.62 310.39C381.115 303.643 337.806 310.496 294.806 312.141C251.807 313.785 205.981 309.216 171.561 283.377C123.825 247.544 110.24 180.715 67.5263 139.03C31.9133 104.268 -23.3529 91.1578 -70.7753 106.233"></path>
      </svg>
      <svg
       className="position-absolute top-0 end-0 zindex-2"
       width="317"
       height="367"
       viewBox="0 0 317 367"
       fill="none"
       stroke="white"
       strokeOpacity="0.12"
       xmlns="http://www.w3.org/2000/svg"
      >
       <path d="M766.907 366.533C779.503 326.57 789.228 282.872 775.238 243.386C756.894 191.65 701.745 160.967 647.532 152.375C593.32 143.768 538.065 152.505 483.207 154.603C428.348 156.702 369.906 150.882 325.992 117.917C265.111 72.2101 247.777 -13.031 193.285 -66.2137C147.851 -110.547 77.3677 -127.278 16.8509 -108.058"></path>
       <path d="M725.482 364.967C737.4 327.169 746.6 285.82 733.367 248.472C716.013 199.53 663.846 170.506 612.564 162.379C561.267 154.236 509.011 162.507 457.121 164.492C405.231 166.477 349.935 160.97 308.403 129.786C250.796 86.5396 234.415 5.91023 182.859 -44.3902C139.881 -86.3267 73.1977 -102.157 15.9585 -83.9725"></path>
       <path d="M684.052 363.4C695.277 327.751 703.968 288.784 691.477 253.559C675.113 207.409 625.928 180.044 577.561 172.383C529.194 164.705 479.92 172.508 430.984 174.363C382.047 176.234 329.913 171.041 290.762 141.639C236.445 100.868 221.002 24.8341 172.399 -22.5996C131.876 -62.1553 69.0088 -77.0677 15.0312 -59.9202"></path>
       <path d="M642.622 361.833C653.168 328.349 661.336 291.73 649.601 258.644C634.227 215.287 588.025 189.582 542.589 182.385C497.153 175.173 450.862 182.493 404.893 184.251C358.925 186.009 309.953 181.128 273.168 153.507C222.142 115.198 207.636 43.7743 161.969 -0.777178C123.901 -37.9361 64.834 -51.9474 14.1339 -35.836"></path>
       <path d="M601.195 360.267C611.064 328.932 618.691 294.678 607.729 263.732C593.361 223.167 550.125 199.121 507.62 192.39C465.115 185.643 421.806 192.496 378.806 194.141C335.807 195.785 289.981 191.216 255.561 165.377C207.825 129.544 194.24 62.7152 151.526 21.0304C115.913 -13.7317 60.6471 -26.8422 13.2247 -11.7668"></path>
      </svg>
      <div className="dark-mode position-relative zindex-3 text-center px-3 px-sm-0 pb-2 py-sm-3 py-md-4 py-lg-5">
       <h2 className="display-3 pt-xl-2">Get Started with Sanga</h2>
       <p className="fs-lg pb-4 pb-lg-5 mb-2 mb-sm-3 mb-lg-0 mb-xl-2">
        Using basic data skills you can analysis and improve your business
        indicators.
       </p>
       <a className="btn btn-lg btn-light mb-lg-2 mb-xl-4" href="#">
        Create a free account
       </a>
      </div>
     </div>
    </section>
   </AppComponent>
  </>
 );
};

export default Home;
