/** @format */

import React from "react";
import { Link } from "react-router-dom";
import PAGES_URL from "@routers/routes";
import { BsArrowUpRight } from "react-icons/bs";

interface Props {
 children?: any;
 progress?: any;
}

const PotDashboard = ({ children, progress }: Props) => {
 return (
  <>
   <div className="d-flex flex-column">
    <div className="row align-items-center ">
     <div className="col-md-6">
      <div className="d-flex justify-content-between bg-primary me-2 me-lg-0 p-4 p-lg-5 text-white invest-dashboard">
       <div className="col-1">
        <BsArrowUpRight size={40} />
       </div>
       <div className="col-10">
        <p className="my-0 small fw-medium pb-2">Total Investments</p>
        <h1 className="display-5 text-white">$10,000</h1>
        <div className="d-flex justify-content- small mt-3">
         <div className="col">
          <p className="mb-0 board-text">+USD500 (18%)</p>
          <span className="text-dark-50">Total Gain/Loss</span>
         </div>
         <div className="col">
          <p className="mb-0 board-text">+USD20.55 (6%)</p>
          <span className="text-dark-50">Today’s Gain/Loss</span>
         </div>
        </div>
       </div>
      </div>
     </div>
    </div>
    <div className="progress progress-sm mt-5">
     <div
      className="progress-bar bg-primary"
      role="progressbar"
      style={{ width: progress + "%" }}
      data-valuenow={progress}
      data-valuemin="0"
      data-valuemax="100"
     ></div>
    </div>
    {children}
   </div>
  </>
 );
};

export default PotDashboard;
