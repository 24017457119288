/** @format */
import DashboardComponent from "@components/dashboard/DashboardComponent";
import React, { useState } from "react";
import { BsCreditCard2FrontFill } from "react-icons/bs";
import {
 IoBusinessSharp,
 IoCardOutline,
 IoLogoBitcoin,
 IoWalletOutline,
} from "react-icons/io5";

import { connect, useSelector } from "react-redux";
import moment from "moment";
import { closePaymentModal, FlutterWaveButton } from "flutterwave-react-v3";
import { Logo } from "@assets/images";
import history from "@redux/actions/history";
import PAGES_URL from "@routers/routes";
import createPot from "@lib/supabase/functions/createPot";
import potDataWrapper from "@lib/supabase/functions/potdata";
import { RootState } from "@redux/store";
interface RiskMandate {
 risk_mandates: {
  name: string;
 };
}

interface PotData {
 monthly_contribution_start_date: string;
 starting_contribution_fee: number;
 monthly_contribution_fee: number;
 riskMandate: RiskMandate;
}

interface PotTypeData {
 name: string;
 short_description: string;
}

interface ReviewProps {
 user: any;
 potData: PotData;
 potTypeData: PotTypeData;
}

const Review: React.FC<ReviewProps> = (props) => {
 const [isLoading, setLoading] = useState(false);
 const reduxStore = useSelector((state: RootState) => state);

 const data = [
  {
   title: "Starting contribution",
   value: `${props.potData.starting_contribution_fee} USD`,
  },
  {
   title: "Monthly contribution",
   value: `${props.potData.monthly_contribution_fee} USD`,
  },
  {
   title: "Start period",
   value: `${moment(props.potData.monthly_contribution_start_date).format(
    "MMMM, YYYY"
   )}`,
  },
  {
   title: "Selected risk mandate",
   value: props.potData.riskMandate.risk_mandates.name,
  },
 ];

 const customer = {
  email: props.user.email,
  phone_number: props.user.phone,
  name: props.user.first_name + " " + props.user.last_name,
 };

 const config = {
  public_key: "FLWPUBK_TEST-b11fc4af2c51fd8f6e2f54243f0e0608-X",
  tx_ref: Date.now().toFixed(),
  amount: props.potData.starting_contribution_fee,
  currency: "USD",
  payment_options: "card",
  customer,
  customizations: {
   title: "Senga",
   description: `Payments for ${props.potTypeData.name}`,
   logo: Logo,
  },
 };

 const fwConfig = {
  ...config,
  text: "Pay with Flutterwave",
  callback: async (response: any) => {
   if (response.status == "success") {
    const potData = await potDataWrapper(reduxStore);
    const potDataResponse = await createPot(potData);
    console.log("pot data response is: " + JSON.stringify(potDataResponse));
    const potId = potDataResponse.data[0].pot_id;
    history.push({ pathname: PAGES_URL.POT_SUCCESS, search: `?id=${potId}` });
   }
   closePaymentModal();
   setLoading(true);
  },
  onClose: () => {
   setLoading(false);
   console.log("You close me ooo");
  },
 };
 return (
  <DashboardComponent pageTitle="INVESTMENT">
   <section className="section-fluid">
    <div className="mb-lg-5">
     <h1 className="ls-tight mb-1 d-flex ">💡 Finishing up your pot</h1>
     <p className="h4 fw-normal">
      You are a step away from creating your investment pot, do check if
      everything is setup correctly
     </p>
    </div>
    <div className="row mt-5 justify-content-center">
     <div className="col-lg-8">
      <div className="card rounded-1">
       <div className="card-body">
        <h4 className="text-dark-50 h4 mb-5">#Order Summary</h4>
        <h4 className="mb-0">{props.potTypeData.name}</h4>
        <p className=" h5 mb-5 fw-normal">
         {props.potTypeData.short_description}
        </p>
        {data.map((dta: any, i: any) => (
         <div
          className="d-flex justify-content-between border-bottom py-1 py-lg-3"
          key={i}
         >
          <h4 className="mb-0 h5 mt-2 fw-medium">{dta.title}</h4>
          <p className=" h5 fw-normal">{dta.value}</p>
         </div>
        ))}
        <FlutterWaveButton
         disabled={isLoading}
         className="btn btn-dark btn-lg w-lg-50 mt-5 d-none"
         {...fwConfig}
        >
         <BsCreditCard2FrontFill size={30} className="me-2 me-3" />
         Complete payment
        </FlutterWaveButton>
        <div className="d-flex"></div>
       </div>
      </div>
     </div>
     <div className="col-lg-4">
      <div className="card rounded-1 bg-opacity-info ">
       <div className="card-body">
        <div className="d-flex justify-content-between">
         <div className="d-flex align-items-start">
          <span className="h2 text-primary">$ </span>
          <span className="h1 fw-bold text-primary">
           {" "}
           {props.potData.starting_contribution_fee}.00
          </span>
         </div>
         <h5 className="h1 fw-bold text-dark"></h5>
        </div>
        <p className="text-dark h5 fw-normal mb-5">Choose a payment method</p>
        <div className="form-check d-flex align-items-center mb-3">
         <input
          className="form-check-input "
          type="radio"
          id="ex-radio-1"
          name="radio"
         />
         <label
          className="form-check-label h5 mb-0 ms-3  fw-normal"
          htmlFor="ex-radio-1"
         >
          Senga's Wallet
         </label>
         <IoWalletOutline size={30} className="ms-auto" />
        </div>
        <hr />
        <div className="form-check d-flex align-items-center my-3">
         <input
          className="form-check-input "
          type="radio"
          id="ex-radio-2"
          name="radio"
         />
         <label
          className="form-check-label h5 mb-0 ms-3  fw-normal"
          htmlFor="ex-radio-2"
         >
          Credit Card By Flutterwave
         </label>
         <IoCardOutline size={30} className="ms-auto" />
        </div>
        <hr />
        <div className="form-check d-flex align-items-center my-3">
         <input
          className="form-check-input "
          type="radio"
          id="ex-radio-3"
          name="radio"
         />
         <label
          className="form-check-label h5 mb-0 ms-3  fw-normal"
          htmlFor="ex-radio-3"
         >
          Perfect Money
         </label>
         <IoBusinessSharp size={30} className="ms-auto" />
        </div>
        <hr />
        <div className="form-check d-flex align-items-center my-3">
         <input
          className="form-check-input "
          type="radio"
          id="ex-radio-4"
          name="radio"
         />
         <label
          className="form-check-label h5 mb-0 ms-3 fw-normal"
          htmlFor="ex-radio-4"
         >
          Cryptocurrency
         </label>
         <IoLogoBitcoin size={30} className="ms-auto" />
        </div>
        {/* <div className="form-check">
         <input className="form-check-input" type="checkbox" id="ex-check-1" />
         <label className="form-check-label" htmlFor="ex-check-1">
          Check this checkbox
         </label>
        </div> */}
        <button className="btn btn-dark btn-lg w-lg-100 rounded-0 mt-5 mb-3">
         Complete Payment
        </button>
       </div>
      </div>
     </div>
    </div>
   </section>
  </DashboardComponent>
 );
};

const mapStateToProps = (state: any): ReviewProps => ({
 user: state.user.data,
 potData: state.pots.potData,
 potTypeData: state.pots.potTypeData,
});
export default connect(mapStateToProps)(Review);
