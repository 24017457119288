/** @format */

import { Icons } from "@assets/images";
import { InputField } from "@components/Inputs";
import DashboardComponent from "@components/dashboard/DashboardComponent";
import { HoverText } from "@components/dashboard/Util";
import React from "react";
import {
 BsCreditCard2BackFill,
 BsPatchQuestion,
 BsPlusCircle,
 BsQuestionCircleFill,
} from "react-icons/bs";
import { FaCcVisa } from "react-icons/fa6";
import CreditCard from "./component/CreditCard";
import { Link } from "react-router-dom";

const Deposit = () => {
 return (
  <DashboardComponent pageTitle="">
   <section className="section-fluid">
    <div className="d-flex   align-items-center mb-5">
     <div>
      <h1 className="ls-tight mb-1 d-flex ">
       Fund Wallet
       <HoverText message="Selected Investment Plan" Icon={BsPatchQuestion} />
      </h1>
      <p className="h4 fw-normal">Have an insight financial reports</p>
     </div>
    </div>

    <div className="row ">
     <div className="col-lg-5 ">
      <div className="card rounded-2">
       <div className="card-body">
        <div className="d-flex flex-column mb-5">
         <h5 className="mb-0 fw-light d-flex">
          Current balance{" "}
          <HoverText
           message="Current available wallet balance"
           Icon={BsQuestionCircleFill}
          />
         </h5>
         <h1 className="display-1 fw-normal d-flex mb-0">
          <span>$</span>
          <span>0.48</span>
         </h1>
        </div>

        <form className="">
         <div className="input-group mb-5">
          <span className="input-group-text">
           <i className="h5 mb-0 ai-dollar"></i>
          </span>
          <input type="text" className="form-control" placeholder="Amount" />

          <span className="input-group-text h5 mb-0">.00</span>
         </div>
         <a
          href="#"
          className="card h-100 rounded-1 bg-light text-decoration-none px-xxl-1 mb-3"
          style={{ maxWidth: "306px;" }}
         >
          <div className="card-body p-2 px-sm-3 px-md-4">
           <div className="d-flex align-items-center">
            <FaCcVisa className="text-dark" size={40} />
            <div className="ps-3 ps-md-4">
             <h3 className="fs-sm mb-0 text-dark">**** **** **** 4543</h3>
             <p className="fs-sm mb-0 text-dark">
              CVV 456
              <del className="text-body-secondary fs-xs ms-1">09/2024</del>
             </p>
            </div>
           </div>
          </div>
         </a>

         <Link to={"#"} className="text-end fw-bold ">
          Add new card <BsPlusCircle className="ms-1" />
         </Link>

         {/* <a
          href="#"
          className="card h-100 rounded-1 text-decoration-none px-xxl-1"
          style={{ maxWidth: "306px;" }}
         >
          <div className="card-body p-2 px-sm-3 px-md-4">
           <div className="d-flex align-items-center">
            <BsCreditCard2BackFill className="text-dark" size={40} />
            <div className="ps-3 ps-md-4">
             <h3 className="fs-sm mb-0 text-dark">Add Credit / Debit Card</h3>
            </div>
           </div>
          </div>
         </a> */}

         <CreditCard />
        </form>

        <div className="mt-5">
         Before proceeding you are agreeing to our terms and use of service.
         <div className="form-check">
          <input
           className="form-check-input"
           type="radio"
           id="ex-radio-1"
           name="radio"
          />
          <label className="form-check-label" htmlFor="ex-radio-1">
           Terms & Conditions
          </label>
         </div>
         <div className="form-check">
          <input
           className="form-check-input"
           type="radio"
           id="ex-radio-2"
           name="radio"
           checked
          />
          <label className="form-check-label" htmlFor="ex-radio-2">
           Use of service
          </label>
         </div>
         <button className="btn btn-lg btn-dark mt-4 ">Continue </button>
        </div>
       </div>
      </div>
     </div>
     <div className="col-lg-4">
      <div className="card bg-lighter p-0 m-0">
       <div className="card-header bg-primary m-0 w-100">
        <h4 className="mb-0 px-4 text-white">Sub Total</h4>
       </div>
       <div className="card-body">
        <p>* Card payment Method</p>
        <p>* One off payment</p>
        <p>* Secured Gateway</p>
        <button className="btn btn-lg w-100 btn-dark mt-4 py-4">
         Continue{" "}
        </button>
       </div>
      </div>
     </div>
    </div>
   </section>
  </DashboardComponent>
 );
};

export default Deposit;
