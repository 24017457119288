/** @format */
import React, { useEffect, useState } from "react";
import { Avatar } from "@components/FileUploadComponent";
import { InputField, Select } from "@components/Inputs";
import { UpdateUserValidator } from "@helpers/Validator";
import { getUser, updateUser } from "@redux/actions/user";
import { connect } from "react-redux";
import Button from "@components/Buttons";
import { UpdateUser as User, UpdateAvatar } from "@helpers/types/user.type";
import { listCountries, listGenders } from "@redux/actions/global";
import { hasValues } from "@helpers/String";
import { HoverText } from "@components/dashboard/Util";
import { BsPatchQuestion } from "react-icons/bs";

const EditAccountSettings = (props) => {
 const { user, genders, nationality, fetchCountries, fetchGenders } = props;

 const [state, setState] = useState(user);
 const [avatar, setAvatar] = useState<UpdateAvatar>({} as UpdateAvatar);
 const [error, setError] = useState<User>({} as User);

 useEffect(() => {
  fetchGenders();
  fetchCountries();
 }, [fetchCountries, fetchGenders]);

 // handle input field behavior on change
 const handleInputChange = (e: any) => {
  setState((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
 };

 // handle avatar upload
 const handleImageChange = (e: any) => {
  setAvatar(e.target.value);
 };

 // handle form onsubmit behavior
 const handleSubmit = (e: any) => {
  e.preventDefault();
  const errors = UpdateUserValidator(state);
  if (!hasValues(errors)) {
   setError(errors);
   return;
  }
  const { created_at, updated_at, ...formData } = state;
  props.update(formData);
 };
 return (
  <>
   <section className="card py-1 p-md-2 p-xl-3 p-xxl-4 mb-4">
    <div className="card-body">
     <Avatar
      className=""
      name="url"
      id="url"
      onChange={handleImageChange}
      //   value={avatar.url}
     />
     <form action="">
      <div className="row g-3 g-sm-4 mt-0 mt-lg-2">
       <div className="col-sm-6">
        <input type="hidden" name="id" value={user.id} />
        <label className="form-label" htmlFor="fn">
         First name
        </label>
        <InputField
         value={state.first_name}
         errorMessage={error.first_name}
         name="first_name"
         placeholder="First name"
         onChange={handleInputChange}
        />
       </div>
       <div className="col-sm-6">
        <label className="form-label" htmlFor="ln">
         Last name
        </label>
        <InputField
         value={state.last_name}
         errorMessage={error.last_name}
         name="last_name"
         placeholder="Last name"
         onChange={handleInputChange}
        />
       </div>
       <div className="col-sm-6">
        <label className="form-label d-flex" htmlFor="email">
         Email address
         <HoverText
          message={"Please contact admin for more assistance"}
          Icon={BsPatchQuestion}
         />
        </label>
        <InputField
         value={state.email}
         errorMessage={error.email}
         name="email"
         disabled
         className="bg-transparent"
         placeholder="Email Address"
         onChange={handleInputChange}
        />
       </div>
       <div className="col-sm-6">
        <label className="form-label" htmlFor="fn">
         Phone number
        </label>
        <InputField
         value={state.phone}
         errorMessage={error.phone}
         name="phone"
         type="tel"
         placeholder="Phone number"
         onChange={handleInputChange}
        />
       </div>
       <div className="col-sm-6">
        <Select
         errorMessage={error.nationality_id}
         data={nationality}
         name="nationality_id"
         id="nationality"
         label={"Nationality"}
         selected={state?.nationality_id}
         onChange={handleInputChange}
        />
       </div>
       <div className="col-sm-6">
        <Select
         errorMessage={error.gender_id}
         data={genders}
         name="gender_id"
         id="gender"
         label={"Genders"}
         selected={state?.gender_id}
         onChange={handleInputChange}
        />
       </div>

       <div className="col-12">
        <label className="form-label" htmlFor="address">
         Address
        </label>
        <textarea
         className="form-control"
         rows={5}
         name="address"
         onChange={handleInputChange}
         placeholder="Address"
         id="address"
        >
         {state.address}
        </textarea>
       </div>

       <div className=" d-flex pt-3">
        <button className="btn btn-secondary" type="button">
         Cancel
        </button>
        <Button
         className=" btn-primary ms-3"
         loading={props.isLoading}
         onClick={handleSubmit}
        >
         Save changes
        </Button>
       </div>
      </div>
     </form>
    </div>
   </section>
  </>
 );
};

const mapStateToProps = (state: any) => ({
 isLoading: state.user.isLoading,
 user: state.user.data,
 nationality: state.global.countries || {},
 genders: state.global.genders || {},
});

const mapDispatchToProps = (dispatch: any) => ({
 update: (data: any) => dispatch(updateUser(data)),
 fetchGenders: () => dispatch(listGenders()),
 fetchCountries: () => dispatch(listCountries()),
});

export default connect(
 mapStateToProps,
 mapDispatchToProps
)(EditAccountSettings);
