/** @format */

import KYC_BANNER from "./KYC.png";
import STOCKS from "./stocks.png";
import GT_BANK from "./GTBank_logo.png";
import VISA from "./Visa-Logo-PNG-Image.png";

const Icons = { KYC_BANNER, STOCKS, GT_BANK, VISA };

export default Icons;
