/** @format */

const potDataWrapper = (reduxStore: any) => {
 // Prepare data for creating a new pot
    let formattedDate = "";
    const potData = {
  user_id: reduxStore.user.data.id,
  pot_type_id: reduxStore.pots.potTypeData.pot_type_id,
  name: reduxStore.pots.potData.name,
  ...(reduxStore.pots.potData.monthly_contribution_fee
   ? {
      monthly_contribution_fee: Number(
       reduxStore.pots.potData.monthly_contribution_fee
      ),
     }
   : {}),
  ...(reduxStore.pots.potData.monthly_contribution_start_date
   ? {
      monthly_contribution_start_date:
       reduxStore.pots.potData.monthly_contribution_start_date,
     }
   : {}),

  starting_contribution_fee: Number(
   reduxStore.pots.potData.starting_contribution_fee
  ),
  base_currency: "USD",
  risk_mandate_id: reduxStore.pots.potData.riskMandate.risk_mandate_id,
  meta_data: {
   assets:
    reduxStore.pots.potData.riskMandate.risk_mandates.risk_mandate_assets.map(
     (asset: any) => ({
      name: asset.assets.asset_name,
      weight: asset.asset_weight,
     })
    ),
   pot_type_name: reduxStore.pots.potTypeData.name,
   risk_mandate_name: reduxStore.pots.potData.riskMandate.risk_mandates.name,
   monthly_values: {
    [formattedDate]: reduxStore.pots.potData.starting_contribution_fee,
   },
  },
 };

 return potData;
};

export default potDataWrapper;
