/** @format */
import React from "react";
import AppComponent from "@components/AppComponent";
import { Banner, Hero } from "@assets/images";

const FeaturesSingle = () => {
 return (
  <AppComponent>
   <section className="container py-5 mt-5 mb-md-2 mb-lg-3 mb-xl-4">
    <nav aria-label="breadcrumb">
     <ol className="pt-lg-3 pb-lg-4 pb-2 breadcrumb">
      <li className="breadcrumb-item">
       <a href="index.html">Home</a>
      </li>
      <li className="breadcrumb-item">
       <a href="feature-grid.html">feature</a>
      </li>
      <li className="breadcrumb-item active" aria-current="page">
       Single
      </li>
     </ol>
    </nav>

    <h1 className="display-4 text-center pb-2 pb-lg-3">
     Introducing Senga Wallet: Your Gateway to Seamless Wealth Growth
    </h1>
    <div className="d-flex flex-wrap align-items-center justify-content-center mt-n2">
     <span className="text-body-secondary fs-sm fw-normal p-0 mt-2 me-3">
      12
      <i className="ai-share fs-lg ms-1"></i>
     </span>
     <a
      className="nav-link position-relative text-body-secondary fs-sm fw-normal d-flex align-items-end p-0 mt-2"
      href="#comments"
      data-scroll
      data-scroll-offset="60"
     >
      <span
       className="position-absolute w-100 h-100 top-0 start-0"
       data-bs-toggle="collapse"
       data-bs-target="#commentsCollapse"
      ></span>
      4<i className="ai-message fs-lg ms-1"></i>
     </a>
     <span className="fs-xs opacity-20 mt-2 mx-3">|</span>
     <span className="fs-sm text-body-secondary mt-2">16 hours ago</span>
     <span className="fs-xs opacity-20 mt-2 mx-3">|</span>
     <a className="badge text-nav fs-xs border mt-2" href="#">
      Inspiration
     </a>
    </div>
   </section>

   <section className="jarallax" data-jarallax data-speed=".65">
    <div className="position-relative">
     <img src={Hero._10} className="d-block rounded-5" alt="Video cover" />
     <div className="d-nline-flex position-absolute top-50 start-50 translate-middle z-2 p-4">
      <a
       href="https://www.youtube.com/watch?v=udMULpKcnn8"
       className="btn btn-icon btn-xl btn-primary rounded-circle stretched-link"
       data-bs-toggle="video"
       aria-label="Play video"
      >
       <i className="ai-play-filled"></i>
      </a>
     </div>
    </div>
    <div
     className="jarallax-img bg-position-center-y"
     style={{ backgroundImage: "url(assets/img/feature/single/cover.jpg);" }}
    ></div>
    <div className="d-none d-xxl-block" style={{ height: "760px;" }}></div>
    <div
     className="d-none d-xl-block d-xxl-none"
     style={{ height: "650px;" }}
    ></div>
    <div
     className="d-none d-lg-block d-xl-none"
     style={{ height: "500px;" }}
    ></div>
    <div
     className="d-none d-md-block d-lg-none"
     style={{ height: "400px;" }}
    ></div>
    <div className="d-md-none" style={{ height: "300px;" }}></div>
   </section>

   <section className="container pt-5 mt-md-2 mt-lg-3 mt-xl-4">
    <div className="row justify-content-center pt-xxl-2">
     <div className="col-lg-9 col-xl-8">
      <p className="fs-lg">
       Dolor laoreet fermentum lectus praesent aenean molestie mollis integer.
       Sem ullamcorper montes, lorem ullamcorper orci, pellentesque ipsum
       malesuada gravida. Donec imperdiet nulla suscipit in. Dignissim ornare ac
       lorem consectetur massa a. Pellentesque urna pharetra, quis maecenas. Sit
       dolor amet nulla aenean eu, ac. Nisl mi tempus, iaculis viverra
       vestibulum scelerisque imperdiet montes. Mauris massa elit pretium
       elementum eget tortor quis. Semper interdum lectus odio diam.
      </p>
      <p className="fs-lg mb-3">
       Ut pellentesque bibendum dignissim a molestie. Ultrices diam ut vel neque
       tincidunt eget. Sed ut quis sit semper nunc at etiam lacinia. Quam
       laoreet eget id sapien a pharetra, ornare diam dignissim. Lorem amet
       nisl, enim mi aenean mauris. Porta nisl a ultrices ut libero id. Gravida
       a mi neque, tristique justo, et pharetra. Laoreet nulla est nulla cras ac
       arcu sed mattis tristique. Morbi convallis suspendisse enim blandit
       massa. Cursus augue dui mattis morbi velit.
      </p>
      <h2 className="h4 mb-lg-4 pt-3 pt-md-4 pt-xl-5">
       Dolor laoreet fermentum lectus praesent aenean
      </h2>
      <p className="fs-lg pb-4 pb-xl-5">
       Ut pellentesque bibendum dignissim a molestie. Ultrices diam ut vel neque
       tincidunt eget. Sed ut quis sit semper nunc at etiam lacinia. Quam
       laoreet eget id sapien a pharetra, ornare diam dignissim. Lorem amet
       nisl, enim mi aenean mauris. Porta nisl a ultrices ut libero id. Gravida
       a mi neque, tristique justo, et pharetra. Laoreet nulla est nulla cras ac
       arcu sed mattis tristique. Morbi convallis suspendisse enim blandit
       massa. Cursus augue dui mattis morbi velit.
      </p>
      <figure className="figure">
       <img
        className="figure-img rounded-5 mb-3"
        src="assets/img/feature/single/image.jpg"
        alt="Image"
       />
       <figcaption className="figure-caption">
        Image source tristique justo et pharetra
       </figcaption>
      </figure>
      <h2 className="h4 mb-lg-4 pt-3 pt-md-4 pt-xl-5">
       Cursus augue dui mattis morbi velit
      </h2>
      <p className="fs-lg pb-2 pb-lg-0 mb-4 mb-lg-5">
       Proin non congue sem, sed tristique ante. Donec et sollicitudin tellus.
       Duis maximus, dui eget egestas mattis, purus ex tempor nulla, quis tempor
       sapien neque at nisl. Aliquam eu nisi ut nisl ultrices posuere. Praesent
       dignissim efficitur nisi, a hendrerit ipsum elementum sit amet. Vivamus
       non ante nisl. Nunc faucibus velit at eros mollis semper. Curabitur
       aliquam eros tellus, nec facilisis nisl finibus sit amet. Ut et dolor nec
       lorem gravida elementum.
      </p>
      <div className="card border-0 bg-secondary mb-3">
       <div className="card-body">
        <figure>
         <blockquote className="blockquote">
          <p>
           Ut pellentesque bibendum dignissim a molestie ultrices diam ut vel
           neque tincidunt eget sed ut quis sit semper nunc at etiam lacinia
           quam laoreet eget id sapien a pharetra, ornare diam dignissim neque
           tincidunt.
          </p>
         </blockquote>
         <figcaption className="blockquote-footer">
          Darlene Robertson
         </figcaption>
        </figure>
       </div>
      </div>
      <h2 className="h4 mb-lg-4 pt-3 pt-md-4 pt-xl-5">
       Lorem ipsum dolor sit amet consectetur
      </h2>
      <p className="fs-lg">
       Ut pellentesque bibendum dignissim a molestie. Ultrices diam ut vel neque
       tincidunt eget. Sed ut quis sit semper nunc at etiam lacinia. Quam
       laoreet eget id sapien a pharetra, ornare diam dignissim.
      </p>
      <ul>
       <li className="mb-1">Quam laoreet eget id sapien</li>
       <li className="mb-1">Morbi convallis suspendisse</li>
       <li className="mb-1">Vivamus non ante</li>
       <li className="mb-1">Praesent dignissim efficitur</li>
       <li className="mb-1">Gravida a mi neque</li>
      </ul>
      <p className="fs-lg">
       Donec diam neque, efficitur vitae ante a, eleifend placerat est.
       Phasellus dapibus scelerisque diam, eu rhoncus lorem vulputate lobortis.
       Praesent pulvinar venenatis mauris, eget fringilla sem.
      </p>

      <div className="d-flex flex-wrap pb-5 pt-3 pt-md-4 pt-xl-5 mt-xl-n2">
       <h3 className="h6 py-1 mb-0 me-4">Relevant tags:</h3>
       <a className="nav-link fs-sm py-1 px-0 me-3" href="#">
        <span className="text-primary">#</span>Nature
       </a>
       <a className="nav-link fs-sm py-1 px-0 me-3" href="#">
        <span className="text-primary">#</span>Books
       </a>
       <a className="nav-link fs-sm py-1 px-0 me-3" href="#">
        <span className="text-primary">#</span>Travel
       </a>
      </div>

      <div className="border-top py-4">
       <div className="d-flex align-items-start py-2">
        <img
         className="d-block rounded-circle mb-3"
         src="assets/img/avatar/04.jpg"
         width="56"
         alt="Author image"
        />
        <div className="d-md-flex w-100 ps-4">
         <div style={{ maxWidth: "26rem" }}>
          <h3 className="h5 mb-2">Darlene Robertson</h3>
          <p className="fs-sm mb-0">
           Porta nisl a ultrices ut libero id. Gravida mi neque, tristique
           justo, et pharetra laoreet nulla est nulla cras ac arcu sed mattis
           tristique convallis suspen enim blandit massa cursus augue dui mattis
           morbi velit semper nunc at etiam lacinia.
          </p>
         </div>
         <div className="pt-4 pt-md-0 ps-md-4 ms-md-auto">
          <h3 className="h5">Share post:</h3>
          <div className="d-flex">
           <a
            className="nav-link p-2 me-2"
            href="#"
            data-bs-toggle="tooltip"
            data-bs-placement="bottom"
            title="Instagram"
            aria-label="Instagram"
           >
            <i className="ai-instagram"></i>
           </a>
           <a
            className="nav-link p-2 me-2"
            href="#"
            data-bs-toggle="tooltip"
            data-bs-placement="bottom"
            title="Facebook"
            aria-label="Facebook"
           >
            <i className="ai-facebook"></i>
           </a>
           <a
            className="nav-link p-2 me-2"
            href="#"
            data-bs-toggle="tooltip"
            data-bs-placement="bottom"
            title="Telegram"
            aria-label="Telegram"
           >
            <i className="ai-telegram fs-sm"></i>
           </a>
           <a
            className="nav-link p-2"
            href="#"
            data-bs-toggle="tooltip"
            data-bs-placement="bottom"
            title="X"
            aria-label="X"
           >
            <i className="ai-x"></i>
           </a>
          </div>
         </div>
        </div>
       </div>
      </div>
     </div>
    </div>
   </section>

   <section
    className="container pt-xl-2 pb-5 mb-md-2 mb-lg-4 mb-xl-5"
    id="comments"
   >
    <div className="row justify-content-center">
     <div className="col-lg-9 col-xl-8">
      <div className="border-top border-bottom">
       <div className="collapse" id="commentsCollapse">
        <div className="border-bottom py-4 mt-2 mb-4">
         <div className="d-flex align-items-center pb-1 mb-3">
          <img
           className="rounded-circle"
           src="assets/img/avatar/08.jpg"
           width="48"
           alt="Comment author"
          />
          <div className="ps-3">
           <h6 className="mb-0">Albert Flores</h6>
           <span className="fs-sm text-body-secondary">5 hours ago</span>
          </div>
         </div>
         <p className="pb-2 mb-0">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin tellus
          lectus, tempus eu urna eu, imperdiet dignissim augue. Aliquam
          fermentum est a ligula bibendum, ac gravida ipsum dictum. Class aptent
          taciti sociosqu ad litora torquent per conubia nostra, per inceptos
          himenaeos. Curabitur suscipit quam ut velit condimentum, nec mollis
          risus semper. Curabitur quis mauris eget ligula tincidunt venenatis.
          Sed congue pulvinar hendrerit.
         </p>
         <button className="nav-link fs-sm fw-semibold px-0 py-2" type="button">
          Reply
          <i className="ai-redo fs-xl ms-2"></i>
         </button>
        </div>

        <div className="border-bottom pt-2 pb-4">
         <div className="d-flex align-items-center pb-1 mb-3">
          <img
           className="rounded-circle"
           src="assets/img/avatar/11.jpg"
           width="48"
           alt="Comment author"
          />
          <div className="ps-3">
           <h6 className="mb-0">Jenny Wilson</h6>
           <span className="fs-sm text-body-secondary">2 days ago at 9:20</span>
          </div>
         </div>
         <p className="pb-2 mb-0">
          Pellentesque urna pharetra, quis maecenas. Sit dolor amet nulla aenean
          eu, ac. Nisl mi tempus, iaculis viverra vestibulum scelerisque
          imperdiet montes mauris massa elit pretium elementum eget tortor quis
         </p>
         <button className="nav-link fs-sm fw-semibold px-0 py-2" type="button">
          Reply
          <i className="ai-redo fs-xl ms-2"></i>
         </button>

         <div className="card card-body border-0 bg-secondary mt-4">
          <div className="d-flex align-items-center pb-1 mb-3">
           <img
            className="rounded-circle"
            src="assets/img/avatar/10.jpg"
            width="48"
            alt="Comment author"
           />
           <div className="ps-3">
            <h6 className="mb-0">Ralph Edwards</h6>
            <span className="fs-sm text-body-secondary">
             2 days ago at 11:45
            </span>
           </div>
          </div>
          <p className="mb-0">
           <a className="fw-bold text-decoration-none" href="#">
            @Jenny Wilson
           </a>{" "}
           Massa morbi duis et ornare urna dictum vestibulum pulvinar nunc
           facilisis ornare id at at ut arcu integer tristique placerat non
           turpis nibh turpis ullamcorper est porttitor.
          </p>
         </div>
        </div>

        <div className="pt-4 mt-2 mb-2">
         <div className="d-flex align-items-center pb-1 mb-3">
          <img
           className="rounded-circle"
           src="assets/img/avatar/07.jpg"
           width="48"
           alt="Comment author"
          />
          <div className="ps-3">
           <h6 className="mb-0">Esther Howard</h6>
           <span className="fs-sm text-body-secondary">May 19, 2022</span>
          </div>
         </div>
         <p className="pb-2 mb-0">
          Donec et sollicitudin tellus. Duis maximus, dui eget egestas mattis,
          purus ex tempor nulla, quis tempor sapien neque at nisl. Aliquam eu
          nisi ut nisl ultrices posuere. Praesent dignissim efficitur nisi, a
          hendrerit ipsum elementum sit amet. Vivamus non ante nisl. Nunc
          faucibus velit at eros mollis semper.
         </p>
         <button className="nav-link fs-sm fw-semibold px-0 py-2" type="button">
          Reply
          <i className="ai-redo fs-xl ms-2"></i>
         </button>
        </div>
       </div>

       <div className="nav">
        <button
         className="btn-more nav-link collapsed w-100 justify-content-center p-3"
         type="button"
         data-bs-toggle="collapse"
         data-bs-target="#commentsCollapse"
         aria-expanded="false"
         aria-controls="commentsCollapse"
         data-show-label="Show all comments"
         data-hide-label="Hide all comments"
         aria-label="Show / hide comments"
        >
         <span className="fw-normal opacity-70 ms-1">(4)</span>
        </button>
       </div>
      </div>
     </div>
    </div>
   </section>

   <section className="container pt-2 pt-sm-3 pb-5 mb-md-3 mb-lg-4 mb-xl-5">
    <div className="d-flex align-items-center pb-3 mb-3 mb-lg-4">
     <h2 className="h1 mb-0 me-4">Related articles</h2>
     <div className="d-flex ms-auto">
      <button
       className="btn btn-prev btn-icon btn-sm btn-outline-primary rounded-circle me-3"
       type="button"
       id="prev-post"
       aria-label="Prev"
      >
       <i className="ai-arrow-left"></i>
      </button>
      <button
       className="btn btn-next btn-icon btn-sm btn-outline-primary rounded-circle"
       type="button"
       id="next-post"
       aria-label="Next"
      >
       <i className="ai-arrow-right"></i>
      </button>
     </div>
    </div>
    <div
     className="swiper pb-2 pb-sm-3 pb-md-4"
     data-swiper-options='
          {
            "spaceBetween": 24,
            "loop": true,
            "autoHeight": true,
            "navigation": {
              "prevEl": "#prev-post",
              "nextEl": "#next-post"
            },
            "breakpoints": {
              "576": { "slidesPerView": 2 },
              "1000": { "slidesPerView": 3 }
            }
          }
        '
    >
     <div className="swiper-wrapper">
      <article className="swiper-slide">
       <div className="position-relative">
        <img
         className="rounded-5"
         src="assets/img/feature/list/01.jpg"
         alt="Post image"
        />
        <h3 className="h4 mt-4 mb-0">
         <a className="stretched-link" href="feature-single-v1.html">
          The fashion for eco bags with vintage prints will still be relevant
          for more than one year
         </a>
        </h3>
       </div>
      </article>

      <article className="swiper-slide">
       <div className="position-relative">
        <img
         className="rounded-5"
         src="assets/img/feature/list/04.jpg"
         alt="Post image"
        />
        <h3 className="h4 mt-4 mb-0">
         <a className="stretched-link" href="feature-single-v3.html">
          How to look for inspiration for new goals in life and remember to give
          yourself a break?
         </a>
        </h3>
       </div>
      </article>

      <article className="swiper-slide">
       <div className="position-relative">
        <img
         className="rounded-5"
         src="assets/img/feature/list/06.jpg"
         alt="Post image"
        />
        <h3 className="h4 mt-4 mb-0">
         <a className="stretched-link" href="feature-single-v1.html">
          A session with a psychologist as a personal choice or a fashion trend
          in society
         </a>
        </h3>
       </div>
      </article>

      <article className="swiper-slide">
       <div className="position-relative">
        <img
         className="rounded-5"
         src="assets/img/feature/list/05.jpg"
         alt="Post image"
        />
        <h3 className="h4 mt-4 mb-0">
         <a className="stretched-link" href="feature-single-v3.html">
          Top 10 best TV shows on Netflix for your next weekend
         </a>
        </h3>
       </div>
      </article>
     </div>
    </div>
   </section>

   <section
    className="container mb-2 mb-md-3 mb-xl-4 pb-2"
    data-bs-theme="dark"
   >
    <div className="position-relative bg-dark rounded-5 overflow-hidden p-md-5 p-4">
     <div
      className="position-absolute top-0 start-0 w-100 h-100"
      style={{ backgroundColor: "rgba(255, 255, 255, .03)" }}
     ></div>
     <div className="position-relative p-xl-5 p-md-4 py-4 px-sm-3">
      <h2 className="h1 pb-md-4 pb-3 mt-n2">
       Stay up to date with important news!
      </h2>
      <div className="row gy-md-5 gy-4 gx-xl-5">
       <div className="col-lg-7">
        <div className="row row-cols-sm-3 row-cols-2 gy-lg-4 gy-3 gx-xl-4 gx-sm-3 gx-2">
         <div className="col">
          <div className="form-check mb-0">
           <label
            className="form-check-label fs-base fw-medium"
            htmlFor="advert-updates"
           >
            Advertising Updates
           </label>
           <input
            className="form-check-input"
            id="advert-updates"
            type="checkbox"
            checked
           />
          </div>
         </div>
         <div className="col">
          <div className="form-check mb-0">
           <label
            className="form-check-label fs-base fw-medium"
            htmlFor="newsletter"
           >
            Daily Newsletter
           </label>
           <input
            className="form-check-input"
            id="newsletter"
            type="checkbox"
           />
          </div>
         </div>
         <div className="col">
          <div className="form-check mb-0">
           <label
            className="form-check-label fs-base fw-medium"
            htmlFor="week-in-review"
           >
            Week in Review
           </label>
           <input
            className="form-check-input"
            id="week-in-review"
            type="checkbox"
           />
          </div>
         </div>
         <div className="col">
          <div className="form-check mb-0">
           <label
            className="form-check-label fs-base fw-medium"
            htmlFor="inspiration"
           >
            Inspiration
           </label>
           <input
            className="form-check-input"
            id="inspiration"
            type="checkbox"
           />
          </div>
         </div>
         <div className="col">
          <div className="form-check mb-0">
           <label
            className="form-check-label fs-base fw-medium"
            htmlFor="psychology"
           >
            Psychology
           </label>
           <input
            className="form-check-input"
            id="psychology"
            type="checkbox"
           />
          </div>
         </div>
         <div className="col">
          <div className="form-check mb-0">
           <label
            className="form-check-label fs-base fw-medium"
            htmlFor="design"
           >
            Design
           </label>
           <input
            className="form-check-input"
            id="design"
            type="checkbox"
            checked
           />
          </div>
         </div>
        </div>
       </div>
       <div className="col-lg-5">
        <div className="input-group rounded-pill">
         <input className="form-control" type="text" placeholder="Your email" />
         <button className="btn btn-primary rounded-pill" type="button">
          Button
         </button>
        </div>
        <div className="form-text mt-3 fs-sm">
         * Yes, I agree to the <a href="#">terms</a> and{" "}
         <a href="#">privacy policy</a>
        </div>
       </div>
      </div>
     </div>
    </div>
   </section>
  </AppComponent>
 );
};

export default FeaturesSingle;
