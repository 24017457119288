/** @format */

import supabase from "@lib/supabase/client";
import { SET_COUNTRY_DATA, SET_GENDER_DATA } from "@redux/reducers/types";

export const listGenders = () => async (dispatch: any) => {
 try {
  const { data } = await supabase.from("genders").select();
  dispatch({ type: SET_GENDER_DATA, payload: data });
 } catch (error) {}
};

export const listCountries = () => async (dispatch: any) => {
 try {
  const { data } = await supabase.from("nationality").select();
  dispatch({ type: SET_COUNTRY_DATA, payload: data });
 } catch (error) {}
};
