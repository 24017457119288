/** @format */

import { InvestmentPotActionTypes } from "@redux/reducers/types";

export function setPotData(data: any) {
 // Replace 'any' with the appropriate type for your pot data
 return { type: InvestmentPotActionTypes.SET_POT_DATA, payload: data };
}

export function setPotError(error: any) {
 return { type: InvestmentPotActionTypes.SET_POT_ERROR, payload: error };
}

export const resetInvestmentPot = () => ({
 type: InvestmentPotActionTypes.RESET_INVESTMENT_POT,
});

export function setPotTypeData(data: any) {
 return { type: InvestmentPotActionTypes.SET_POT_TYPE_DATA, payload: data };
}
