/** @format */

import React from "react";
import DashboardComponent from "@components/dashboard/DashboardComponent";
import {
 BsBarChartLineFill,
 BsListColumns,
 BsPatchQuestion,
 BsPostageFill,
 BsSearch,
 BsSend,
 BsWallet2,
} from "react-icons/bs";
import { HoverText } from "@components/dashboard/Util";
import { Link } from "react-router-dom";
import PAGES_URL from "@routers/routes";

const Help = () => {
 return (
  <DashboardComponent pageTitle={"HELP"}>
   <div className="section-fluid">
    <div className="d-flex   align-items-center mb-5">
     <div>
      <h1 className="ls-tight mb-1 d-flex ">
       What can we help you find
       <HoverText message="Selected Investment Plan" Icon={BsPatchQuestion} />
      </h1>
      <p className="h4 fw-normal">
       Exploring our articles and core basics on using Senga and getting your{" "}
       <br />
       financial investments managed with our easy to use steps
      </p>
     </div>
    </div>
    <div className="row mt-5">
     <div className="col-lg-7">
      <div className="row">
       <div className="col-lg-12">
        <div className="input-group rounded-1 mb-5">
         <span className="input-group-text">
          <BsSearch size={20} />
         </span>
         <input
          type="search"
          className="form-control "
          placeholder="Search for anything!"
         />
         <span className="input-group-text">
          <BsSend size={20} className="fw-bold text-primary" />
         </span>
        </div>
       </div>
       <div className="col-lg-6">
        <div className="card">
         <div className="card-body">
          <BsPostageFill className="text-center h1 fw-normal text-primary d-block mb-4" />
          <h3 className="h2 fw-normal">Pots</h3>
          <p className="h5 fw-normal">
           Understanding how to create and manage your investments pots
          </p>
         </div>
        </div>
       </div>
       <Link to={PAGES_URL.FEATURES} target="_blank" className="col-lg-6">
        <div className="card bg-primary">
         <div className="card-body">
          <BsWallet2 className=" h1 fw-normal text-white d-block mb-4" />
          <h3 className="text-white h2 fw-normal">Wallet</h3>
          <p className="h5 fw-normal text-white">
           Learn how to fund and manage your personal wallet with Senga
          </p>
         </div>
        </div>
       </Link>
       <div className="col-lg-6 mt-lg-5">
        <div className="card">
         <div className="card-body">
          <BsBarChartLineFill className="text-center h1 fw-normal text-primary d-block mb-4" />
          <h3 className="h2 fw-normal">Risk Levels</h3>
          <p className="h5 fw-normal">
           Learn Senga's Risk Levels Mitigate financial safety and earnings
          </p>
         </div>
        </div>
       </div>
       <div className="col-lg-6 mt-lg-5">
        <div className="card">
         <div className="card-body">
          <BsListColumns className="text-center h1 fw-normal text-primary d-block mb-4" />
          <h3 className="h2 fw-normal">Billing</h3>
          <p className="h5 fw-normal">
           Track how Senga is managing and investing your assets realtime
          </p>
         </div>
        </div>
       </div>
      </div>
     </div>
     <div className="col-lg-5">
      <h4 className="h2 fw-normal mb-0">Frequest Asked Questions</h4>
      <p className="h4 fw-normal">
       Related questions we found other customer's asking
      </p>
      <div className="card rounded-2">
       <div className="card-body">
        <div className="accordion" id="accordionDefault">
         <div className="accordion-item">
          <h3 className="accordion-header h4" id="headingOne">
           <button
            className="accordion-button h5  fw-medium"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseOne"
            aria-expanded="true"
            aria-controls="collapseOne"
           >
            What is Senga?
           </button>
          </h3>
          <div
           className="accordion-collapse collapse "
           id="collapseOne"
           aria-labelledby="headingOne"
           data-bs-parent="#accordionDefault"
          >
           <div className="accordion-body fs-sm">
            This is the first item's accordion body. It is hidden by default,
            until the collapse plugin adds the appropriate classes that we use
            to style each element.
           </div>
          </div>
         </div>

         <div className="accordion-item">
          <h3 className="accordion-header" id="headingTwo">
           <button
            className="accordion-button h5  fw-medium collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseTwo"
            aria-expanded="false"
            aria-controls="collapseTwo"
           >
            How long does it takes my account to be verified?
           </button>
          </h3>
          <div
           className="accordion-collapse collapse"
           id="collapseTwo"
           aria-labelledby="headingTwo"
           data-bs-parent="#accordionDefault"
          >
           <div className="accordion-body fs-sm">
            This is the second item's accordion body. It is hidden by default,
            until the collapse plugin adds the appropriate classes that we use
            to style each element.
           </div>
          </div>
         </div>

         <div className="accordion-item">
          <h3 className="accordion-header" id="headingThree">
           <button
            className="accordion-button h5  fw-medium collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseThree"
            aria-expanded="false"
            aria-controls="collapseThree"
           >
            Daily transaction limits?
           </button>
          </h3>
          <div
           className="accordion-collapse collapse"
           id="collapseThree"
           aria-labelledby="headingThree"
           data-bs-parent="#accordionDefault"
          >
           <div className="accordion-body fs-sm">
            This is the third item's accordion body. It is hidden by default,
            until the collapse plugin adds the appropriate classes that we use
            to style each element.
           </div>
          </div>
         </div>
         <div className="accordion-item">
          <h3 className="accordion-header" id="headingThree">
           <button
            className="accordion-button h5  fw-medium collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseThree"
            aria-expanded="false"
            aria-controls="collapseThree"
           >
            Can I cancel my investment at anytime?
           </button>
          </h3>
          <div
           className="accordion-collapse collapse"
           id="collapseThree"
           aria-labelledby="headingThree"
           data-bs-parent="#accordionDefault"
          >
           <div className="accordion-body fs-sm">
            This is the third item's accordion body. It is hidden by default,
            until the collapse plugin adds the appropriate classes that we use
            to style each element.
           </div>
          </div>
         </div>
         <div className="accordion-item">
          <h3 className="accordion-header" id="headingThree">
           <button
            className="accordion-button h5  fw-medium collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseThree"
            aria-expanded="false"
            aria-controls="collapseThree"
           >
            Maximum amount I can invest in a Pot?
           </button>
          </h3>
          <div
           className="accordion-collapse h5  fw-medium collapse"
           id="collapseThree"
           aria-labelledby="headingThree"
           data-bs-parent="#accordionDefault"
          >
           <div className="accordion-body fs-sm">
            This is the third item's accordion body. It is hidden by default,
            until the collapse plugin adds the appropriate classes that we use
            to style each element.
           </div>
          </div>
         </div>
         <div className="accordion-item">
          <h3 className="accordion-header" id="headingThree">
           <button
            className="accordion-button h5  fw-medium collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseThree"
            aria-expanded="false"
            aria-controls="collapseThree"
           >
            Contact Information?
           </button>
          </h3>
          <div
           className="accordion-collaps h5  fw-medium collapse"
           id="collapseThree"
           aria-labelledby="headingThree"
           data-bs-parent="#accordionDefault"
          >
           <div className="accordion-body fs-sm">
            This is the third item's accordion body. It is hidden by default,
            until the collapse plugin adds the appropriate classes that we use
            to style each element.
           </div>
          </div>
         </div>
        </div>
       </div>
      </div>
     </div>
    </div>
   </div>
  </DashboardComponent>
 );
};

export default Help;
