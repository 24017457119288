/** @format */

import {
 SET_USER,
 UPDATE_PASSWORD_REQUEST,
 UPDATE_USER_FAILURE,
 UPDATE_USER_REQUEST,
 UPDATE_USER_SUCCESS,
} from "@redux/reducers/types";

type Action = { type: string; payload?: any }; // Define the Action type

const initialState = {
 data: {},
 response: null,
};

export default function userReducer(state = initialState, action: Action) {
 switch (action.type) {
  case UPDATE_USER_REQUEST:
  case UPDATE_PASSWORD_REQUEST:
   return { ...state, response: null };
  case SET_USER:
  case UPDATE_USER_SUCCESS:
   return { ...state, data: action.payload };
  case UPDATE_USER_SUCCESS:
   return { ...state };
  case UPDATE_USER_FAILURE:
  case UPDATE_USER_FAILURE:
   return {
    ...state,
   };
  default:
   return state;
 }
}
