/** @format */

import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import store from "@redux/store";
import { saveState } from "@redux/localStorage";
import App from "./App";
import "bootstrap/dist/js/bootstrap.bundle";
import "@popperjs/core";
import "@assets/styles/app.scss";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Redux store subscription
store.subscribe(() => {
 saveState(store.getState());
});

const root = ReactDOM.createRoot(
 document.getElementById("root") as HTMLElement
);

root.render(
 <Provider store={store}>
  <ToastContainer
   position="top-left"
   autoClose={5000}
   hideProgressBar={false}
   newestOnTop
   closeOnClick
   rtl={false}
   pauseOnFocusLoss
   draggable
   pauseOnHover
   // theme="colored"
  />
  <App />
 </Provider>
);
