/** @format */

import { CLEAR_ERROR, SET_LOADING } from "../reducers/types";

export const setLoading = (isLoading: boolean) => ({
 type: SET_LOADING,
 payload: isLoading,
});

export const clearError = () => ({
 type: CLEAR_ERROR,
 payload: null,
});
