/** @format */

import {
 AUTHENTICATED,
 CLEAR_ERROR,
 FORGOT_PASSWORD_FAILURE,
 FORGOT_PASSWORD_REQUEST,
 FORGOT_PASSWORD_SUCCESS,
 LOGIN_FAILURE,
 LOGIN_REQUEST,
 LOGIN_SUCCESS,
 LOGOUT,
 REGISTER_FAILURE,
 REGISTER_REQUEST,
 REGISTER_SUCCESS,
} from "@redux/reducers/types";

type Action = { type: string; payload?: any }; // Define the Action type

const initialState = {
 isAuthenticated: false,
 isLoading: false,
 user: null,
 response: null,
};

export default function authReducer(state = initialState, action: Action) {
 switch (action?.type) {
  case LOGIN_REQUEST:
  case REGISTER_REQUEST:
  case FORGOT_PASSWORD_REQUEST:
   return {
    ...state,
    isLoading: true,
   };
  case LOGIN_SUCCESS:
   return {
    ...state,
    isLoading: false,
   };
  case AUTHENTICATED: {
   return {
    ...state,
    user: action.payload,
    isAuthenticated: true,
   };
  }
  case LOGIN_FAILURE:
  case REGISTER_FAILURE:
  case FORGOT_PASSWORD_FAILURE:
   return {
    ...state,
    user: null,
    isAuthenticated: false,
    response: action.payload,
    isLoading: false,
   };
  case REGISTER_SUCCESS:
   return {
    ...state,
    isLoading: false,
    response: action.payload,
   };

  case FORGOT_PASSWORD_SUCCESS:
   return {
    ...state,
    isLoading: false,
    response: action.payload,
   };

  case CLEAR_ERROR:
   return {
    ...state,
    response: action.payload,
    isLoading: false,
   };

  case LOGOUT:
   return {
    ...initialState,
   };
  default:
   return state;
 }
}
