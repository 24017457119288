/** @format */

import React, { useEffect, useState } from "react";
import DashboardComponent from "@components/dashboard/DashboardComponent";
import { useHistory } from "react-router-dom";
import PAGES_URL from "@routers/routes";
import { BsArrowRight, BsBoxFill, BsCheckCircleFill } from "react-icons/bs";
import { connect, useSelector } from "react-redux";
import { setPotError, setPotTypeData } from "@redux/actions/pots"; // Assuming this is the correct import path
import supabase from "@lib/supabase/client";
import { getPotTypeData } from "@redux/selectors";

interface NewInvestmentProps {
 handlePotTypeData: typeof setPotTypeData;
 potError: typeof setPotError;
 error: typeof Error;
}

interface PotTypeItem {
 pot_type_id: string;
 name: string;
 short_description: string;
 long_description: string[];
 min_amount: string;
 pot_type_risk_mandates: RiskMandate[];
}

interface RiskMandate {
 risk_mandate_id: string;
 name: string;
 description: string;
 risk_mandate_assets: {
  asset_id: string;
  asset_weight: number;
  assets: {
   asset_name: string;
  };
 }[];
}

const NewInvestment: React.FC<NewInvestmentProps> = ({ handlePotTypeData }) => {
 const history = useHistory();
 const [potTypeItems, setPotTypeItems] = useState<PotTypeItem[]>([]);
 const [selectedPotTypeItem, setSelectedPotTypeItem] =
  useState<PotTypeItem | null>(null);
 const existingPotTypeData = useSelector(getPotTypeData);

 const [loading, setLoading] = useState(true);

 useEffect(() => {
  const fetchPotTypes = async () => {
   const { data, error } = await supabase.functions.invoke("pot-types/list", {
    method: "GET",
   });

   if (error) {
    setLoading(false); // Set loading to false on error
    return;
   }

   setPotTypeItems((data.data as PotTypeItem[]) || []);
   setLoading(false); // Set loading to false once data has been set
  };

  fetchPotTypes();
 }, []);

 useEffect(() => {
  if (existingPotTypeData) {
   setSelectedPotTypeItem(existingPotTypeData);
  }
 }, [existingPotTypeData]);

 const handlePotTypeSelection = (potTypeId: string) => {
  const selectedItem = potTypeItems.find(
   (item) => item.pot_type_id === potTypeId
  );
  handlePotTypeData(selectedItem || null);
  setSelectedPotTypeItem(selectedItem || null);

  history.push(PAGES_URL.CREATE_POT); // Navigate to the next page if a pot type has been selected
 };

 return (
  <>
   <DashboardComponent pageTitle={"Choose pot type"}>
    <section className="section-fluid">
     <div className="d-flex g-3 align-items-center mb-3">
      <h1 className="ls-tight">
       <BsBoxFill /> Choose Pricing
      </h1>
     </div>
     <div className="">
      {loading ? (
       <div>Loading...</div>
      ) : potTypeItems.length > 0 ? (
       <div className="row">
        {potTypeItems.map((item, idx) => (
         <>
          <div className="col-lg-6">
           <div
            className={`card slick-item ${
             idx == 1 ? "primary" : "secondary"
            }  border-0 shadow-4 shadow-6-hover`}
           >
            <div className="card-body">
             <div className="">
              <h3 className="text-white h3 zindex-10 mb-1 ">{item.name}</h3>
              <div className="d-flex align-items-center my-5 ">
               <span className="d-block display-5 text-white">
                <span className="d-block h5 mb-0 fw-light text-white">
                 Starting Price.
                </span>
                ${item.min_amount}.00
               </span>
              </div>
              <p className="text-white mb-4 h5 fw-normal w-75">
               {item.short_description}
              </p>
              <div className=" mb-2 d-flex justify-content-between align-items-center">
               <span className="text-sm text-white fw-semibold">
                Start Investing!
               </span>{" "}
               <a
                onClick={() => handlePotTypeSelection(item.pot_type_id)}
                className="stretched-link text-white zindex-5 pointer"
                target="_blank"
               >
                <BsArrowRight size={25} />
               </a>
              </div>
             </div>
            </div>
           </div>
           <ul className="list-unstyle mt-5">
            {item.long_description &&
             item.long_description.map((list, listIdx) => (
              <li key={listIdx} className="py-2 d-flex align-items-start">
               <div className="me-3">
                <BsCheckCircleFill
                 className={`${idx == 1 ? "text-primary" : "text-deep"}`}
                />
               </div>
               <p className="h5 fw-normal">{list}</p>
              </li>
             ))}
           </ul>
          </div>
         </>
        ))}
       </div>
      ) : (
       <div>There are no pot types available.</div>
      )}
     </div>
    </section>
   </DashboardComponent>
  </>
 );
};

const mapStateToProps = (state: any) => ({
 potTypeData: state.potTypeData,
 error: state.pots.error || {},
});

const mapDispatchToProps = {
 handlePotTypeData: setPotTypeData,
 potError: setPotError,
};

// Use connect to create a new, connected component
export default connect(mapStateToProps, mapDispatchToProps)(NewInvestment);
