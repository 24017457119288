/** @format */

import React, { useEffect } from "react";
// @ts-ignore TODO: fix this
import { flows } from "@ballerine/web-sdk";

// import FormWizard from "react-form-wizard-component";
// import "react-form-wizard-component/dist/style.css";
import DashboardComponent from "@components/dashboard/DashboardComponent";
import { defaultInitConfig, defaultModalFlowConfig } from "@configs/ballarine";
import Button from "@components/Buttons";
import { Icons } from "@assets/images";
import { Link } from "react-router-dom";
import PAGES_URL from "@routers/routes";
// import { InputField } from "@components/Inputs";
// import Personal from "./Personal";
// import Identification from "./Identification";
// import Bank from "./Bank";

const Verification = () => {
 useEffect(() => {
  (async () => {
   await flows.init(defaultInitConfig);
  })();
 });

 const startFlow = async () => {
  const elementId = "test-id";
  // 3. Mount selected flow on an element
  flows.mount({
   flowName: "my-kyc-flow",
   elementId,
   callbacks: {
    onFlowNavigationUpdate(payload) {
     console.log("onFlowNavigation", payload);
    },
    onFlowComplete(payload) {
     console.log("onFlowComplete", payload);
    },
   },
  });
  // 4. Listen to finish event (see events)
  //  ballerineFlows.on("finish", doSomethingFn);
 };

 return (
  <>
   <DashboardComponent pageTitle="Upgrade Account">
    <section className="min-vh-100 ">
     <div className="row vh-100 justify-content-center align-items-center">
      <div className="col-md-8 text-center" id="test-id">
       <img src={Icons.KYC_BANNER} className="mb-5" width={200} alt="" />
       <h3 className="text-primary">Please Complete Required Information </h3>
       <p className="lead-sm">
        You are a few steps from using Senga, provide the information needed
        below and submit your necessary documents. Your account should be
        activated within 48 hours. You can reach out to our support team via our
        email or channel or live chat
       </p>

       <div className="card mt-5">
        <div className="card-body">
         <div className="d-lg-flex align-items-center  justify-content-between my-4">
          <div className="list text-start">
           <h5 className="text-primary my-0">Personal Information</h5>
           <p className=" lead-sm ">Let's get to know you better</p>
          </div>
          <Link to={PAGES_URL.PROFILE} className="btn btn-primary ">
           Complete profile
          </Link>
         </div>
         <hr />
         <div className="d-lg-flex align-items-center  justify-content-between my-4">
          <div className="list text-start">
           <h5 className="text-primary my-0">Upload Documemts</h5>
           <p className=" lead-sm ">Proof that you are a real person</p>
          </div>
          <button onClick={startFlow} className="btn  btn-primary">
           Upload Documents
          </button>
         </div>
         <hr />
         <div className="d-lg-flex align-items-center  justify-content-between my-4">
          <div className="list text-start">
           <h5 className="text-primary my-0">Save Banking details</h5>
           <p className="lead-sm ">Input your banking and payout details</p>
          </div>
          <a className="btn  btn-primary">Add bank details</a>
         </div>
        </div>
       </div>
      </div>
     </div>
    </section>
   </DashboardComponent>
  </>
 );
};

export default Verification;
