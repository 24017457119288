/** @format */
// auth types

export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILURE = "REGISTER_FAILURE";
export const REGISTER_REQUEST = "REGISTER_REQUEST";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGOUT = "LOGOUT";
export const AUTHENTICATED = "AUTHENTICATED";

export const SET_LOADING = "SET_LOADING";
export const CLEAR_ERROR = "CLEAR_ERROR";

export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";
export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";

export const SET_USER = "SET_USER ";

export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";

export const UPDATE_AVATAR_REQUEST = "UPDATE_AVATAR_REQUEST";
export const UPDATE_AVATAR_FAILURE = "UPDATE_AVATAR_FAILURE";
export const UPDATE_AVATAR_SUCCESS = "UPDATE_AVATAR_SUCCESS";

export const UPDATE_PASSWORD_REQUEST = "UPDATE_PASSWORD_REQUEST";
export const UPDATE_PASSWORD_FAILURE = "UPDATE_PASSWORD_FAILURE";
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";

export const SET_GENDER_DATA = "SET_GENDER_DATA";
export const SET_COUNTRY_DATA = "SET_COUNTRY_DATA";

export const ResponseActionTypes = {
 PASSWORD_UPDATE_SUCCESS: "PASSWORD_UPDATE_SUCCESS",
 PASSWORD_UPDATE_ERROR: "PASSWORD_UPDATE_ERROR",
};

export const InvestmentPotActionTypes = {
 SET_POT_ERROR: "SET_POT_ERROR",
 SET_POT_TYPE_DATA: "SET_POT_TYPE_DATA",
 SET_POT_DATA: "SET_POT_DATA",
 RESET_INVESTMENT_POT: "RESET_INVESTMENT_POT",
};
