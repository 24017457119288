/** @format */
import React from "react";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";

type Props = {
 data: any;
};

const RiskLevelChart: React.FC<Props> = ({ data }: Props) => {
 const series = data?.risk_mandates?.risk_mandate_assets.map(function (
  element: any
 ) {
  return element.asset_weight;
 });

 const labels = data?.risk_mandates?.risk_mandate_assets.map(function (
  element: any
 ) {
  return element.assets.asset_name;
 });

 const options: ApexOptions = {
  series: series || [],
  colors: ["#5f236e", "#5fa36e", "#8f236e", "#1f236e"],
  chart: {
   type: "radialBar",
   offsetY: -10,
   fontFamily: "Satoshi",
  },
  plotOptions: {
   radialBar: {
    startAngle: -135,
    endAngle: 135,
   },
  },
  legend: {
   show: true,
   position: "bottom",
   horizontalAlign: "left",
   floating: false,
   fontSize: "14px",
   fontWeight: 400,
   formatter: (seriesName, opts) => {
    return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex] + "%\n";
    // return [[label + ":" + opts.w.globals.series[opts.seriesIndex]], "%"];
   },
   inverseOrder: false,
   tooltipHoverFormatter: undefined,
   offsetX: 0,
   offsetY: 10,
   labels: {
    colors: undefined,
    useSeriesColors: false,
   },
   markers: {
    width: 12,
    height: 12,
    strokeWidth: 0,
    strokeColor: "#fff",
    fillColors: undefined,
    radius: 12,
    customHTML: undefined,
    onClick: undefined,
    offsetX: 0,
    offsetY: 0,
   },
   itemMargin: {
    horizontal: 5,
    vertical: 50,
   },
  },
  labels: labels || [],
 };

 return (
  <>
   <ReactApexChart
    options={options}
    series={options.series}
    type="radialBar"
    height={"100%"}
   />
  </>
 );
};

const RiskLevelChartSingle: React.FC<Props> = ({ data }: Props) => {
 const options: ApexOptions = {
  series: [data.weight || 0],
  chart: {
   type: "radialBar",
   offsetY: -10,
  },
  plotOptions: {
   radialBar: {
    startAngle: -135,
    endAngle: 135,
    dataLabels: {
     name: {
      fontFamily: "Satoshi",
      fontSize: "16px",
      color: "#5f236e",
      offsetY: 120,
     },
     value: {
      offsetY: 76,
      fontSize: "22px",
      color: "#5f236e",
      formatter: function (val) {
       return val + "%";
      },
     },
    },
   },
  },
  fill: {
   type: "gradient",
   colors: ["#5f236e"],
   gradient: {
    shade: "dark",
    shadeIntensity: 0.15,
    inverseColors: false,
    opacityFrom: 1,
    opacityTo: 1,
    stops: [0, 50, 65, 91],
   },
  },
  stroke: {
   dashArray: 4,
  },
  labels: [data.name || 0],
 };

 return (
  <>
   <ReactApexChart
    options={options}
    series={options.series}
    width={"100%"}
    type="radialBar"
   />
  </>
 );
};

export { RiskLevelChart, RiskLevelChartSingle };
