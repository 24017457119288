/** @format */

import { UpdateUser } from "./types/user.type";

const LoginValidator = (values: any) => {
 type FormField = {
  email?: string;
  password?: string;
 };
 const errors = {} as FormField;

 if (!values.email) {
  errors.email = "Email is required";
 } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
  errors.email = "Invalid email address";
 }

 if (!values.password) {
  errors.password = "A password is required";
 }

 return errors;
};

const RegisterValidator = (values: any) => {
 type FormField = {
  first_name?: string;
  last_name?: string;
  email?: string;
  password?: string;
 };
 const errors = {} as FormField;

 if (!values.first_name) {
  errors.first_name = "First Name is required";
 }

 if (!values.last_name) {
  errors.last_name = "Last Name is required";
 }

 if (!values.email) {
  errors.email = "Email is required";
 } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
  errors.email = "Invalid email address";
 }

 if (!values.password) {
  errors.password = "A password is required";
 } else if (values.password.length < 8) {
  errors.password = "Password must be at least 8 characters";
 }

 return errors;
};

const ForgotValidator = (values: any) => {
 type FormField = {
  email?: string;
 };
 const errors = {} as FormField;
 if (!values.email) {
  errors.email = "Email is required";
 } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
  errors.email = "Invalid email address";
 }

 return errors;
};

type FormField = {
 password?: string;
 confirm?: string;
};
const ConfirmPasswordValidator = (values: any) => {
 const errors = {} as FormField;

 if (!values.password) {
  errors.password = "Password is required";
 } else if (values.password.length < 8) {
  errors.password = "Password must be at least 8 characters";
 }

 if (!values.confirm) {
  errors.confirm = "Confirmation Password is required";
 } else if (values.password !== values.confirm) {
  errors.confirm = "Password does not match";
 }

 return errors;
};

const UpdateUserValidator = (values: UpdateUser) => {
 const errors: any = {} as UpdateUser;

 if (!values.first_name) {
  errors.first_name = "First name is required";
 }

 if (!values.last_name) {
  errors.last_name = "Last name is required";
 }

 if (!values.phone) {
  errors.phone = "Phone number is required";
 }

 //  if (!values.email) {
 //   errors.email = "Email is required";
 //  }

 if (!values.address) {
  errors.address = "Address is required";
 }
 if (!values.nationality_id) {
  errors.nationality_id = "Please chooose a valid country";
 }

 if (!values.gender_id) {
  errors.gender_id = "Please choose your preferred gender";
 }
 return errors;
};

const CreatePotValidator = (values: any) => {
 const errors = {} as any;

 if (!values.name) {
  errors.name = "Pot name is required";
 }

 if (!values.monthly_contribution_start_date) {
  errors.monthly_contribution_start_date =
   "Pot contribution start period is required";
 }

 if (!values.riskMandate) {
  errors.riskMandate = "A selected risk mandate is required";
 }

 if (!values.starting_contribution_fee) {
  errors.starting_contribution_fee = "Starting Contribution is required";
 } else if (isNaN(Number(values.starting_contribution_fee))) {
  errors.starting_contribution_fee =
   "Starting Contribution must be a valid number";
 }

 return errors;
};

interface ContributionValues {
 startingContribution: string;
 monthlyContribution?: string;
}

const ContributionValidator = (values: ContributionValues) => {
 const errors: { [key: string]: string } = {};

 // Validate startingContribution
 if (!values.startingContribution) {
  errors.startingContribution = "Starting contribution is required";
 } else if (isNaN(Number(values.startingContribution))) {
  errors.startingContribution = "Starting contribution must be a valid number";
 }

 // Validate monthlyContribution if provided
 if (values.monthlyContribution && isNaN(Number(values.monthlyContribution))) {
  errors.monthlyContribution = "Monthly contribution must be a valid number";
 }

 return errors;
};


type CreditCardFields = {
 cardNumber?: string;
 expiryDate?: string; // assuming MM/YY or MM/YYYY format
 cvv?: string;
};

export const RiskLevelValidator = (values: any) => {return {}}

const CreditCardValidator = (values: any) => {
 const errors = {} as CreditCardFields;

 // Validate card number using Luhn algorithm
 if (!values.cardNumber) {
  errors.cardNumber = "Card number is required";
 } else if (!isValidCardNumber(values.cardNumber)) {
  errors.cardNumber = "Invalid card number";
 }

 // Validate expiration date
 if (!values.expiryDate) {
  errors.expiryDate = "Expiry date is required";
 } else if (!isValidExpiryDate(values.expiryDate)) {
  errors.expiryDate = "Invalid or expired date";
 }

 // Validate CVV
 if (!values.cvv) {
  errors.cvv = "CVV is required";
 } else if (!isValidCVV(values.cvv)) {
  errors.cvv = "Invalid CVV";
 }

 return errors;
};

// Helper function to validate card number using Luhn algorithm
const isValidCardNumber = (number: string) => {
 let numCheck = 0,
  numDigit = false,
  numTotal = 0;

 // Remove all spaces from the number string
 const sanitizedNumber = number.replace(/\s+/g, "");

 const numArray = sanitizedNumber.split("").reverse();

 for (let i = 0; i < numArray.length; i++) {
  numCheck = parseInt(numArray[i], 10);

  if (numDigit) {
   numCheck *= 2;

   if (numCheck > 9) {
    numCheck -= 9;
   }
  }

  numTotal += numCheck;
  numDigit = !numDigit;
 }

 return numTotal % 10 === 0;
};

// Helper function to validate expiry date
const isValidExpiryDate = (expiry: string) => {
 const [year, month] = expiry.split("-"); // Split the expiry string into year and month
 const now = new Date();
 const expiryDate = new Date(`${year}-${month}-01`); // Construct the expiry date

 return expiryDate > now; // Compare the expiry date to the current date
};

// Helper function to validate CVV
const isValidCVV = (cvv: string) => {
 const cvvRegex = /^\d{3,4}$/;
 return cvvRegex.test(cvv);
};

export {
 LoginValidator,
 RegisterValidator,
 ForgotValidator,
 ConfirmPasswordValidator,
 UpdateUserValidator,
 CreatePotValidator,
 CreditCardValidator,
 ContributionValidator,
};
