/** @format */

import React from "react";
import DashboardComponent from "@components/dashboard/DashboardComponent";
import NotificationSettings from "./components/NotificationSettings";
import DeleteAccountSettings from "./components/DeleteAccount";
import EditAccountSettings from "./components/EditAccountSettings";
import UpdatePassword from "./components/UpdatePassword";

const Profile = () => {
 return (
  <>
   <DashboardComponent pageTitle={"Profile Settings"}>
    <div className="section-fluid">
     <div className="row">
      <div className="col-lg-8">
       <EditAccountSettings />
       <UpdatePassword />
       <NotificationSettings />
       <DeleteAccountSettings />
      </div>
     </div>
    </div>
   </DashboardComponent>
  </>
 );
};

export default Profile;
