/** @format */
import DashboardComponent from "@components/dashboard/DashboardComponent";
import React from "react";
import PotDashboard from "./components/PotDashboard";
import Button from "@components/Buttons";
import { Link, useLocation } from "react-router-dom";
import PAGES_URL from "@routers/routes";
import { BsCheckCircle } from "react-icons/bs";
import { resetInvestmentPot } from "@redux/actions/pots";
import { connect } from "react-redux";

interface PotSuccessProps {
 message: string;
 resetInvestmentPot: typeof resetInvestmentPot;
}

const PotSuccess: React.FC<PotSuccessProps> = ({ message }) => {
 const id = new URLSearchParams(useLocation().search).get("id");
 const handleButtonClick = () => {
  resetInvestmentPot(); // Call the resetInvestmentPot action
 };
 return (
  <DashboardComponent pageTitle="Success!">
   <PotDashboard progress={100}>
    <div className="d-flex flex-column">
     <div className="col-lg-12">
      <h5 className="fw-normal mt-4">Success!</h5>
      <p className="fw-light">Your pot was successfully created.</p>

      <div className="card px-lg-5 px-2 fw-light">
       <div className="card-body text-center">
        <BsCheckCircle className="success-icon" size={72} color="#28a745" />
        <p className="pt-2 pt-lg-3">
         Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
         convallis sapien a elit suscipit posuere. Donec imperdiet posuere
         volutpat. Proin ex nisi, vestibulum.{message}
        </p>
       </div>
      </div>

      <div className="mt-1 mt-lg-5">
       <div className="d-flex justify-content-end">
        <Link to={PAGES_URL.DASHBOARD_HOME} className="me-3">
         {" "}
         {/* Added line */}
         <Button
          onClick={handleButtonClick}
          className={"btn-light mt-5 btn__lg"}
         >
          {" "}
          {/* Updated className */}
          Back to Home
         </Button>
        </Link>
        <Link
         to={{
          pathname: PAGES_URL.DETAILS,
          search: `id=${id}`,
          state: { potId: id },
         }}
        >
         <Button
          onClick={handleButtonClick}
          className={"btn-primary mt-5 btn__lg"}
         >
          View your Pot
         </Button>
        </Link>
       </div>
      </div>
     </div>
    </div>
   </PotDashboard>
  </DashboardComponent>
 );
};

const mapStateToProps = (state: any) => ({
 // Map any necessary state to props here
});

const mapDispatchToProps = {
 resetInvestmentPot, // Make sure to include this in mapDispatchToProps
};

export default connect(mapStateToProps, mapDispatchToProps)(PotSuccess);
