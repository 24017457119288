/** @format */

import supabase from "@lib/supabase/client";

interface PotData {
 user_id: string;
 pot_type_id: string;
 name: string;
 monthly_contribution_start_date?: string; // The '?' indicates that this property is optional
 monthly_contribution_fee?: number;
 starting_contribution_fee: number;
 base_currency?: string;
 risk_mandate_id: string;
 meta_data?: Record<string, any>; // This type means an object with string keys and any type values
}
async function createPot(potData: PotData) {
 try {
  const { data, error } = await supabase.functions.invoke("pots/create", {
   body: potData,
  });
  if (error) throw error;
  return data;
 } catch (error) {
  console.error(error);
  throw error;
 }
}

export default createPot;
