/** @format */

import { logOutAction } from "@redux/actions/authentication";
import supabase from "@lib/supabase/client";

const sessionExpirationMiddleware =
 (store: any) => (next: any) => async (action: any) => {
  const state = store.getState();

  // Check if the user is authenticated and if there's a token with an expiration time.
  if (
   state.auth.isAuthenticated &&
   state.auth.user &&
   state.auth.user.access_token
  ) {
   await supabase.auth.setSession({
    access_token: state.auth.user.access_token,
    refresh_token: state.auth.user.refresh_token,
   });
   const session = state.auth.user;
   // Convert the expiration time to a Date object.
   const expirationDate = new Date(session.expires_at * 1000);

   // If the token has expired, dispatch a logout action.
   if (expirationDate < new Date()) {
    store.dispatch(logOutAction());
   }
  }

  return next(action);
 };

export default sessionExpirationMiddleware;
