/** @format */
import React from "react";
import AppComponent from "@components/AppComponent";
import { Banner, Hero, Teams } from "@assets/images";
import { Link } from "react-router-dom";
import PAGES_URL from "@routers/routes";

const Features = () => {
 return (
  <AppComponent>
   <div className="container pt-5 pb-lg-5 pb-md-4 pb-2 my-5">
    <nav aria-label="breadcrumb">
     <ol className="pt-lg-3 pb-lg-4 pb-2 breadcrumb">
      <li className="breadcrumb-item">
       <a href="index.html">Home</a>
      </li>
      <li className="breadcrumb-item active" aria-current="page">
       Features
      </li>
     </ol>
    </nav>

    <div className="row align-items-center gy-2 mb-4 pb-1 pb-sm-2 pb-lg-3">
     <div className="col-lg-5">
      <h1 className="mb-lg-0">Features</h1>
     </div>
     <div className="col-xl-2 offset-xl-1 col-lg-3 col-sm-5">
      <select className="form-select">
       <option>All categories</option>
       <option>Inspiration</option>
       <option>Brand strategy</option>
       <option>Advertisement</option>
       <option>Ecommerce</option>
       <option>Travel &amp; Vacation</option>
      </select>
     </div>
     <div className="col-lg-4 col-sm-7">
      <div className="position-relative">
       <i className="ai-search position-absolute top-50 start-0 translate-middle-y ms-3"></i>
       <input
        className="form-control ps-5"
        type="search"
        placeholder="Enter keyword"
       />
      </div>
     </div>
    </div>

    <div className="masonry-grid mb-2 mb-md-4 pb-lg-3" data-columns="3">
     <article className="masonry-grid-item">
      <div className="card border-0 bg-secondary">
       <div className="card-body pb-4">
        <div className="d-flex align-items-center mb-4 mt-n1">
         <span className="fs-sm text-body-secondary">9 hours ago</span>
         <span className="fs-xs opacity-20 mx-3">|</span>
         <a className="badge text-nav fs-xs border" href="#">
          How it works
         </a>
        </div>
        <h3 className="h4 card-title">
         <Link to={PAGES_URL.FEATURES_SINGLE}>
          Introducing Senga Wallet: Your Gateway to Seamless Wealth Growth
         </Link>
        </h3>
        <p className="card-text">
         At Senga, we understand the importance of simplicity and convenience
         when it comes to managing your investments. That's why we're thrilled
         to present the Senga Wallet ....
        </p>
       </div>
       <div className="card-footer pt-3">
        <a
         className="d-flex align-items-center text-decoration-none pb-2"
         href="#"
        >
         <img
          className="rounded-circle"
          src={Teams._02}
          width="48"
          alt="Post author"
         />
         <h6 className="ps-3 mb-0">Darlene Robertson</h6>
        </a>
       </div>
      </div>
     </article>

     <article className="masonry-grid-item">
      <div className="card border-0 bg-secondary">
       <div className="card-body pb-4">
        <div className="d-flex align-items-center mb-4 mt-n1">
         <span className="fs-sm text-body-secondary">12 hours ago</span>
         <span className="fs-xs opacity-20 mx-3">|</span>
         <a className="badge text-nav fs-xs border" href="#">
          How it works
         </a>
        </div>
        <h3 className="h4 card-title">
         <a href="Features-single-v2.html">
          Navigate Your Financial Horizon: Introducing Senga's Risk Level
          Feature
         </a>
        </h3>
        <p className="card-text">
         At Senga, we recognize that every investor has a unique risk tolerance
         and financial goals. To empower you with personalized control over your
         investments....
        </p>
       </div>
       <div className="card-footer pt-3">
        <a
         className="d-flex align-items-center text-decoration-none pb-2"
         href="#"
        >
         <img
          className="rounded-circle"
          src={Teams._04}
          width="48"
          alt="Post author"
         />
         <h6 className="ps-3 mb-0">Jenny Wilson</h6>
        </a>
       </div>
      </div>
     </article>

     <article className="masonry-grid-item">
      <div className="card border-0 bg-secondary">
       <div className="card-body pb-4">
        <div className="d-flex align-items-center mb-4 mt-n1">
         <span className="fs-sm text-body-secondary">13 hours ago</span>
         <span className="fs-xs opacity-20 mx-3">|</span>
         <a className="badge text-nav fs-xs border" href="#">
          How it works
         </a>
        </div>
        <h3 className="h4 card-title">
         <a href="Features-single-v3.html">
          Effortless Finance Management: Introducing Senga's Billing Feature
         </a>
        </h3>
        <p className="card-text">
         At Senga, we understand the importance of seamless financial management
         for our users. We're excited to introduce the Senga Billing Feature, a
         powerful ....
        </p>
       </div>
       <div className="card-footer pt-3">
        <a
         className="d-flex align-items-center text-decoration-none pb-2"
         href="#"
        >
         <img
          className="rounded-circle"
          src={Teams._08}
          width="48"
          alt="Post author"
         />
         <h6 className="ps-3 mb-0">Lillia Black</h6>
        </a>
       </div>
      </div>
     </article>

     <article className="masonry-grid-item">
      <div className="card border-0 bg-secondary">
       <div className="card-body pb-4">
        <div className="d-flex align-items-center mb-4 mt-n1">
         <span className="fs-sm text-body-secondary">July 16, 2022</span>
         <span className="fs-xs opacity-20 mx-3">|</span>
         <a className="badge text-nav fs-xs border" href="#">
          How it works
         </a>
        </div>
        <h3 className="h4 card-title">
         <a href="Features-single-v1.html">
          Effortless Transactions, Exceptional Control: Senga's Payment Feature
         </a>
        </h3>
        <p className="card-text">
         Experience a new era of convenience and financial control with the
         Senga Payment Feature. Designed with simplicity and security in mind,
         our payment feature
        </p>
       </div>
       <div className="card-footer pt-3">
        <a
         className="d-flex align-items-center text-decoration-none pb-2"
         href="#"
        >
         <img
          className="rounded-circle"
          src={Teams._02}
          width="48"
          alt="Post author"
         />
         <h6 className="ps-3 mb-0">Guy Hawkins</h6>
        </a>
       </div>
      </div>
     </article>

     <article className="masonry-grid-item">
      <div className="card border-0 bg-secondary">
       <div className="card-body pb-4">
        <div className="d-flex align-items-center mb-4 mt-n1">
         <span className="fs-sm text-body-secondary">July 16, 2022</span>
         <span className="fs-xs opacity-20 mx-3">|</span>
         <a className="badge text-nav fs-xs border" href="#">
          How it works
         </a>
        </div>
        <h3 className="h4 card-title">
         <a href="Features-single-v1.html">
          Unlock Financial Freedom with Senga Pots: Your Personalized Savings
          Solution
         </a>
        </h3>
        <p className="card-text">
         Introducing Senga Pots, a revolutionary feature designed to transform
         the way you save and manage your money. Tailored for your financial
         goals....
        </p>
       </div>
       <div className="card-footer pt-3">
        <a
         className="d-flex align-items-center text-decoration-none pb-2"
         href="#"
        >
         <img
          className="rounded-circle"
          src={Teams._02}
          width="48"
          alt="Post author"
         />
         <h6 className="ps-3 mb-0">Guy Hawkins</h6>
        </a>
       </div>
      </div>
     </article>
    </div>

    <div className="row gy-3 align-items-center mb-md-2 mb-xl-4">
     <div className="col col-md-4 col-6 order-md-1 order-1">
      <div className="d-flex align-items-center">
       <span className="text-body-secondary fs-sm">Show</span>
       <select className="form-select form-select-flush w-auto">
        <option value="6">6</option>
        <option value="9" selected>
         9
        </option>
        <option value="12">12</option>
        <option value="24">24</option>
       </select>
      </div>
     </div>
     <div className="col col-md-4 col-12 order-md-2 order-3 text-center">
      <button className="btn btn-primary w-md-auto w-100" type="button">
       Load more posts
      </button>
     </div>
     <div className="col col-md-4 col-6 order-md-3 order-2">
      <nav aria-label="Page navigation">
       <ul className="pagination pagination-sm justify-content-end">
        <li className="page-item active" aria-current="page">
         <span className="page-link">
          1<span className="visually-hidden">(current)</span>
         </span>
        </li>
        <li className="page-item">
         <a className="page-link" href="#">
          2
         </a>
        </li>
        <li className="page-item">
         <a className="page-link" href="#">
          3
         </a>
        </li>
        <li className="page-item">
         <a className="page-link" href="#">
          4
         </a>
        </li>
        <li className="page-item">
         <a className="page-link" href="#">
          5
         </a>
        </li>
       </ul>
      </nav>
     </div>
    </div>
   </div>
  </AppComponent>
 );
};

export default Features;
