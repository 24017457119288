/** @format */

import { UpdateUserPasswordValidator } from "./update_user_password.validator";

// declare validators
const validator = {
 UpdateUserPasswordValidator,
};

export default validator;
