/** @format */
import React from "react";

const CreditCard = () => {
 return (
  <form className="modal-body needs-validation pt-0 mt-4">
   <div className="mb-4">
    <label className="form-label" htmlFor="card-name">
     Name on card
    </label>
    <input
     className="form-control"
     type="text"
     placeholder="John Doe"
     required
     id="card-name"
    />
   </div>
   <div className="mb-4">
    <label className="form-label" htmlFor="card-number">
     Card number
    </label>
    <div className="input-group">
     <input
      className="form-control"
      type="text"
      data-format='{"creditCard": true}'
      placeholder="XXXX XXXX XXXX XXXX"
      required
      id="card-number"
     />
     <div className="input-group-text py-0">
      <div className="credit-card-icon"></div>
     </div>
    </div>
   </div>
   <div className="row row-cols-2 g-4 pb-2 pb-sm-3 mb-4">
    <div className="col">
     <label className="form-label" htmlFor="card-expiration">
      Expiration date
     </label>
     <input
      className="form-control"
      type="text"
      data-format='{"date": true, "datePattern": ["m", "y"]}'
      placeholder="MM/YY"
      required
      id="card-expiration"
     />
    </div>
    <div className="col">
     <label className="form-label" htmlFor="card-cvv">
      CVV Code
     </label>
     <input
      className="form-control"
      type="text"
      data-format='{"numericOnly": true, "blocks": [3]}'
      placeholder="XXX"
      required
      id="card-cvv"
     />
    </div>
   </div>
   <div className="d-flex flex-column flex-sm-row">
    <button
     className="btn btn-secondary mb-3 mb-sm-0"
     type="reset"
     data-bs-dismiss="modal"
    >
     Cancel
    </button>
    <button className="btn btn-primary ms-sm-3" type="submit">
     Save new card
    </button>
   </div>
  </form>
 );
};

export default CreditCard;
