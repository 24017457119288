/** @format */

import React from "react";
import Button from "@components/Buttons";
import DashboardComponent from "@components/dashboard/DashboardComponent";
const ChangePassword = () => {
 return (
  <DashboardComponent pageTitle={"MY ACCOUNT"}>
   <section className="card border-0 py-1 p-md-2 p-xl-3 p-xxl-4 mb-4">
    <div className="card-body">
     <div className="d-flex align-items-center mt-sm-n1 pb-4 mb-0 mb-lg-1 mb-xl-3">
      <i className="ai-lock-open text-primary lead pe-1 me-2"></i>
      <h2 className="h4 mb-0">Basic info</h2>
     </div>

     <form action="">
      <label htmlFor="" className="fw-light mb-2 small">
       Old Password
      </label>
      <input
       className="form-control mb-5"
       placeholder=""
       type="uid"
       id="uid"
       name="uid"
      />
      <label htmlFor="" className="fw-light mb-2 small">
       New Password
      </label>
      <input
       className="form-control mb-5"
       placeholder=""
       type="uid"
       id="uid"
       name="uid"
      />
      <label htmlFor="" className="fw-light mb-2 small">
       Confirm Password
      </label>
      <input
       className="form-control mb-5"
       placeholder=""
       type="uid"
       id="uid"
       name="uid"
      />
      <Button className={"btn-primary btn__lg ms-auto mt-auto"}>
       Update Settings
      </Button>
     </form>
    </div>
   </section>
  </DashboardComponent>
 );
};

export default ChangePassword;
