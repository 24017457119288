/** @format */
import React, { useEffect, useState } from "react";
import DashboardComponent from "@components/dashboard/DashboardComponent";
import ProjectionChart from "./components/ProjectionChart";
import { Link, useLocation } from "react-router-dom";
import Button from "@components/Buttons";
import PAGES_URL from "@routers/routes";
import supabase from "@lib/supabase/client"; // Import supabase client
import {
 BsArrowLeft,
 BsArrowReturnLeft,
 BsClockHistory,
 BsCurrencyDollar,
 BsQuestionCircle,
} from "react-icons/bs";
import { HoverText } from "@components/dashboard/Util";
import { Icons } from "@assets/images";
import { RiskLevelChartSingle } from "./components/RiskLevelChart";
import Transactions from "../wallet/Transactions";

interface Information {
 title: string;
 value: string;
}

interface Asset {
 name: string;
 weight: number;
}

interface PotData {
 name: string;
 information: Information[];
 assets: Asset[];
}

const transformDataToPotData = (data: any): PotData => {
 // Extract the first item from the data array
 const potInfo = data.data[0];

 // Create an Information array
 const information: Information[] = [
  {
   title: "Starting contribution fee",
   value: `${potInfo.starting_contribution_fee}`,
  },
  ...(potInfo.monthly_contribution_start_date
   ? [
      {
       title: "Monthly Contribution Start Date",
       value: potInfo.monthly_contribution_start_date,
      },
     ]
   : []),
  ...(potInfo.monthly_contribution_fee
   ? [
      {
       title: "Monthly Contribution Fee",
       value: `${potInfo.monthly_contribution_fee}`,
      },
     ]
   : []),
 ];

 const assets: Asset[] = potInfo.meta_data.assets.map((asset: any) => ({
  name: asset.name,
  weight: asset.weight,
 }));

 // Return the transformed data
 return {
  name: potInfo.name,
  information: information,
  assets: assets,
 };
};

const Details = () => {
 const id = new URLSearchParams(useLocation().search).get("id");
 const [potData, setPotData] = useState<PotData | null>(null);

 useEffect(() => {
  const fetchPotData = async () => {
   const { data, error } = await supabase.functions.invoke(`pots/list/${id}`, {
    method: "GET",
   });

   if (error) {
    console.error(error);
    return;
   }

   const transformedData = transformDataToPotData(data);
   setPotData(transformedData);
  };

  // Call the fetchPotData function
  fetchPotData();
 }, [id]); // Dependency array with id to ensure the effect runs once when the component mounts

 if (!potData) {
  return (
   <DashboardComponent pageTitle={"Loading your pot..."}>{}</DashboardComponent>
  ); // Show loading indicator while pot data is being fetched
 }

 return (
  <DashboardComponent pageTitle={potData.name}>
   <div className="section-fluid">
    <div className="d-flex  g-3 align-items-center mb-3">
     <div>
      <h1 className="ls-tight mb-1 d-flex ">
       <Link to={PAGES_URL.WALLET}>
        <BsArrowLeft />
       </Link>{" "}
       &nbsp;{potData.name}
       <HoverText message="Selected Investment Plan" Icon={BsQuestionCircle} />
      </h1>
      <p className="h4 fw-normal">
       Track and manage your investment pot activity
      </p>
     </div>
    </div>
    <div className="row">
     <div className="col-lg-8">
      <ProjectionChart />

      <div className="mt-4">
       <Transactions />
      </div>
     </div>
     <div className="col-lg-4">
      <div className="card  rounded-1 shadow-0">
       <div className="card-body">
        <div className="row">
         <img
          src={Icons.STOCKS}
          className="img-fluid mx-auto"
          style={{ width: "120px", marginBottom: "80px" }}
          alt=""
         />

         <div className="d-flex justify-content-center ">
          <div>
           <p className="fw-medium  h5 mb-0 text-dark-50 text-start">
            {potData.information[0].title}
           </p>
           <div className="d-flex">
            <span className=" display-3">$ </span>
            <span className="display-3 fw-bold">
             {potData.information[0].value}
            </span>
           </div>
          </div>
         </div>

         <div className="mt-5 d-flex justify-content-start align-items-center">
          <BsClockHistory size={30} className="me-lg-5" />
          <div>
           <p className="mb-0"> {potData.information[1].title}</p>
           <h1 className="h4 m-0">{potData.information[1].value}</h1>
          </div>
         </div>
         <div className="mt-5 d-flex justify-content-start align-items-center">
          <BsCurrencyDollar size={30} className="me-lg-5 m-0" />
          <div className="">
           <p className="mb-0"> {potData.information[2].title}</p>
           <h1 className="h4 m-0">{potData.information[2].value}</h1>
          </div>
         </div>
        </div>
        <div className="row mt-lg-5 mt-3">
         {potData.assets.map((asset, index) => (
          <div className="col-lg-6" key={index}>
           <RiskLevelChartSingle data={asset} />
          </div>
         ))}
        </div>
       </div>
      </div>
     </div>
    </div>
   </div>
  </DashboardComponent>
 );
};

export default Details;
