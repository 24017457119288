/** @format */
import React, { useEffect, useState } from "react";
import DashboardComponent from "@components/dashboard/DashboardComponent";
import { Button } from "react-bootstrap";
import { CreatePotValidator } from "@helpers/Validator";
import { BsFileText, BsInfoCircleFill, BsPatchQuestion } from "react-icons/bs";
import { connect, useSelector } from "react-redux";
import { setPotData, setPotError } from "@redux/actions/pots"; // Assuming this is the correct import path
import { HoverText } from "@components/dashboard/Util";
import { RiskLevelChart } from "./components/RiskLevelChart";
import { InputOption } from "@components/Inputs";
import { getPotTypeData, getSelectedRiskMandate } from "@redux/selectors"; // Assuming this is the correct import path
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import history from "@redux/actions/history";
import PAGES_URL from "@routers/routes";

interface PotsProps {
 handlePotData: typeof setPotData;
 handlePotError: typeof setPotError;
}

interface RiskMandate {
 risk_mandate_id: string;
 name: string;
 description: string;
 priority: number;
 risk_mandates: {
  name: string;
  description: string;
  risk_mandate_assets: {
   asset_id: string;
   asset_weight: number;
   assets: {
    asset_name: string;
   };
  }[];
 };
}

type Pot = {
 name?: string;
 monthly_contribution_start_date?: any;
 riskMandate: RiskMandate;
 starting_contribution_fee: number;
 monthly_contribution_fee: number;
};

const CreatePot: React.FC<PotsProps> = (props: any) => {
 const { handlePotData, handlePotError, potData, error } = props;
 const [selectedRiskMandate, setSelectedRiskMandate] =
  useState<RiskMandate | null>(null);
 const potTypeData = useSelector(getPotTypeData);
 const selectedRiskMandateFromStore = useSelector(getSelectedRiskMandate);
 const riskMandates: RiskMandate[] = potTypeData.pot_type_risk_mandates;
 const handleRiskMandateChange = (riskMandate: any) => {
  setSelectedRiskMandate(riskMandate);
 };
 useEffect(() => {
  // If there's a selected risk mandate in the store, use that. Otherwise, use the first risk mandate from riskMandates.
  setSelectedRiskMandate(
   selectedRiskMandateFromStore ||
    (riskMandates && riskMandates.length > 0 ? riskMandates[0] : null)
  );
 }, [selectedRiskMandateFromStore, riskMandates]);

 useEffect(() => {
  if (selectedRiskMandate) {
   handlePotData({ riskMandate: selectedRiskMandate });
  }
 }, [selectedRiskMandate, handlePotData]);

 const handleInputChange = (e: any) => {
  console.log(e);
  const { name, value } = e.target;
  handlePotData({ ...potData, [name]: value }); // Dispatch an action to update the Redux store
 };

 const handleSubmit = (e: any) => {
  try {
   e.preventDefault();
   const errors = CreatePotValidator(potData);
   if (Object.keys(errors).length !== 0) throw errors;
   history.push(PAGES_URL.REVIEW); // Navigate to the next page if a pot type has been selected

   handlePotError({}); // Clear errors in the Redux store
  } catch (err: any) {
   console.log(err);
   handlePotError(err); // Dispatch an action to set errors
  }
 };

 return (
  <DashboardComponent pageTitle={"Create Pot"}>
   <section className="section-fluid">
    <div className="d-flex  g-3 align-items-center mb-3">
     <div>
      <h1 className="ls-tight mb-1 d-flex ">
       🚀
       <span className="d-none d-lg-block ms-2">Setting up&nbsp;</span>{" "}
       {potTypeData.name}
       <HoverText message="Selected Investment Plan" Icon={BsPatchQuestion} />
      </h1>
      <p className="h4 fw-normal">{potTypeData.short_description}</p>
     </div>
    </div>
    <div className="col-lg-9">
     <div className="card">
      <div className="card-body">
       <form className="row">
        <div className="col-lg-8">
         <div className="col-lg-12">
          <label htmlFor="" className="mb-2 fw-medium h5">
           Pot Name
          </label>
          <input
           className={`form-control form-control-lg ${
            error.name && "border-danger"
           }`}
           placeholder="Type here"
           type="text"
           id="name"
           onChange={handleInputChange}
           name="name"
           value={potData.name || ""} // Assume pot is an object of type Pot
          />
          {error.name && (
           <span className="text-danger fw-light">
            <BsInfoCircleFill /> {error.name}
           </span>
          )}
         </div>
         <div className="col-md-12 mt-4">
          <label htmlFor="" className="mb-2 fw-medium h5">
           Contribution start period&nbsp;
           <span className="fw-lighter">(Optional)</span>
          </label>
          <DatePicker
           onChange={(monthly_contribution_start_date) =>
            handlePotData({ monthly_contribution_start_date })
           }
           className="form-control form-control-lg d-block position-relative"
           showMonthYearPicker
           dateFormat={"MMMM yyyy"}
           name="monthly_contribution_start_date"
           selected={
            moment(potData.monthly_contribution_start_date).toDate() ??
            moment().toDate()
           }
           minDate={new Date()}
           maxDate={moment().add(2, "M").toDate()}
           placeholderText="Start period"
          />
         </div>
         {error.start_period && (
          <span className="text-danger fw-light">
           <BsInfoCircleFill /> {error.start_period}
          </span>
         )}

         <div className="col-lg-12 mt-4">
          <label htmlFor="" className="mb-2 fw-medium h5">
           Risk Levels
          </label>
          <div className="row gx-2 justify-context-center">
           {riskMandates
            .sort((a, b) => a.priority - b.priority)
            .map((mandate, index) => (
             <div className="col-lg-4 ">
              <InputOption
               key={index}
               className="mx-0"
               onChange={() => handleRiskMandateChange(mandate)}
               label={mandate.risk_mandates.name}
               id={mandate.risk_mandate_id}
               name="riskMandate"
               value={mandate.risk_mandate_id}
               checked={
                selectedRiskMandate
                 ? selectedRiskMandate.risk_mandate_id ===
                   mandate.risk_mandate_id
                 : false
               }
              />
             </div>
            ))}
          </div>
         </div>

         <div className="col-lg-12 mt-4">
          <label htmlFor="" className="mb-2 fw-medium h5">
           Allocated amount
          </label>
          <div className="input-group">
           <span className="input-group-text">
            <i className="ai-dollar"></i>
           </span>
           <input
            type="number"
            className="form-control"
            placeholder="Start Contribution"
            onChange={handleInputChange}
            name="starting_contribution_fee"
            value={potData.starting_contribution_fee || ""} // Assume pot is an object of type Pot
           />
           <span className="input-group-text">.00</span>
          </div>
          {error.starting_contribution_fee && (
           <span className="text-danger fw-light">
            <BsInfoCircleFill /> {error.starting_contribution_fee}
           </span>
          )}
         </div>

         <div className="col-lg-12 mt-4">
          <label htmlFor="" className="mb-2 fw-medium h5">
           Monthly Contribution
           <span className="fw-lighter">(Optional)</span>
          </label>
          <div className="input-group">
           <span className="input-group-text">
            <i className="ai-dollar"></i>
           </span>
           <input
            type="number"
            className="form-control"
            placeholder="Monthly Contribution"
            onChange={handleInputChange}
            name="monthly_contribution_fee"
            value={potData.monthly_contribution_fee || ""} // Assume pot is an object of type Pot
           />
           <span className="input-group-text">.00</span>
          </div>
          {error.monthly_contribution_fee && (
           <span className="text-danger fw-light">
            <BsInfoCircleFill /> {error.monthly_contribution_fee}
           </span>
          )}
         </div>

         <div className="mt-4 mt-lg-5">
          <div className="d-flex ">
           <Button
            onClick={handleSubmit}
            className={"btn btn-dark btn-lg w-lg-50"}
           >
            <BsFileText className="me-2 me-lg-3" size={30} /> Proceed & Review
           </Button>
          </div>
         </div>
        </div>
        <div className="col-lg-4    align-self-stretch ">
         <div className="card bg-lighter rounded-1 h-100">
          <div className="d-flex flex-column h-100 ">
           <div className="card-body px-0 ">
            <RiskLevelChart data={selectedRiskMandate && selectedRiskMandate} />
           </div>
           <h4>Risk Assessments</h4>
          </div>
         </div>
        </div>
       </form>
      </div>
     </div>
    </div>
   </section>
  </DashboardComponent>
 );
};

const mapStateToProps = (state: any) => ({
 potTypeData: state.potTypeData,
 potData: state.pots.potData,
 error: state.pots.error || {},
});

const mapDispatchToProps = {
 handlePotData: setPotData,
 handlePotError: setPotError,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreatePot);
