/** @format */

import { Router, Switch, Route } from "react-router-dom";
import PAGES_URL from "@routers/routes";
import history from "@redux/actions/history";
import UnAuthRoute from "@routers/UnAuthRoute";
import AuthRoute from "@routers/AuthRoute";
import {
 Home,
 ContactUs,
 Login,
 SignUp,
 ForgotPassword,
 ConfirmPassword,
 Wallet,
 Notifications,
 Help,
 New,
 MyAccount,
 Profile,
 Verification,
 NotificationSettings,
 ChangePassword,
 TryItOut,
 //  Inbox,
 //  Sent,
 //  Draft,
 //  CreateMessage,
 CreatePot,
 RiskLevel,
 Review,
 Details,
 About,
 Careers,
 Customers,
 Features,
 Terms,
 Policy,
 Security,
 HowItWorks,
 Billings,
 Messages,
 Deposit,
 Withdraw,
 FeaturesSingle,
 Team,
 ViewAllPot,
} from "@pages/index";
import PotSuccess from "@pages/dashboard/pot/PotSuccess";

function App() {
 return (
  <Router history={history}>
   <Switch>
    <Route exact path={PAGES_URL.OUR_TEAM} component={Team} />
    <Route exact path={PAGES_URL.ABOUT} component={About} />
    <Route exact path={PAGES_URL.CUSTOMERS} component={Customers} />
    <Route exact path={PAGES_URL.HOWITWORKS} component={HowItWorks} />
    <Route exact path={PAGES_URL.CAREERS} component={Careers} />
    <Route exact path={PAGES_URL.CONTACT_US} component={ContactUs} />
    <Route exact path={PAGES_URL.FEATURES} component={Features} />
    <Route path={PAGES_URL.TRY_IT_OUT} component={TryItOut} />
    <Route path={PAGES_URL.FEATURES_SINGLE} component={FeaturesSingle} />
    <Route exact path={PAGES_URL.TERMS} component={Terms} />{" "}
    <Route exact path={PAGES_URL.POLICY} component={Policy} />{" "}
    <Route exact path={PAGES_URL.SECURITY} component={Security} />
    <UnAuthRoute path={PAGES_URL.LOGIN} component={Login} />
    <UnAuthRoute path={PAGES_URL.SIGNUP} component={SignUp} />
    <UnAuthRoute path={PAGES_URL.FORGOT_PASSWORD} component={ForgotPassword} />
    <UnAuthRoute
     exact
     path={PAGES_URL.CONFIRM_PASSWORD}
     component={ConfirmPassword}
    />
    <Route exact path={PAGES_URL.HOME} component={Home} />
    <AuthRoute exact path={PAGES_URL.DASHBOARD_HOME} component={Wallet} />
    <AuthRoute exact path={PAGES_URL.NOTIFICATIONS} component={Notifications} />
    <AuthRoute exact path={PAGES_URL.HELP} component={Help} />
    <AuthRoute exact path={PAGES_URL.WALLET} component={Wallet} />
    <AuthRoute exact path={PAGES_URL.DEPOSIT} component={Deposit} />
    <AuthRoute exact path={PAGES_URL.WITHDRAW} component={Withdraw} />
    <AuthRoute exact path={PAGES_URL.NEW_INVESTMENT} component={New} />
    <AuthRoute exact path={PAGES_URL.CREATE_POT} component={CreatePot} />
    <AuthRoute exact path={PAGES_URL.VIEW_ALL_POT} component={ViewAllPot} />
    <AuthRoute exact path={PAGES_URL.RISK_LEVEL} component={RiskLevel} />
    <AuthRoute exact path={PAGES_URL.REVIEW} component={Review} />
    <AuthRoute exact path={PAGES_URL.POT_SUCCESS} component={PotSuccess} />
    <AuthRoute exact path={PAGES_URL.DETAILS} component={Details} />
    <AuthRoute exact path={PAGES_URL.ACCOUNT} component={MyAccount} />
    <AuthRoute exact path={PAGES_URL.VERIFICATION} component={Verification} />
    <AuthRoute exact path={PAGES_URL.PROFILE} component={Profile} />
    {/* <AuthRoute exact path={PAGES_URL.INBOX} component={Inbox} />
    <AuthRoute exact path={PAGES_URL.SENT} component={Sent} />
    <AuthRoute exact path={PAGES_URL.DRAFT} component={Draft} />
     */}
    <AuthRoute exact path={PAGES_URL.LIST_MESSAGES} component={Messages} />
    {/* <AuthRoute
     exact
     path={PAGES_URL.CREATE_MESSAGE}
     component={CreateMessage}
    /> */}
    <AuthRoute exact path={PAGES_URL.BILLINGS} component={Billings} />
    <AuthRoute
     exact
     path={PAGES_URL.NOTIFICATION_SETTINGS}
     component={NotificationSettings}
    />
    <AuthRoute
     exact
     path={PAGES_URL.PASSWORD_CHANGE}
     component={ChangePassword}
    />
   </Switch>
  </Router>
 );
}

export default App;
