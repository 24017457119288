/** @format */
import React, { useState } from "react";
import { InputPasswordField } from "@components/Inputs";
import PAGES_URL from "@routers/routes";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { updateUserPassword } from "@redux/actions/user";
import Button from "@components/Buttons";
import validator from "@helpers/validators";
import { UpdateUserPassword as User } from "@helpers/types/user.type";
import { hasValues } from "@helpers/String";

type Props = {
 update: any;
};

const UpdatePassword = ({ update }: Props) => {
 const [state, setState] = useState({} as User);
 const [error, setError] = useState({} as User);

 const handleInputChange = (e: any) => {
  setState((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
 };

 const handleOnSubmit = (e: any) => {
  e.preventDefault();
  const errors = validator.UpdateUserPasswordValidator(state);
  if (!hasValues(errors)) {
   setError(errors);
   return;
  }
  update(state);
 };

 return (
  <section className="card border-0 py-1 p-md-2 p-xl-3 p-xxl-4 mb-4">
   <div className="card-body">
    <div className="d-flex align-items-center pb-4 mt-sm-n1 mb-0 mb-lg-1 mb-xl-3">
     <i className="ai-lock-closed text-primary lead pe-1 me-2"></i>
     <h2 className="h4 mb-0">Password change</h2>
    </div>
    <div className="row align-items-center g-3 g-sm-4 pb-3">
     <div className="col-sm-6">
      <label className="form-label" htmlFor="current-pass">
       Current password
      </label>
      <InputPasswordField
       errorMessage={error.current_password}
       name="current_password"
       value={state.current_password}
       onChange={handleInputChange}
      />
     </div>
     <div className="col-sm-6">
      <Link
       className="d-inline-block fs-sm fw-semibold text-decoration-none mt-sm-4"
       to={PAGES_URL.FORGOT_PASSWORD}
      >
       Forgot password?
      </Link>
     </div>
     <div className="col-sm-6">
      <label className="form-label" htmlFor="new-pass">
       New password
      </label>
      <InputPasswordField
       errorMessage={error.password}
       name="password"
       value={state.password}
       onChange={handleInputChange}
      />
     </div>
     <div className="col-sm-6">
      <label className="form-label" htmlFor="confirm-pass">
       Confirm new password
      </label>
      <InputPasswordField
       errorMessage={error.confirm}
       name="confirm"
       value={state.confirm}
       onChange={handleInputChange}
      />
     </div>
    </div>
    <div className="alert alert-info d-flex my-3 my-sm-4">
     <i className="ai-circle-info fs-xl me-2"></i>
     <p className="mb-0">
      Password must be minimum 8 characters long - the more, the better.
     </p>
    </div>
    <div className="d-flex pt-3">
     <button className="btn btn-secondary" type="button">
      Cancel
     </button>
     <Button
      onClick={handleOnSubmit}
      className="btn btn-primary ms-3"
      type="button"
     >
      Save changes
     </Button>
    </div>
   </div>
  </section>
 );
};

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = (dispatch: any) => ({
 update: (data: any) => dispatch(updateUserPassword(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdatePassword);
