/** @format */

import React, { useState } from "react";
import { connect } from "react-redux";
import AuthComponent from "@components/AuthComponent";
import Button from "@components/Buttons";
// import { confirmPasswordAction } from "@redux/actions/authentication";
import { ConfirmPasswordValidator } from "@helpers/Validator";
import { hasValues } from "@helpers/String";
import { InputField } from "@components/Inputs";
import { BsInfoCircleFill } from "react-icons/bs";

type User = {
 password?: string;
 confirm?: string;
};

const ConfirmPassword = (props: any) => {
 const [state, setState] = useState({} as User);
 const [error, setError] = useState({} as User);

 const handleInputChange = (e: any) => {
  setState((prev) => ({ ...prev, [e.target.name]: e.target.value }));
 };

 const handleSubmit = (e: any) => {
  e.preventDefault();
  const checkForErrors = ConfirmPasswordValidator(state);
  if (!hasValues(checkForErrors)) {
   setError(checkForErrors);
   console.log(error);
   return;
  }
  props.confirmPassword(state);
  setError({});
 };

 return (
  <>
   <AuthComponent>
    <div className="container my-auto">
     <form action="" className="" onSubmit={handleSubmit}>
      <div className="row">
       <div className="col-lg-12">
        <h1 className="text-primary">Update Your Password</h1>
        <p>
         Please enter your new desired password to complete your password reset
         confirmation
        </p>
       </div>

       <div className="col-md-12">
        <label htmlFor="" className={`fw-light mb-2 small`}>
         Password
        </label>
        <InputField
         className={` ${error.password && "border-danger"}`}
         onChange={handleInputChange}
         name="password"
        />
        {error.password && (
         <p className=" my-1 small text-danger">
          <BsInfoCircleFill /> {error.password}
         </p>
        )}
       </div>
       <div className="col-md-12 mt-4 mt-lg-5">
        <label htmlFor="" className={`fw-light mb-2 small`}>
         Confirm Password
        </label>
        <InputField
         className={` ${error.confirm && "border-danger"}`}
         placeholder="Confirm Password"
         onChange={handleInputChange}
         name="confirm"
        />
        {error.confirm && (
         <p className="my-1 small text-danger">
          <BsInfoCircleFill /> {error.confirm}
         </p>
        )}
       </div>
       <div className="col-lg-12 mt-4">
        <Button className="btn-primary d-block w-100 mb-3  fw-bold">
         Proceed
        </Button>
       </div>
      </div>
     </form>
    </div>
   </AuthComponent>
  </>
 );
};

const mapStateToProps = (state: any) => ({
 loading: state.auth.loading,
});

const mapDispatchToProps = (dispatch: any) => ({
 //  confirmPassword: (data: any) => dispatch(confirmPasswordAction(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ConfirmPassword);
