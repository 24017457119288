/** @format */
import React from "react";
import AppComponent from "@components/AppComponent";
import { Hero, Persons, Teams } from "@assets/images";
import { Link } from "react-router-dom";
import PAGES_URL from "@routers/routes";
import Brands from "@assets/images/brands";
import {
 BsBinoculars,
 BsHeartPulse,
 BsLightbulb,
 BsLightbulbFill,
} from "react-icons/bs";

const Careers = () => {
 return (
  <AppComponent>
   <>
    <section className="position-relative pt-5">
     <div className="position-absolute top-0 start-0 w-100 bg-light bg-position-bottom-center bg-size-cover bg-repeat-0">
      <div className="d-lg-none" style={{ height: "960px" }}></div>
      <div className="d-none d-lg-block" style={{ height: "768px" }}></div>
     </div>

     <div className="container position-relative zindex-5 pt-5">
      <div className="row">
       <div className="col-lg-6">
        <nav
         className="pt-md-2 pt-lg-3 pb-4 pb-md-5 mb-xl-4"
         aria-label="breadcrumb"
        >
         <ol className="breadcrumb mb-0">
          <li className="breadcrumb-item">
           <Link to={PAGES_URL.HOME}>
            <i className="bx bx-home-alt fs-lg me-1"></i>Home
           </Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
           Careers
          </li>
         </ol>
        </nav>

        <h1 className="pb-2 pb-md-3 display-5">
         Help us build the impossible in Africa and beyond together.
        </h1>
        <p
         className="fs-xl pb-4 mb-1 mb-md-2 mb-lg-3"
         style={{ maxWidth: "526px" }}
        >
         We are a dedicated team of passionate product managers, full stack
         developers, UX/UI designers, QA engineers and marketing experts.
        </p>
        <img
         src={Brands.clutch_dark}
         className=" d-dark-mode-none"
         width="175"
         alt="Clutch"
        />
        <img
         src="assets/img/about/clutch-light.png"
         className="d-none d-dark-mode-block"
         width="175"
         alt="Clutch"
        />
       </div>

       <div className="col-lg-6 mt-xl-3 pt-5 pt-lg-4">
        <div className="row row-cols-2 gx-3 gx-lg-4">
         <div className="col pt-lg-5 mt-lg-1">
          <img
           src={Persons._01}
           className="d-block rounded-3 mb-3 mb-lg-4"
           alt="Image"
          />
          <img src={Persons._02} className="d-block rounded-3" alt="Image" />
         </div>
         <div className="col">
          <img
           src={Persons._03}
           className="d-block rounded-3 mb-3 mb-lg-4"
           alt="Image"
          />
          <img src={Persons._04} className="d-block rounded-3" alt="Image" />
         </div>
        </div>
       </div>
      </div>
     </div>
    </section>
    <section className="container mt-3 mb-5 pt-lg-5" id="benefits">
     <div className=" pt-3">
      <div className="d-flex  pt-4">
       <div className=" border-end-lg px-5">
        <div className="text-center">
         <BsLightbulb size={48} className="text-primary d-block mb-4 mx-auto" />
         {/* <img
          src="assets/img/landing/digital-agency/icons/idea.svg"
          width="48"
          alt="Bulb icon"
          className="d-block mb-4 mx-auto"
         /> */}
         <h4 className="mb-2 pb-1">Customer Focused</h4>
         <p className="mx-auto" style={{ maxWidth: "336px" }}>
          We relentlessly build our products around customers expectations
         </p>
        </div>
       </div>

       <div className=" border-end-lg px-5">
        <div className="text-center">
         <BsBinoculars
          size={48}
          className="text-primary d-block mb-4 mx-auto"
         />

         <h4 className="mb-2 pb-1">Vision</h4>
         <p className="mx-auto" style={{ maxWidth: "336px" }}>
          We are constantly on a journey aligned with where you want to be
         </p>
        </div>
       </div>

       <div className=" px-5">
        <div className="text-center">
         <BsHeartPulse
          size={48}
          className="text-primary d-block mb-4 mx-auto"
         />
         <h4 className="mb-2 pb-1">Great Ecosystem</h4>
         <p className="mx-auto" style={{ maxWidth: "336px" }}>
          At Sanga, we are family-focused environment with similar goals.
         </p>
        </div>
       </div>
      </div>
     </div>
    </section>
    <section className="container text-center pb-5 mt-n2 mt-md-0 mb-md-2 mb-lg-4">
     <div className="">
      <div className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-center zindex-5">
       <a
        href="https://www.youtube.com/watch?v=zPo5ZaH6sW8"
        className="btn btn-video btn-icon btn-xl stretched-link bg-white"
        data-bs-toggle="video"
       >
        <i className="bx bx-play"></i>
       </a>
      </div>
      <span className="position-absolute top-0 start-0 w-100 h-100  opacity-35"></span>
      <img src={Hero._10} className="d-block w-100" alt="Cover image" />
     </div>
    </section>
    <section className="container py-5 my-md-2 my-lg-3 my-xl-4 my-xxl-5">
     <div className="row justify-content-between  py-1 py-sm-3 py-md-4 my-lg-2">
      <div className="col-lg-5 ">
       <img src={Hero._13} alt="" />
      </div>
      <div className="col-lg-6 col-xl-7 col-xxl-6">
       <div className="h1 text-center text-lg-start pb-md-2 pb-xl-0 mb-4 mb-xl-5">
        A team built on self-drive, ownership and trust
       </div>
       <p className="fs-xl">
        We are building the impossible. We value a growth mindset over a fixed
        mindset, and encourage each other to boldly take on new approaches to
        solving problems for our customers.
       </p>
       <p className="fs-xl">
        We empower you with all the context and tools you need to get things
        done, and we trust you'll take it from there leaving no stone unturned
        in the process.
       </p>
       <div className=" pt-3 mt-3 mt-sm-4">
        <Link className="btn btn-lg btn-primary" to={PAGES_URL.ABOUT}>
         Learn More
        </Link>
       </div>
      </div>
     </div>
    </section>
    <section className="container py-5 my-md-3 my-lg-5">
     <h2 className="h1  pt-1">Our Leadership</h2>
     <p className="fs-xl mb-3 pb-3" style={{ width: "636px" }}>
      At Senga we work smart and play hard. We provide a flexible and conducive
      work environment for individuals to deliver their best and reach their
      maximum potential.
     </p>
     <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4">
      <div className="col">
       <div className="card card-hover border-0 bg-transparent">
        <div className="position-relative">
         <img src={Teams._04} className="rounded-3" alt="Yande" />
         <div className="card-img-overlay d-flex flex-column align-items-center justify-content-center rounded-3">
          <span className="position-absolute top-0 start-0 w-100 h-100 bg-dark opacity-25 rounded-3"></span>
         </div>
        </div>
        <div className="card-body text-center p-3">
         <h3 className="fs-lg fw-semibold pt-1 mb-2">Yande</h3>
         <p className="fs-sm mb-0">Co-Founder &amp; CEO</p>
        </div>
       </div>
      </div>

      <div className="col">
       <div className="card card-hover border-0 bg-transparent">
        <div className="position-relative">
         <img src={Teams._08} className="rounded-3" alt="Tiza" />
         <div className="card-img-overlay d-flex flex-column align-items-center justify-content-center rounded-3">
          <span className="position-absolute top-0 start-0 w-100 h-100 bg-dark opacity-25 rounded-3"></span>
         </div>
        </div>
        <div className="card-body text-center p-3">
         <h3 className="fs-lg fw-semibold pt-1 mb-2">Tiza</h3>
         <p className="fs-sm mb-0">Co-Founder</p>
        </div>
       </div>
      </div>

      <div className="col">
       <div className="card card-hover border-0 bg-transparent">
        <div className="position-relative">
         <img src={Teams._03} className="rounded-3" alt="Thomas" />
         <div className="card-img-overlay d-flex flex-column align-items-center justify-content-center rounded-3">
          <span className="position-absolute top-0 start-0 w-100 h-100 bg-dark opacity-25 rounded-3"></span>
         </div>
        </div>
        <div className="card-body text-center p-3">
         <h3 className="fs-lg fw-semibold pt-1 mb-2">Thomas</h3>
         <p className="fs-sm mb-0">Engineering Manager</p>
        </div>
       </div>
      </div>

      <div className="col">
       <div className="card card-hover border-0 bg-transparent">
        <div className="position-relative">
         <img src={Teams._02} className="rounded-3" alt="Ahmed" />
         <div className="card-img-overlay d-flex flex-column align-items-center justify-content-center rounded-3">
          <span className="position-absolute top-0 start-0 w-100 h-100 bg-dark opacity-25 rounded-3"></span>
         </div>
        </div>
        <div className="card-body text-center p-3">
         <h3 className="fs-lg fw-semibold pt-1 mb-2">Ahmed</h3>
         <p className="fs-sm mb-0">Software Engineer</p>
        </div>
       </div>
      </div>
     </div>
    </section>

    <section className="container mt-n2">
     <div className="card border-0 bg-primary">
      <div className="card-body p-md-5 p-4 bg-size-cover">
       <div className="py-md-5 py-4 text-center">
        <h3 className="h4 fw-normal text-light opacity-75">
         Come join our fast growing team
        </h3>
        <a href="mailto:hr@senga.com" className="display-6 text-light">
         hr@senga.com
        </a>
        <div className="pt-md-5 pt-4 pb-md-2">
         <a href="contacts-v1.html" className="btn btn-lg btn-light">
          Open Positionns
         </a>
        </div>
       </div>
      </div>
     </div>
    </section>
   </>
  </AppComponent>
 );
};

export default Careers;
