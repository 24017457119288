/** @format */

import { Teams } from "@assets/images";
import React from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { BsSend } from "react-icons/bs";

const MessageBody = () => {
 const messages = [
  {
   name: "User",
   image: Teams._03,
   timestamps: "1 days ago at 1:54",
   body: `Howdy \n\n I am having issues with creating a pot and I just funded my wallet \n\n I keep getting an account validation null error and I it has been 2 days now, same error; please i need this resolved asap.\n\n Warm regards`,
  },
  {
   name: "Jeffery Cummings",
   image: Teams._02,
   timestamps: "2 days ago at 9:20",
   body: `Hi \n\n We just received your ticket and we will be attending to the issue as soon as possible \n\n while you wait, you could chechout our help page on a breakdown on how to create a new pot and do let's us know if you have resolved the issue\n\n Thanks`,
  },
 ];
 return (
  <div className="vh-100">
   <div
    className="  "
    style={{
     height: "45%",
     overflowY: "scroll",
    }}
   >
    {messages &&
     messages.map((message, idx) => (
      <>
       <div key={idx} className="d-flex align-items-start pb-1 mb-3">
        <img
         src={message.image}
         className="rounded-circle"
         width="48"
         alt="Comment author"
        />
        <div className="ps-3">
         <h6 className="mb-0">{message.name}</h6>
         <span className="fs-sm text-body-secondary">{message.timestamps}</span>
         <p
          className="mt-5 h5 fw-normal w-lg-75"
          style={{ whiteSpace: "pre-line" }}
         >
          {message.body}
         </p>
        </div>
       </div>
       <hr className="mb-4" />
      </>
     ))}
   </div>
   <Editor
    toolbarClassName="toolbarClassName"
    wrapperClassName="wrapperClassName"
    editorClassName="editorClassName"
   />
   <div className="d-flex">
    <button className="btn btn-primary ms-auto w-lg-25">
     <BsSend size={20} />
    </button>
   </div>
  </div>
 );
};

export default MessageBody;
