/** @format */

import React, { ReactNode } from "react";
import { AppstoreLight, Hand, Logo, PlaystoreLight } from "@assets/images";
import { Link } from "react-router-dom";
import PAGES_URL from "../routers/routes";

interface Props {
 children: ReactNode;
}

const AppComponent = ({ children }: Props) => {
 return (
  <>
   <main className="page-wrapper">
    <header className="navbar navbar-expand-lg fixed-top bg-light">
     <div className="container d-flex  align-items-center">
      <Link className=" pe-sm-3" to={PAGES_URL.HOME}>
       <img width={100} src={Logo} alt="" />
      </Link>
      <Link
       className="btn btn-light  fs-sm order-lg-3 d-none d-sm-inline-flex me-4"
       to={PAGES_URL.LOGIN}
       target="_blank"
       rel="noopener"
      >
       Sign in
      </Link>
      <Link
       className="btn btn-primary  order-lg-3 d-none d-sm-inline-flex"
       to={PAGES_URL.SIGNUP}
       target="_blank"
       rel="noopener"
      >
       Create free account
      </Link>
      <button
       className="navbar-toggler ms-sm-3"
       type="button"
       data-bs-toggle="collapse"
       data-bs-target="#navbarNav"
      >
       <span className="navbar-toggler-icon"></span>
      </button>
      <nav className="collapse navbar-collapse" id="navbarNav">
       <ul
        className="navbar-nav navbar-nav-scroll me-auto"
        // style={{"--ar-scroll-height: 520px"}}
       >
        <li className="nav-item dropdown">
         <a
          className="nav-link dropdown-toggle active"
          href="#"
          data-bs-toggle="dropdown"
          aria-expanded="false"
         >
          Company
         </a>
         <div className="dropdown-menu overflow-hidden p-0">
          <div className="d-lg-flex">
           <div className="mega-dropdown-column pt-1 pt-lg-3 pb-lg-4">
            <ul className="list-unstyled mb-0">
             <li>
              <Link className="dropdown-item" to={PAGES_URL.ABOUT}>
               About
              </Link>
              <span
               className="mega-dropdown-column position-absolute top-0 end-0 h-100 bg-size-cover bg-repeat-0 zindex-2 opacity-0"
               style={{
                backgroundImage: "url(assets/img/megamenu/creative-agency.jpg)",
               }}
              ></span>
             </li>
             <li>
              <Link className="dropdown-item" to={PAGES_URL.CUSTOMERS}>
               Customer
              </Link>
              <span
               className="mega-dropdown-column position-absolute top-0 end-0 h-100 bg-size-cover bg-repeat-0 zindex-2 opacity-0"
               style={{
                backgroundImage: "url()",
               }}
              ></span>
             </li>
             <li>
              <Link className="dropdown-item" to={PAGES_URL.CAREERS}>
               Careers
              </Link>
              <span
               className="mega-dropdown-column position-absolute top-0 end-0 h-100 bg-size-cover bg-repeat-0 zindex-2 opacity-0"
               style={{
                backgroundImage: "url(assets/img/megamenu/conference.jpg)",
               }}
              ></span>
             </li>
             <li>
              <Link className="dropdown-item" to={PAGES_URL.CONTACT_US}>
               Contact Us
              </Link>
              <span
               className="mega-dropdown-column position-absolute top-0 end-0 h-100 bg-size-cover bg-repeat-0 zindex-2 opacity-0"
               //    style={{
               //     backgroundImage: "url(assets/img/megamenu/conference.jpg)",
               //    }}
              ></span>
             </li>

             <li>
              <Link className="dropdown-item" to={PAGES_URL.OUR_TEAM}>
               Our Team
              </Link>
              <span
               className="mega-dropdown-column position-absolute top-0 end-0 h-100 bg-size-cover bg-repeat-0 zindex-2 opacity-0"
               //    style={{
               //     backgroundImage: "url(assets/img/megamenu/conference.jpg)",
               //    }}
              ></span>
             </li>
            </ul>
           </div>
           <div className="mega-dropdown-column position-relative border-start zindex-3"></div>
          </div>
         </div>
        </li>
        <li className="nav-item dropdown">
         <a
          className="nav-link dropdown-toggle  active"
          href="#"
          data-bs-toggle="dropdown"
          aria-expanded="false"
         >
          Product
         </a>
         <div className="dropdown-menu overflow-hidden p-0">
          <div className="d-lg-flex">
           <div className="mega-dropdown-column pt-1 pt-lg-3 pb-lg-4">
            <ul className="list-unstyled mb-0">
             <li>
              <Link className="dropdown-item" to={PAGES_URL.HOWITWORKS}>
               How it works
              </Link>
              <span
               className="mega-dropdown-column position-absolute top-0 end-0 h-100 bg-size-cover bg-repeat-0 zindex-2 opacity-0"
               style={{
                backgroundImage: `url(${Hand})`,
               }}
              ></span>
             </li>
             <li>
              <a className="dropdown-item" href="landing-conference.html">
               FAQs
              </a>
             </li>
             <li>
              <a
               className="dropdown-item"
               target="_blank"
               href="https://calendly.com"
              >
               Request a demo
              </a>
             </li>
            </ul>
           </div>
           <div className="mega-dropdown-column position-relative border-start zindex-3"></div>
          </div>
         </div>
        </li>
        <li className="nav-item">
         <Link
          className="nav-link text-primary"
          to={PAGES_URL.FEATURES}
          aria-expanded="false"
         >
          Features
         </Link>
        </li>
        {/* <li className="nav-item dropdown">
         <a
          className="nav-link dropdown-toggle active"
          href="#"
          data-bs-toggle="dropdown"
          aria-expanded="false"
         >
          Legal
         </a>
         <div className="dropdown-menu overflow-hidden p-0">
          <div className="d-lg-flex">
           <div className="mega-dropdown-column pt-1 pt-lg-3 pb-lg-4">
            <ul className="list-unstyled mb-0">
             <li>
              <Link className="dropdown-item" to={PAGES_URL.ABOUT}>
               Help Center
              </Link>
             </li>
             <li>
              <Link className="dropdown-item" to={PAGES_URL.TERMS}>
               Terms of service
              </Link>
             </li>
             <li>
              <Link className="dropdown-item" to={PAGES_URL.POLICY}>
               Privacy Policy
              </Link>
             </li>
             <li>
              <Link className="dropdown-item" to={PAGES_URL.SECURITY}>
               Security
              </Link>
             </li>
            </ul>
           </div>
          </div>
         </div>
        </li> */}
       </ul>
       <div className="d-sm-none p-3 mt-n3">
        <a
         className="btn btn-primary w-100  mb-1"
         href="https://themes.getbootstrap.com/product/around-multipurpose-template-ui-kit/"
         target="_blank"
         rel="noopener"
        >
         <i className="ai-cart fs-xl me-2 ms-n1"></i>Create free account
        </a>
       </div>
      </nav>
     </div>
    </header>
    {children}
    <footer className="footer bg- py-5">
     <div className="container pt-md-2 pt-lg-3 pt-xl-4">
      <div className="row pb-5 pt-sm-2 mb-lg-2">
       <div className="col-md-4 col-lg-3 pb-2 pb-md-0 mb-4 mb-md-0">
        <a className="navbar-brand py-0 mb-3 mb-md-4" href={PAGES_URL.HOME}>
         <img width={100} src={Logo} alt="" />
        </a>
        <p className="fs-sm pb-2 pb-md-3 mb-3">
         Senga is a purposeful Investing platform. we aim at getting people
         richer through investments in the easiest way possible to them. Grow
         wealth through us
        </p>
        <div className="d-flex">
         <a
          className="btn btn-icon btn-sm btn-dark btn-facebook rounded-circle me-3"
          href="#"
         >
          <i className="ai-facebook"></i>
         </a>
         <a
          className="btn btn-icon btn-sm btn-dark btn-instagram rounded-circle me-3"
          href="#"
         >
          <i className="ai-instagram"></i>
         </a>
         <a
          className="btn btn-icon btn-sm btn-dark btn-linkedin rounded-circle"
          href="#"
         >
          <i className="ai-linkedin"></i>
         </a>
        </div>
       </div>
       <div className="col-md-8 col-lg-7 col-xl-6 offset-lg-2 offset-xl-3">
        <div className="row row-cols-1 row-cols-sm-3">
         <div className="col mb-4 mb-md-0">
          <h4 className="h6 fw-bold pb-lg-1">Company</h4>
          <ul className="nav flex-column">
           <li>
            <a className="nav-link fw-normal py-1 px-0" href="#">
             About
            </a>
           </li>
           <li>
            <a className="nav-link fw-normal py-1 px-0" href="#">
             Customers
            </a>
           </li>
           <li>
            <a className="nav-link fw-normal py-1 px-0" href="#">
             Careers
            </a>
           </li>
           <li>
            <a className="nav-link fw-normal py-1 px-0" href="#">
             Get in touch
            </a>
           </li>
          </ul>
         </div>
         <div className="col mb-4 mb-md-0">
          <h4 className="h6 fw-bold pb-lg-1">Support</h4>
          <ul className="nav flex-column">
           <li>
            <a className="nav-link fw-normal py-1 px-0" href="#">
             Help center
            </a>
           </li>
           <li>
            <a className="nav-link fw-normal py-1 px-0" href="#">
             Terms of service
            </a>
           </li>
           <li>
            <a className="nav-link fw-normal py-1 px-0" href="#">
             Legal
            </a>
           </li>
           <li>
            <a className="nav-link fw-normal py-1 px-0" href="#">
             Privacy policy
            </a>
           </li>
          </ul>
         </div>
         <div className="col">
          <h4 className="h6 fw-bold pb-2 mb-0 mb-lg-1">Install App</h4>
          <a className="btn btn-dark px-3 py-2 mt-3 me-3 me-md-0" href="#">
           <img
            className="mx-1 d-dark-mode-none"
            src={AppstoreLight}
            width="120"
            alt="App Store"
           />
          </a>
          <a className="btn btn-dark px-3 py-2 mt-3 me-3 me-md-0" href="#">
           <img
            className="mx-1 d-dark-mode-none"
            src={PlaystoreLight}
            width="119"
            alt="Google Play"
           />
          </a>
         </div>
        </div>
       </div>
      </div>
      <p className="fs-sm mb-0">
       <span className="opacity-70">&copy; All rights reserved. </span>
       <a
        className="nav-link d-inline fw-normal p-0"
        href={PAGES_URL.HOME}
        target="_blank"
        rel="noopener"
       >
        Senga 2023
       </a>
      </p>
     </div>
    </footer>
   </main>
  </>
 );
};

export default AppComponent;
