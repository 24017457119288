/** @format */
import React from "react";
import AppComponent from "@components/AppComponent";
import { Banner, Hero } from "@assets/images";
import { Link } from "react-router-dom";
import PAGES_URL from "@routers/routes";

const About = () => {
 return (
  <AppComponent>
   <section className="bg-primary position-relative py-5">
    <div
     className="d-none d-dark-mode-block position-absolute top-0 start-0 w-100 h-100"
     style={{ backgroundColor: "rgba(255,255,255, .02)" }}
    ></div>
    <div className="container dark-mode position-relative zindex-2 py-5 mb-4 mb-sm-5">
     <div className="row pb-5 mb-2 mb-sm-0 mb-lg-3">
      <div className="col-lg-10 col-xl-9">
       <nav aria-label="breadcrumb ">
        <ol className="pt-lg-3 pb-lg-4 pb-2 breadcrumb">
         <li className="breadcrumb-item">
          <Link to={PAGES_URL.HOME}>Home</Link>
         </li>
         <li className="breadcrumb-item " aria-current="page">
          About us
         </li>
        </ol>
       </nav>
       <h1 className="display-2 pb-2 pb-sm-3">Our Story</h1>
      </div>
     </div>
    </div>
   </section>

   <section
    className="container position-relative zindex-3"
    style={{ marginTop: "-135px" }}
   >
    <div className="rounded-5 overflow-hidden">
     <div className="jarallax ratio ratio-16x9" data-jarallax data-speed="0.6">
      <div
       className="jarallax-img"
       style={{
        backgroundImage: `url(${Banner.About})`,
       }}
      ></div>
     </div>
    </div>
    <div className="row pt-5 mt-n2 mt-sm-0 mt-md-2 mt-lg-4 mt-xl-5">
     <div className="col-md-6 col-lg-5">
      <div className="fs-sm text-uppercase mb-3">What we do</div>
      <h2 className="display-6">
       We are a team who create marketing strategies
      </h2>
      <p className="fs-xl">
       After working 6 years within financial services and gaining invaluable
       experience within <strong>wealth management</strong>, it became clear
       that across the continent there is a lack of access to high quality easy
       to use investment solutions for everyday people. Frustrated with the lack
       of choice friends and family living in Africa have to help propel and
       secure their futures through savings,<strong>Senga was born.</strong>
      </p>
      <p className="fs-xl">
       Bringing together IT and finance expertise within the team, our vision is
       to create a transparent solution that allows Africans to easily invest to
       meet their <strong>lifetime goals</strong> without needing a large amount
       of money to begin investing. We have been making it easy for Africans to
       invest by offering ready-made portfolios for investors to choose from and
       let our experts do the heavy lifting. Once you choose a portfolio, you
       can leave it to our experts to manage your portfolio within the selected
       strategy. We will monitor your portfolio and make changes throughout the
       year
      </p>
     </div>
     <div className="col-md-6 col-xl-5 offset-lg-1 offset-xl-2 pt-1 pt-sm-2 pt-md-5">
      <p className="fs-xl">
       At Senga, we're on a mission to transform the way people think about
       wealth and investing. Our platform is designed to make investing
       accessible, convenient, and effective, helping individuals like you
       achieve your financial goals and grow your wealth in the easiest way
       possible.
      </p>
      <p className="fs-xl">
       We believe that everyone should have the opportunity to participate in
       the financial markets and benefit from the potential returns they offer.
       Our team is dedicated to breaking down barriers and demystifying the
       world of investing. We're committed to providing you with the tools,
       knowledge, and resources you need to make informed decisions about your
       finances.
      </p>
      <p className="fs-xl">
       whilst investing with a long-term view. Our portfolios are invested
       across global markets, giving Africans easy access to global markets
       (such as US, Asia & Europe) and eventually aim to also include home
       markets to invest promising African companies. We look forward to helping
       you achieve your future goals and strive to improve our service to.
      </p>
     </div>
    </div>
   </section>

   <section className="pt-5 mt-md-2 mt-xl-4 mt-xxl-5">
    <div className="container pt-2 pt-sm-4 pt-lg-5 mt-xxl-2">
     <div className="fs-sm text-uppercase mb-3">Our Mission</div>
     <h2 className="display-6 pb-3 mb-lg-4">Professional approach </h2>
     <div className="">
      <div className="row justify-content-around">
       <div className="col-lg-4 h-auto">
        <div className="card border-0 bg-secondary rounded-5 h-100">
         <div className="card-body">
          <svg
           className="d-block mt-1 mt-sm-0 mb-4"
           width="40"
           height="40"
           viewBox="0 0 40 40"
           xmlns="http://www.w3.org/2000/svg"
          >
           <g className="text-info">
            <path
             d="M20.0004 36.0226L10.982 21.3535C9.42594 23.3156 8.49609 25.7968 8.49609 28.4955C8.49609 34.8492 13.6467 39.9998 20.0004 39.9998C26.3541 39.9998 31.5047 34.8492 31.5047 28.4955C31.5047 25.7969 30.5749 23.3156 29.0188 21.3535L20.0004 36.0226Z"
             fill="currentColor"
            ></path>
           </g>
           <g className="text-primary">
            <path
             d="M39.3962 0H0.605469L20.0009 31.5477L39.3962 0ZM25.7601 7.62359L20.0009 16.9914L14.2416 7.62359H25.7601Z"
             fill="currentColor"
            ></path>
           </g>
          </svg>
          <h3 className="h4">Simplicity and Accessibility</h3>
          <p className="mb-0">
           We understand that investing can be intimidating, especially for
           those who are new to the concept.
          </p>
         </div>
        </div>
       </div>
       <div className="col-lg-4 h-auto">
        <div className="card border-0 bg-secondary rounded-5 h-100">
         <div className="card-body">
          <svg
           className="d-block mt-1 mt-sm-0 mb-4"
           width="40"
           height="40"
           viewBox="0 0 40 40"
           xmlns="http://www.w3.org/2000/svg"
          >
           <g className="text-info">
            <path
             d="M26.307 23.1116C26.307 28.3136 22.09 32.5307 16.888 32.5307C11.6859 32.5307 7.46891 28.3136 7.46891 23.1116C7.46891 17.9096 11.6859 13.6925 16.888 13.6925C17.1467 13.6925 17.4028 13.7038 17.6562 13.7243V6.24121C17.4016 6.22973 17.1455 6.22363 16.888 6.22363C7.56102 6.22363 0 13.7846 0 23.1116C0 32.4386 7.56102 39.9996 16.888 39.9996C26.2149 39.9996 33.7759 32.4386 33.7759 23.1116C33.7759 22.8541 33.7698 22.598 33.7584 22.3433H26.2753C26.2958 22.5968 26.307 22.8529 26.307 23.1116Z"
             fill="currentColor"
            ></path>
           </g>
           <g className="text-primary">
            <path
             d="M40 20C40 8.9543 31.0457 0 20 0V20H40Z"
             fill="currentColor"
            ></path>
           </g>
          </svg>
          <h3 className="h4">Diversification Made Easy</h3>
          <p className="mb-0">
           With Senga, you'll have access to a range of investment options that
           can help you build a diversified portfolio tailored to your financial
           objectives.
          </p>
         </div>
        </div>
       </div>
       <div className="col-lg-4 h-auto">
        <div className="card border-0 bg-secondary rounded-5 h-100">
         <div className="card-body">
          <svg
           className="d-block mt-1 mt-sm-0 mb-4"
           width="40"
           height="40"
           viewBox="0 0 40 40"
           xmlns="http://www.w3.org/2000/svg"
          >
           <g className="text-primary">
            <path
             d="M25.2791 12.7502C28.1954 9.83389 28.1954 5.10556 25.2791 2.18921C22.3627 -0.727136 17.6344 -0.727137 14.718 2.18921C11.8017 5.10556 11.8017 9.83389 14.718 12.7502C17.6344 15.6666 22.3627 15.6666 25.2791 12.7502Z"
             fill="currentColor"
            ></path>
           </g>
           <g className="text-info">
            <path
             d="M14.6859 29.3056C15.6559 27.0123 16.9202 24.8838 18.4577 22.9467C13.8666 17.9802 7.29664 14.8701 0 14.8701V40.0004H12.5259C12.5259 36.2925 13.2527 32.6942 14.6859 29.3056Z"
             fill="currentColor"
            ></path>
           </g>
           <g className="text-primary">
            <path
             d="M40.0014 40.0004V14.8701C26.1223 14.8701 14.8711 26.1214 14.8711 40.0004H40.0014Z"
             fill="currentColor"
            ></path>
           </g>
          </svg>
          <h3 className="h4">Education at Your Fingertips:</h3>
          <p className="mb-0">
           We believe that knowledge is power. Our platform is not just about
           investing; it's about learning and understanding the principles
           behind investment decisions.
          </p>
         </div>
        </div>
       </div>
      </div>
      <div className="row mt-4">
       <div className="col-lg-4 h-auto">
        <div className="card border-0 bg-secondary rounded-5 h-100">
         <div className="card-body">
          <svg
           className="d-block mt-1 mt-sm-0 mb-4"
           width="40"
           height="40"
           viewBox="0 0 40 40"
           xmlns="http://www.w3.org/2000/svg"
          >
           <g className="text-info">
            <path
             d="M34.9811 16.2655C34.3635 26.3194 26.3194 34.3634 16.2656 34.981V40H40V16.2655H34.9811Z"
             fill="currentColor"
            ></path>
           </g>
           <g className="text-info">
            <path
             d="M15.0195 30.0413C23.3152 30.0413 30.0403 23.3163 30.0403 15.0205H15.0195V30.0413Z"
             fill="currentColor"
            ></path>
           </g>
           <g className="text-primary">
            <path
             d="M29.1953 10.0415C27.141 4.19328 21.571 0 15.0208 0C6.725 0 0 6.725 0 15.0208C0 21.571 4.19328 27.141 10.0415 29.1953V10.0415H29.1953Z"
             fill="currentColor"
            ></path>
           </g>
          </svg>
          <h3 className="h4">Personalized for You</h3>
          <p className="mb-0">
           Your financial journey is unique, and so are your goals. Our platform
           allows you to customize your investment strategy based on your risk
           tolerance, time horizon, and aspirations.
          </p>
         </div>
        </div>
       </div>
       <div className="col-lg-4 h-auto">
        <div className="card border-0 bg-secondary rounded-5 h-100">
         <div className="card-body">
          <svg
           className="d-block mt-1 mt-sm-0 mb-4"
           width="40"
           height="40"
           viewBox="0 0 40 40"
           xmlns="http://www.w3.org/2000/svg"
          >
           <g className="text-primary">
            <path
             d="M19.9999 0C11.1313 0 3.61211 5.77313 0.992188 13.7659H39.0077C36.3877 5.77313 28.8686 0 19.9999 0Z"
             fill="currentColor"
            ></path>
           </g>
           <g className="text-info">
            <path
             d="M7.29328 16.1094H0.379453C0.131328 17.368 0 18.6684 0 19.9998C0 26.9291 3.52437 33.0348 8.87797 36.6237L18.3427 27.1589L7.29328 16.1094Z"
             fill="currentColor"
            ></path>
           </g>
           <g className="text-primary">
            <path
             d="M10.9688 37.848C13.6819 39.2238 16.7505 39.9998 20.0007 39.9998C31.0464 39.9998 40.0007 31.0455 40.0007 19.9998C40.0007 18.6684 39.8694 17.3679 39.6213 16.1094H32.7074L10.9688 37.848Z"
             fill="currentColor"
            ></path>
           </g>
          </svg>
          <h3 className="h4">Automated Efficiency:</h3>
          <p className="mb-0">
           We know that life can get busy. That's why we offer automated
           investment features that enable you to set up recurring investments
           and let the power of compounding work for you over time.
          </p>
         </div>
        </div>
       </div>
       <div className="col-lg-4 h-auto">
        <div className="card border-0 bg-secondary rounded-5 h-100">
         <div className="card-body">
          <svg
           className="d-block mt-1 mt-sm-0 mb-4"
           width="40"
           height="40"
           viewBox="0 0 40 40"
           xmlns="http://www.w3.org/2000/svg"
          >
           <g className="text-info">
            <path
             d="M20.0004 36.0226L10.982 21.3535C9.42594 23.3156 8.49609 25.7968 8.49609 28.4955C8.49609 34.8492 13.6467 39.9998 20.0004 39.9998C26.3541 39.9998 31.5047 34.8492 31.5047 28.4955C31.5047 25.7969 30.5749 23.3156 29.0188 21.3535L20.0004 36.0226Z"
             fill="currentColor"
            ></path>
           </g>
           <g className="text-primary">
            <path
             d="M39.3962 0H0.605469L20.0009 31.5477L39.3962 0ZM25.7601 7.62359L20.0009 16.9914L14.2416 7.62359H25.7601Z"
             fill="currentColor"
            ></path>
           </g>
          </svg>
          <h3 className="h4">Transparency & Trust</h3>
          <p className="mb-0">
           Transparency is at the heart of what we do. We're committed to
           providing clear information about our investment options, fees, and
           the potential risks involved. Your trust is paramount to us.
          </p>
         </div>
        </div>
       </div>
      </div>
      <div className="swiper-pagination position-relative bottom-0 mt-2 pt-4 d-lg-none"></div>
     </div>
    </div>
   </section>

   <section className="bg- py-5 mt-4">
    <div className="container py-md-2 py-lg-4 my-xl-2 my-xxl-3">
     <div
      className="row align-items-center py-3 py-sm-4 py-lg-5 mb-3 my-sm-2 my-md-3 my-lg-0 my-xl-2 my-xxl-4"
      data-aos="fade-up"
      data-aos-duration="600"
      data-aos-offset="280"
      data-disable-parallax-down="md"
     >
      <div className="col-md-6 col-xxl-6 order-md-2 pb-1 pb-sm-2 pb-md-0 mb-4 mb-md-0">
       <div className="position-relative">
        <img className="d-block rounded-1" src={Hero._10} alt="Image" />
        <div className="d-none d-lg-block position-absolute top-0 end-0 translate-middle-y w-auto pe-1 me-4">
         <div data-aos="fade-up" data-aos-duration="950" data-aos-offset="280">
          <svg
           className="text-info"
           width="107"
           height="107"
           viewBox="0 0 107 107"
           fill="currentColor"
           xmlns="http://www.w3.org/2000/svg"
          >
           <path d="M106.192 53.0468C106.225 63.5385 103.146 73.8042 97.3443 82.5459C91.5426 91.2875 83.279 98.1125 73.5985 102.158C63.918 106.203 53.2553 107.287 42.9589 105.272C32.6624 103.257 23.1946 98.2343 15.7528 90.8387C8.31094 83.4431 3.22928 74.0068 1.15039 63.7231C-0.92849 53.4394 0.0887821 42.7702 4.07357 33.0647C8.05836 23.3591 14.8317 15.0531 23.537 9.19711C32.2424 3.34107 42.4887 0.197979 52.9804 0.16529L53.1457 53.2121L106.192 53.0468Z"></path>
          </svg>
         </div>
        </div>
       </div>
      </div>
      <div
       className="col-md-6 col-lg-6 col-xl-6 order-md-1"
       data-aos="fade-up"
       data-aos-duration="800"
       data-aos-offset="180"
       data-disable-parallax-down="md"
      >
       <div className="pe-md-4 pe-lg-0">
        <h2 className="h1 pb-sm-2 pb-lg-3">Let's grow finances together</h2>
        <p className="fs-xl pb-3 mb-1 mb-sm-2 mb-md-3 mb-lg-4">
         With Senga, you're not just investing; you're embarking on a journey to
         financial growth and empowerment. Our platform is more than just
         numbers; it's about the confidence that comes from understanding your
         finances and making decisions that align with your aspirations.
        </p>
        <a
         className="btn btn-primary d-inline-flex align-items-center fw-semibold text-decoration-none"
         href="#"
        >
         Create an account
        </a>
       </div>
      </div>
     </div>

     <div
      className="row align-items-center py-3 py-sm-4 py-lg-5 my-sm-2 my-md-3 my-lg-0 my-xl-2 my-xxl-4"
      data-aos="fade-up"
      data-aos-duration="600"
      data-aos-offset="280"
      data-disable-parallax-down="md"
     >
      <div className="col-md-6 col-xxl-6  order-md-1 pb-1 pb-sm-2 pb-md-0 mb-4 mb-md-0">
       <div className="position-relative">
        <img className="d-block rounded-1" src={Hero._11} alt="Image" />
        <div className="d-none d-lg-block position-absolute top-0 start-50 translate-middle w-auto ms-2">
         <div data-aos="fade-up" data-aos-duration="950" data-aos-offset="280">
          <svg
           className="text-danger"
           width="108"
           height="111"
           viewBox="0 0 108 111"
           fill="currentColor"
           xmlns="http://www.w3.org/2000/svg"
          >
           <path d="M68.9244 1.23484C69.1072 0.713703 69.6783 0.438641 70.1961 0.630706C78.5792 3.74012 86.1086 8.79818 92.1601 15.3932C98.4371 22.2339 102.93 30.5171 105.241 39.5091C107.552 48.501 107.61 57.9241 105.41 66.9438C103.209 75.9634 98.8179 84.3011 92.6254 91.2184C86.4328 98.1356 78.6299 103.419 69.9078 106.6C61.1857 109.782 51.8136 110.763 42.6217 109.457C33.4298 108.151 24.7018 104.598 17.2109 99.1136C9.98911 93.8259 4.13195 86.8998 0.117483 78.9106C-0.130486 78.4171 0.079957 77.8191 0.577752 77.5799L21.7446 67.4094C22.2425 67.1702 22.8384 67.3807 23.0936 67.8705C25.2883 72.084 28.4252 75.7403 32.2656 78.5522C36.3752 81.5612 41.1634 83.5102 46.2062 84.2266C51.2489 84.943 56.3905 84.4048 61.1756 82.6595C65.9606 80.9142 70.2414 78.0157 73.6387 74.2208C77.0359 70.426 79.4449 65.8518 80.6522 60.9036C81.8594 55.9553 81.8277 50.7857 80.5598 45.8526C79.2919 40.9196 76.827 36.3753 73.3834 32.6224C70.1654 29.1154 66.1855 26.4006 61.7558 24.6837C61.2409 24.4841 60.9659 23.9149 61.1488 23.3938L68.9244 1.23484Z"></path>
          </svg>
         </div>
        </div>
       </div>
      </div>
      <div
       className="col-md-6 col-lg-6 col-xl-6 order-md-2"
       data-aos="fade-up"
       data-aos-duration="800"
       data-aos-offset="180"
       data-disable-parallax-down="md"
      >
       <div className="pe-md-4 pe-lg-0">
        <h2 className="h1 pb-sm-2 pb-lg-3">
         Full spectrum of business services
        </h2>
        <p className="fs-xl mb-lg-4">
         Find aute irure dolor in reprehenderit in voluptate velit esse cillum
         dolore eu fugiat.
        </p>
        <ul className="list-unstyled pb-3 mb-3 mb-lg-4">
         <li className="d-flex pt-1 mt-2 mx-2 mx-md-0">
          <i className="ai-check-alt text-primary fs-4 mt-n1 me-2"></i>
          Forecasting and recommendations
         </li>
         <li className="d-flex pt-1 mt-2 mx-2 mx-md-0">
          <i className="ai-check-alt text-primary fs-4 mt-n1 me-2"></i>
          Identification of patterns, analysis
         </li>
         <li className="d-flex pt-1 mt-2 mx-2 mx-md-0">
          <i className="ai-check-alt text-primary fs-4 mt-n1 me-2"></i>
          Segmentation by various methods
         </li>
        </ul>
        <a
         className=" d-inline-flex align-items-center fw-semibold text-decoration-none"
         href="https://calendly.com"
         target="_blank"
        >
         Request a demo
        </a>
       </div>
      </div>
     </div>
    </div>
   </section>

   {/* Join Our Team */}
   <section className="position-relative mt-n5 mt-lg-0">
    <div
     className="bg-light position-absolute bottom-0 start-0 w-100"
     style={{ height: "calc(100% - 3rem)" }}
    ></div>
    <div className="container position-relative zindex-2">
     <div className="row">
      <div className="col-lg-6 py-5 pb-lg-0 mb-sm-2 mb-md-3 mb-lg-0 mt-3 mt-sm-4 mt-md-5">
       <h2 className="h1 text-center text-lg-start pt-3 mt-3 mb-4">
        Become part <br className="d-none d-lg-inline" />
        of our team
       </h2>
       <div
        className="d-flex justify-content-between mx-auto mx-lg-0"
        style={{ maxWidth: "370px" }}
       >
        <ul className="ps-4 me-3">
         <li className="mt-2">Financial analyst</li>
         <li className="mt-2">Business analyst</li>
         <li className="mt-2">Software tester</li>
        </ul>
        <ul className="ps-4">
         <li className="mt-2">Head of marketing</li>
         <li className="mt-2">Strategy advisor</li>
         <li className="mt-2">Lead UX designer</li>
        </ul>
       </div>
       <div className="text-center text-lg-start pt-4">
        <Link className="btn btn-outline-dark" to={PAGES_URL.CAREERS}>
         Apply for a job
        </Link>
       </div>
       <div className="d-none d-lg-flex justify-content-end mt-n4 me-n4">
        <div
         data-aos="fade-left"
         data-aos-duration="700"
         data-aos-anchor-placement="bottom-bottom"
        >
         <svg
          className="text-danger"
          width="117"
          height="117"
          viewBox="0 0 117 117"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
         >
          <path d="M-2.51516e-06 58.5C-3.52666e-06 70.0702 3.43096 81.3806 9.85902 91.0008C16.2871 100.621 25.4235 108.119 36.113 112.547C46.8025 116.975 58.5649 118.133 69.9128 115.876C81.2607 113.619 91.6844 108.047 99.8657 99.8657C108.047 91.6844 113.619 81.2607 115.876 69.9128C118.133 58.5649 116.975 46.8025 112.547 36.113C108.119 25.4235 100.621 16.2871 91.0009 9.85903C81.3806 3.43096 70.0702 -4.24071e-06 58.5 -5.11423e-06L58.5 58.5L-2.51516e-06 58.5Z"></path>
         </svg>
        </div>
        <div
         data-aos="fade-right"
         data-aos-duration="700"
         data-aos-anchor-placement="bottom-bottom"
        >
         <svg
          className="text-info"
          width="117"
          height="117"
          viewBox="0 0 117 117"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
         >
          <circle cx="58.5" cy="58.5" r="58.5"></circle>
         </svg>
        </div>
       </div>
      </div>
      <div className="col-lg-6">
       <div className="bg-primary d-flex flex-column align-items-center justify-content-center position-relative h-100 rounded-1 rounded-bottom-0 p-5">
        <div
         className="dark-mode position-relative zindex-2 py-sm-3 py-md-4"
         style={{ maxWidth: "360px" }}
        >
         <h2 className="h1">Ready to discuss with us?</h2>
         <p className="mb-0">
          We are a team that creates financial investments tools
         </p>
         <div className="d-flex flex-column flex-sm-row align-items-center pt-3 mt-3 mt-lg-4">
          <Link
           className="btn btn-light w-100 w-sm-auto me-sm-4 mb-2 mb-sm-0"
           to={PAGES_URL.CONTACT_US}
          >
           Contact us
          </Link>
          <a
           className="d-inline-block text-white fw-medium p-2"
           href="mailto:email@example.com"
          >
           contact@senga.com
          </a>
         </div>
        </div>
       </div>
      </div>
     </div>
    </div>
   </section>
  </AppComponent>
 );
};

export default About;
