/** @format */

import { Icons } from "@assets/images";
import { InputField } from "@components/Inputs";
import DashboardComponent from "@components/dashboard/DashboardComponent";
import { HoverText } from "@components/dashboard/Util";
import React from "react";
import {
 BsCreditCard2BackFill,
 BsPatchQuestion,
 BsPlusCircle,
 BsPlusSquare,
 BsQuestionCircleFill,
} from "react-icons/bs";
import { FaCcVisa } from "react-icons/fa6";
import CreditCard from "./component/CreditCard";
import { Link } from "react-router-dom";

const Withdraw = () => {
 return (
  <DashboardComponent pageTitle="">
   <section className="section-fluid">
    <div className="d-flex   align-items-center mb-5">
     <div>
      <h1 className="ls-tight mb-1 d-flex ">
       Withdraw from wallet
       <HoverText message="Selected Investment Plan" Icon={BsPatchQuestion} />
      </h1>
      <p className="h4 fw-normal">Have an insight financial reports</p>
     </div>
    </div>

    <div className="row">
     <div className="col-lg-8">
      <div className="card rounded-2">
       <div className="card-body">
        <div className="d-flex flex-column ">
         <h5 className="mb-0 fw-light d-flex">
          Current balance{" "}
          <HoverText
           message="Current available wallet balance"
           Icon={BsQuestionCircleFill}
          />
         </h5>
         <h1 className="display-1 fw-normal d-flex mb-0">
          <span>$</span>
          <span>23,450.48</span>
         </h1>
        </div>

        <div className="row">
         <div className="col-lg-7">
          <form className="mt-5 ">
           <label className="h5 fw-normal mb-1" htmlFor="">
            Amount to withdraw
           </label>
           <div className="input-group mb-5">
            <span className="input-group-text">
             <i className="h5 mb-0 ai-dollar"></i>
            </span>
            <input type="text" className="form-control" placeholder="Amount" />

            <span className="input-group-text h5 mb-0">.00</span>
           </div>

           <label htmlFor="" className="h5 fw-normal mb-1">
            Choose Preferred Bank
           </label>
           <div className="mb-5 w-100">
            <select id="select-lg" className="form-select form-select-lg mb-3">
             <option> select option</option>
             <option>GTB - Ahmed - 0212344433</option>
             <option>Barter - Ahmed-FLT - 1234854773</option>
             <option>Felicity - B. Ahmed - 0034565544</option>
            </select>
            <Link to={"#"} className="text-end fw-bold ">
             Add new bank <BsPlusCircle className="ms-1" />
            </Link>
           </div>

           <button className="btn btn-lg btn-primary">Continue</button>
          </form>
         </div>
         <div className="col-lg-5"></div>
        </div>
       </div>
      </div>
     </div>
    </div>
   </section>
  </DashboardComponent>
 );
};

export default Withdraw;
