/** @format */

import React from "react";
import DashboardComponent from "@components/dashboard/DashboardComponent";
import { Link } from "react-router-dom";
import Button from "@components/Buttons";
import PAGES_URL from "@routers/routes";
import {
 BsArrowUpRightSquareFill,
 BsArrowDownLeftSquareFill,
} from "react-icons/bs";
import ChooseDestination from "./component/ChooseDestination";

const Transactions = () => {
 return (
  <>
   <div className="card mt-5 mt-lg-0">
    <div className="card-body pb-0">
     <div className="d-flex justify-content-between align-items-center">
      <div>
       <h5>Transaction History</h5>
      </div>
      <div className="hstack align-items-center">
       <a href="#" className="text-muted">
        <i className="ai ai-refresh"></i>
       </a>
      </div>
     </div>
     <div className="list-group list-group-flush">
      <div className="list-group-item d-flex align-items-center justify-content-between gap-6">
       <div className="d-flex align-items-center gap-3">
        <a
         className="d-inline-block bg-lighter rounded-1 p-2 p-lg-3"
         href="shop-single.html"
        >
         <BsArrowDownLeftSquareFill size={20} />
        </a>
        <div className="">
         <span className="d-block  text-sm fw-bold">Fund withdrawn</span>
         <span className="badge text-dark p-0">16th Dec 2021</span>
        </div>
       </div>
       {/* <div className="d-none d-md-block text-sm">0xd029384sd343fd...eq23</div> */}
       {/* <div className="d-none d-md-block">
        <span className="badge bg-body-secondary text-danger">Debit</span>
       </div> */}
       <div className="text-end">
        <span className="d-block text-heading text-danger text-sm fw-bold">
         - $1,000.00{" "}
        </span>
       </div>
      </div>
      <div className="list-group-item d-flex align-items-center justify-content-between gap-6">
       <div className="d-flex align-items-center gap-3">
        <a
         className="d-inline-block bg-lighter rounded-1 p-2 p-lg-3"
         href="shop-single.html"
        >
         <BsArrowUpRightSquareFill className="text-success" size={20} />
        </a>
        <div className="">
         <span className="d-block text-heading text-sm fw-semibold">
          Tim's investment pot{" "}
         </span>
         <span className="badge text-dark p-0">2nd Oct 2021</span>
        </div>
       </div>
       {/* <div className="d-none d-md-block text-sm">0xd029384sd343fd...eq23</div> */}
       {/* <div className="d-none d-md-block">
        <span className="badge bg-body-secondary text-success">Successful</span>
       </div> */}
       <div className="text-end">
        <span className="d-block text-heading text-sm fw-bold text-success">
         + $5000.00
        </span>
       </div>
      </div>
      <div className="list-group-item d-flex align-items-center justify-content-between gap-6">
       <div className="d-flex align-items-center gap-3">
        <a
         className="d-inline-block bg-lighter rounded-1 p-2 p-lg-3"
         href="shop-single.html"
        >
         <BsArrowUpRightSquareFill className="text-success" size={20} />
        </a>
        <div className="">
         <span className="d-block text-heading text-sm fw-semibold">
          investment pot{" "}
         </span>
         <span className="badge text-dark p-0">2nd Oct 2021</span>
        </div>
       </div>
       {/* <div className="d-none d-md-block text-sm">0xd029384sd343fd...eq23</div> */}
       {/* <div className="d-none d-md-block">
        <span className="badge bg-body-secondary text-success">Successful</span>
       </div> */}
       <div className="text-end">
        <span className="d-block text-heading text-sm fw-bold text-success">
         + $5000.00
        </span>
       </div>
      </div>
      <div className="list-group-item d-flex align-items-center justify-content-between gap-6">
       <div className="d-flex align-items-center gap-3">
        <a
         className="d-inline-block bg-lighter rounded-1 p-2 p-lg-3"
         href="shop-single.html"
        >
         <BsArrowUpRightSquareFill className="text-success" size={20} />
        </a>
        <div className="">
         <span className="d-block text-heading text-sm fw-semibold">
          investment pot{" "}
         </span>
         <span className="badge text-dark p-0">2nd Oct 2021</span>
        </div>
       </div>
       {/* <div className="d-none d-md-block text-sm">0xd029384sd343fd...eq23</div> */}
       {/* <div className="d-none d-md-block">
        <span className="badge bg-body-secondary text-success">Successful</span>
       </div> */}
       <div className="text-end">
        <span className="d-block text-heading text-sm fw-bold text-success">
         + $5000.00
        </span>
       </div>
      </div>
      <div className="list-group-item d-flex align-items-center justify-content-between gap-6">
       <div className="d-flex align-items-center gap-3">
        <a
         className="d-inline-block bg-lighter rounded-1 p-2 p-lg-3"
         href="shop-single.html"
        >
         <BsArrowUpRightSquareFill className="text-success" size={20} />
        </a>
        <div className="">
         <span className="d-block text-heading text-sm fw-semibold">
          investment pot{" "}
         </span>
         <span className="badge text-dark p-0">2nd Oct 2021</span>
        </div>
       </div>
       {/* <div className="d-none d-md-block text-sm">0xd029384sd343fd...eq23</div> */}
       {/* <div className="d-none d-md-block">
        <span className="badge bg-body-secondary text-success">Successful</span>
       </div> */}
       <div className="text-end">
        <span className="d-block text-heading text-sm fw-bold text-success">
         + $5000.00
        </span>
       </div>
      </div>
      <div className="list-group-item d-flex align-items-center justify-content-between gap-6">
       <div className="d-flex align-items-center gap-3">
        <a
         className="d-inline-block bg-lighter rounded-1 p-2 p-lg-3"
         href="shop-single.html"
        >
         <BsArrowUpRightSquareFill className="text-success" size={20} />
        </a>
        <div className="">
         <span className="d-block text-heading text-sm fw-semibold">
          investment pot{" "}
         </span>
         <span className="badge text-dark p-0">2nd Oct 2021</span>
        </div>
       </div>
       {/* <div className="d-none d-md-block text-sm">0xd029384sd343fd...eq23</div> */}
       {/* <div className="d-none d-md-block">
        <span className="badge bg-body-secondary text-success">Successful</span>
       </div> */}
       <div className="text-end">
        <span className="d-block text-heading text-sm fw-bold text-success">
         + $5000.00
        </span>
       </div>
      </div>
     </div>
    </div>
   </div>
  </>
 );
};

export default Transactions;
