/** @format */

import _02 from "./02.jpeg";
import _03 from "./03.jpeg";
import _04 from "./04.jpg";
import _08 from "./08.jpg";

const Teams = { _02, _03, _04, _08 };

export default Teams;
