/** @format */

import React, { useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
 BsArrowDown,
 BsArrowDownCircleFill,
 BsArrowUp,
 BsBarChartLineFill,
 BsPlusCircleFill,
 BsWalletFill,
} from "react-icons/bs";
import { Link } from "react-router-dom";
import PAGES_URL from "@routers/routes";

const items = [
 {
  id: 1,
  title: "Total Investments",
  icon: BsBarChartLineFill,
  amount: "0.48",
  background: "primary",
  button: "primary-light",
 },
 {
  id: 2,
  title: "Total Assets",
  icon: BsWalletFill,
  amount: "20,000.00",
  background: "secondary",
  button: "secondary-light",
 },

 // add more items as desired
];
const Board = () => {
 const settings = {
  slidesToShow: 2,
  responsive: [
   {
    breakpoint: 480,
    settings: {
     className: "center",
     slidesToShow: 1,
     slidesToScroll: 1,
     infinite: false,
     centerMode: true,
     variableWidth: false,
     centerPadding: "0px",
    },
   },
  ],
 };
 const slider = React.useRef<Slider | null>(null);

 const next = () => {
  slider.current?.slickNext();
 };
 const previous = () => {
  slider.current?.slickPrev();
 };

 return (
  <Slider {...settings}>
   {items.map((item, idx) => (
    <div key={idx} className={`card slick-item ${item.background} mb-5`}>
     <div className="card-body ">
      <div className="d-flex align-items-start">
       <a
        className={`d-inline-block btn-${item.button} rounded-1 p-2 p-lg-3 me-3`}
        href="#"
       >
        <item.icon size={40} color="#fff" />
       </a>
       <div className="col">
        <p className="h6 fw-bold text-uppercase text-sm text-light mb-0">
         {item.title}
        </p>
        <div className="d-flex align-items-start">
         <span className="h2 text-white">$ </span>
         <span className="h1 fw-bold text-white"> {item.amount}</span>
        </div>
        {item.id == 1 && (
         <>
          <div className="d-flex align-items-center">
           <div>
            <a className={`d-inline-block   `} href="#">
             <BsArrowDown className="text-danger" size={15} color="#fff" />
            </a>
            <span className="fw-bold text-danger small">
             - $4.00 <span className="d-none d-lg-inline-block">(USD)</span>
            </span>
            <span className="d-block text-end text-light small">TOTAL</span>
           </div>
           <div>
            <a className={`d-inline-block   ms-lg-3 ms-3`} href="#">
             <BsArrowUp className="text-success" size={15} color="#fff" />
            </a>
            <span className="fw-bold text-success small">
             + $35.40 <span className="d-none d-lg-inline-block">(USD)</span>
            </span>
            <span className="d-block text-end text-light small">DAILY</span>
           </div>
          </div>
         </>
        )}
       </div>
      </div>
      <div className="d-flex mt-3 justify-content-spacing">
       {item.id == 2 && (
        <>
         <Link
          to={PAGES_URL.DEPOSIT}
          className={`btn btn-${item.button} text-white`}
         >
          <BsPlusCircleFill className={`me-lg-3  me-1`} />
          Deposit
         </Link>
         <Link
          to={PAGES_URL.WITHDRAW}
          className={`btn  ms-3 btn-${item.button} text-white`}
         >
          <BsArrowDownCircleFill className="me-lg-3 me-1" /> Withdraw
         </Link>
        </>
       )}
      </div>
     </div>
    </div>
   ))}
  </Slider>
 );
};
export default Board;
