/** @format */

import React from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { Logo, Teams } from "@assets/images";
import { BsColumnsGap, BsPower } from "react-icons/bs";
import { logOutAction } from "@redux/actions/authentication";

interface Props {
 show?: boolean;
 title?: string;
 user?: { last_name: string; email: string };
 logout: typeof logOutAction;
}
const ProfileHeader = ({ user, logout }: Props) => {
 return (
  <nav className="nav-header placeabove">
   <div className="container-fluid">
    <div className="d-flex align-items-center pt-2 px-0 px-lg-5 pt-lg-2">
     <button
      className="d-lg-none border-0 bg-transparent  rounded-0"
      data-bs-toggle="offcanvas"
      data-bs-target="#sidebarAccount"
     >
      <BsColumnsGap size={25} className="text-dark-50" />
     </button>
     <div className="d-none d-lg-block d-xxl-flex align-items-center gap-4 px-4 scrollable-x">
      <div className="d-flex gap-2 text-xs">
       <span className="text-heading fw-semibold">Cryptos:</span>{" "}
       <span className="text-muted">21,713</span>
      </div>
      <div className="d-flex gap-2 text-xs">
       <span className="text-heading fw-semibold">Market Cap:</span>{" "}
       <span className="text-muted">$871,322,862,585</span>
      </div>
      <div className="d-flex gap-2 text-xs">
       <span className="text-heading fw-semibold">24h Vol:</span>{" "}
       <span className="text-muted">$180,639,667,232</span>
      </div>
     </div>

     <img
      className="mx-auto d-block d-lg-none"
      style={{ width: "80px" }}
      src={Logo}
      alt=""
     />

     <div className="ms-lg-auto">
      <a
       className="nav-link d-flex align-items-center "
       href="#"
       aria-expanded="false"
      >
       <img
        className="border rounded-circle"
        src={Teams._04}
        width="48"
        alt="Isabella Bocouse"
       />
       <div className="ps-2 d-none d-lg-block">
        {user && (
         <>
          <div className="fs-xs lh-1 opacity-60">Hello,</div>
          <div className="fs-sm fw-bold">{user.last_name}</div>
         </>
        )}
       </div>
      </a>
     </div>
     <BsPower
      onClick={() => logout()}
      size={30}
      className="d-none d-lg-block ms-4 pointer"
     />
    </div>
   </div>
  </nav>
 );
};

const mapStateToProps = (state: any) => ({
 user: state.user.data,
});

const mapDispatchToProps = (dispatch: any) => ({
 logout: () => dispatch(logOutAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileHeader);
