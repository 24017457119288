/** @format */

import { OverlayTrigger } from "react-bootstrap";
import Tooltip from "react-bootstrap/Tooltip";

type TooltipPros = {
 message: string;
 Icon: any;
 position?: any;
};

const HoverText = ({ message, Icon, position }: TooltipPros) => {
 const renderTooltip = (props: any) => (
  <Tooltip id="button-tooltip" {...props}>
   {message}
  </Tooltip>
 );

 return (
  <OverlayTrigger
   placement={position}
   delay={{ show: 250, hide: 400 }}
   overlay={renderTooltip}
  >
   <div className="">
    <Icon
     className="position-absolute ms-2 mt-1 pointer text-dark-75"
     size={15}
    />
   </div>
  </OverlayTrigger>
 );
};

export { HoverText };
