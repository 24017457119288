/** @format */

import { Teams } from "@assets/images";
import React from "react";
import { BsDot } from "react-icons/bs";

const MessageList = () => {
 const messages = [
  {
   id: 1,
   image: Teams._02,
   name: "Jeffery Cummings",
   timestamps: "34 min ago",
   subject: "isses with creating new investment pot...",
  },
  {
   image: Teams._03,
   name: "Tommy T",
   timestamps: "10 min ago",
   subject: "unable to verify my drivers license with...",
  },
  {
   image: Teams._04,
   name: "Tiz Chikopela",
   timestamps: "2 min ago",
   subject: "unable to withdraw funds from pot...",
  },
  {
   image: Teams._08,
   name: "Mary T. Agnes",
   timestamps: "1 hr ago",
   subject: "issues creating a new investment pot...",
  },
 ];
 return (
  <>
   {messages &&
    messages.map((message, index) => (
     <div
      key={index}
      className={`card rounded-1 ${index == 0 ? "bg-light" : ""} my-3`}
     >
      <div className="card-body p-3">
       <div className="d-flex align-items-center pb-1 mb-3">
        <img
         src={message.image}
         className="rounded-circle"
         width="48"
         alt="Comment author"
        />
        <div className="ps-3">
         <h6 className="mb-0">{message.name}</h6>
        </div>
        <p className="fw-normal ms-auto mb-0">{message.timestamps}</p>
       </div>

       <p className="">{message.subject}</p>
       <div className="d-flex justify-content-between mb-0">
        <span className="badge bg-success bg-opacity-10 h6 text-dark mb-0">
         Open
        </span>
        <span className="badge bg-danger bg-opacity-10 mb-0 h6 text-danger">
         <BsDot size={20} className="me-0 pe-0 mb-0" /> High Priority
        </span>
        <span className="badge bg-warning bg-opacity-10 h6 mb-0 text-warning">
         Sales Department
        </span>
       </div>
      </div>
     </div>
    ))}
  </>
 );
};

export default MessageList;
