/** @format */

import _10 from "./careers.jpeg";
import _11 from "./11.jpeg";
import _12 from "./12.jpeg";
import _13 from "./13.jpg";

const Hero = { _10, _11, _12, _13 };

export default Hero;
