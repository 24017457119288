/** @format */

import supabase from "@lib/supabase/client";
import {
 SET_USER,
 UPDATE_PASSWORD_REQUEST,
 UPDATE_PASSWORD_SUCCESS,
 UPDATE_USER_FAILURE,
 UPDATE_USER_REQUEST,
 UPDATE_USER_SUCCESS,
} from "@redux/reducers/types";
import { setLoading } from "./handler";
import res from "@helpers/response";
import { toast } from "react-toastify";
import { headers } from "@helpers/headers";
/**
 *
 * @returns
 */
export const getUser = () => async (dispatch: any) => {
 dispatch(setLoading(true));
 try {
  const { data: sessionData } = await supabase.auth.getUser();
  const { data, error } = await supabase.functions.invoke("users", {
   body: { id: sessionData.user?.id },
   headers,
  });

  if (error) throw error;

  dispatch({ type: SET_USER, payload: data });
  dispatch(setLoading(false));
 } catch (err) {
  dispatch(setLoading(false));
 }
};

/**
 *
 * @param data
 * @param id
 * @returns
 */
export const updateUser = (formData: any) => async (dispatch: any) => {
 // set loading state of form to true
 try {
  dispatch({ type: UPDATE_USER_REQUEST });

  const { data, error } = await supabase.functions.invoke("users/update", {
   body: formData,
   headers,
  });
  if (error) throw error;

  dispatch({ type: UPDATE_USER_SUCCESS, payload: data });
  toast.success("Profile updated successfully");
 } catch (error: any) {
  const err = await res.error(error);
  dispatch({ type: UPDATE_USER_FAILURE, payload: err });
  toast.error(err.message);
 }
};

// export const changeAvatar = (user: any) => async (dispatch: any) => {
//  // set loading state of form to true
//  dispatch({ type: GlobalActionTypes.LOADING, payload: true });

//  try {
//   // set loading state of form to true
//   const { data: userData, error } = await supabase.auth.updateUser({
//    data: { ...user },
//   });

//   // toast error is any
//   if (error) throw error;

//   dispatch({ type: GlobalActionTypes.LOADING });
//   toast.success("successfully updated");
//  } catch (err: any) {
//   console.log(err);
//   dispatch({ type: GlobalActionTypes.LOADING });
//   toast.error(err?.message);
//  }
// };

// /**
//  *
//  * @param data
//  * @param id
//  * @returns
//  */

export const updateUserPassword = (formData: any) => async (dispatch: any) => {
 dispatch({ type: UPDATE_PASSWORD_REQUEST });
 try {
  // fetch and compare old password
  const { error } = await supabase.functions.invoke("users/password/update", {
   body: formData,
   headers,
  });
  if (error) throw error;
  dispatch({ type: UPDATE_PASSWORD_SUCCESS });
  toast.success("Password updated successfully");
 } catch (error) {
  toast.error((await res.error(error)).message);
 }
};
