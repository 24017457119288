/** @format */

import { createStore, applyMiddleware } from "redux";
import ThunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
import { composeWithDevTools } from "redux-devtools-extension";
import { loadState } from "./localStorage";
import rootReducer from "./reducers/rootReducer";
import sessionExpirationMiddleware from "./middleware/AuthMiddleware";
import client from "@lib/supabase/client";
import { clearError } from "./actions/handler";

const LoggerMiddleware = createLogger();
const persistedState = loadState();
const store = createStore(
 rootReducer,
 persistedState,
 composeWithDevTools(
  applyMiddleware(
   LoggerMiddleware,
   ThunkMiddleware,
   sessionExpirationMiddleware
  )
 )
);

export default store;
store.dispatch(clearError());
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
