/** @format */

import React from "react";
import DashboardComponent from "@components/dashboard/DashboardComponent";
import RecentPots from "./components/RecentPots";
import { connect } from "react-redux";
import Board from "./components/Board";
import Transactions from "@pages/dashboard/wallet/Transactions";
import EarningChart from "./wallet/component/EarningChart";
import { BsHandIndexThumbFill } from "react-icons/bs";

interface Props {
 user?: any;
}

const Wallet = ({ user }: Props) => {
 console.log(user);
 return (
  <>
   <DashboardComponent pageTitle={`Wallet`}>
    <section className="section-fluid">
     <div className="d-flex g-3 align-items-center mb-3">
      <h1 className="ls-tight">👋 Howdy, {user.first_name}!</h1>
     </div>
     <div className="row">
      <div className="col-lg-8" style={{ minWidth: "0" }}>
       <Board />
       <RecentPots />
       <EarningChart />
      </div>
      <div className="col-lg-4">
       <Transactions />
      </div>
     </div>
    </section>
   </DashboardComponent>
  </>
 );
};

const mapStateToProps = (state: any) => ({
 user: state.user.data,
});

export default connect(mapStateToProps, null)(Wallet);
