/** @format */

import { UpdateUserPassword as User } from "@helpers/types/user.type";

export const UpdateUserPasswordValidator = (values: any) => {
 const errors = {} as User;

 // set current password to required
 if (!values.current_password) {
  errors.current_password = "Please enter current Password";
 }

 // set new password to required

 if (!values.password) {
  errors.password = "Please enter a valid password";
 } else if (values.password.length < 8) {
  errors.password = "Password must be more than 8 characters";
 }

 if (!values.confirm) {
  errors.confirm = "Please enter a valid password";
 } else if (values.confirm.length < 8) {
  errors.confirm = "Password must be more than 8 characters";
 }

 if (values.confirm != values.password) {
  errors.confirm = "Password does not match";
 }

 return errors;
};
