/** @format */

import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import DashboardComponent from "@components/dashboard/DashboardComponent";
import PAGES_URL from "@routers/routes";
import { logOutAction } from "@redux/actions/authentication";
import { connect } from "react-redux";
import { Icons, Teams } from "@assets/images";
import { getUser } from "@redux/actions/user";
import {
 BsBellFill,
 BsBuildingAdd,
 BsLockFill,
 BsPersonBadgeFill,
} from "react-icons/bs";
import EditAccountSettings from "./components/EditAccountSettings";
import UpdatePassword from "./components/UpdatePassword";
import NotificationSettings from "./components/NotificationSettings";

const Account = (props: any) => {
 const { user, fetchUser } = props;

 useEffect(() => {
  fetchUser();
 }, [fetchUser]);

 const logout = () => {
  props.logout();
 };
 return (
  <>
   <DashboardComponent pageTitle={"Overview"} profileHeader={false}>
    <div className="section-fluid">
     <div className="row">
      <div className="col-lg-12">
       <ul className="nav " role="tablist">
        <li className="nav-item">
         <a
          href="#home"
          className="nav-link active"
          data-bs-toggle="tab"
          role="tab"
         >
          <BsPersonBadgeFill className="me-lg-3" />
          Personal Information
         </a>
        </li>
        <li className="nav-item">
         <a
          href="#security"
          className="nav-link"
          data-bs-toggle="tab"
          role="tab"
         >
          <BsLockFill className="me-lg-3" />
          Security
         </a>
        </li>
        <li className="nav-item">
         <a
          href="#notification"
          className="nav-link"
          data-bs-toggle="tab"
          role="tab"
         >
          <BsBellFill className="me-lg-3" />
          Notification
         </a>
        </li>
        <li className="nav-item">
         <a
          href="#billing"
          className="nav-link"
          data-bs-toggle="tab"
          role="tab"
         >
          <i className="fi-user me-2"></i>
          Billing
         </a>
        </li>
       </ul>

       <div className="tab-content">
        <div className="tab-pane fade show active" id="home" role="tabpanel">
         <div className="row mt-5">
          <div className="col-lg-8">
           <EditAccountSettings />
          </div>
         </div>
        </div>
        <div className="tab-pane fade" id="security" role="tabpanel">
         <div className="row mt-5">
          <div className="col-lg-8">
           <UpdatePassword />
          </div>
         </div>
        </div>
        <div className="tab-pane fade" id="notification" role="tabpanel">
         <div className="row mt-5">
          <div className="col-lg-8">
           <NotificationSettings />
          </div>
         </div>
        </div>
        <div className="tab-pane fade" id="settings" role="tabpanel">
         <p>
          Trust fund seitan letterpress, keytar raw denim keffiyeh etsy art
          party before they sold out master cleanse gluten-free squid scenester
          freegan cosby sweater. Fanny pack portland seitan DIY, art party
          locavore wolf cliche high life echo park Austin. Cred vinyl keffiyeh
          DIY salvia PBR, banh mi before they sold out farm-to-table VHS viral
          locavore cosby sweater. Lomo wolf viral, mustache readymade
          thundercats keffiyeh craft beer marfa ethical.
         </p>
        </div>
       </div>
      </div>
     </div>
    </div>
   </DashboardComponent>
  </>
 );
};

const mapStateToProps = (state: any) => ({
 user: state.user.data,
});

const mapDispatchToProps = (dispatch: any) => ({
 logout: () => dispatch(logOutAction()),
 fetchUser: () => dispatch(getUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Account);
