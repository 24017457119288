/** @format */
import React, { useEffect, useState } from "react";
import PotDashboard from "./components/PotDashboard";
import DashboardComponent from "@components/dashboard/DashboardComponent";
import Button from "@components/Buttons";
import AmountModal from "./components/AmountModal";
import { RiskLevelValidator } from "@helpers/Validator";
import { useHistory } from "react-router-dom";
import PAGES_URL from "@routers/routes";
import { connect, useSelector } from "react-redux";
import { setPotError, setPotData } from "@redux/actions/pots";
import { getPotTypeData, getSelectedRiskMandate } from "@redux/selectors"; // Assuming this is the correct import path

interface RiskLevelProps {
 setPotData: typeof setPotData;
 setPotError: typeof setPotError;
}

interface RiskMandate {
 risk_mandate_id: string;
 name: string;
 description: string;
 priority: number;
 risk_mandates: {
  name: string;
  description: string;
  risk_mandate_assets: {
   asset_id: string;
   asset_weight: number;
   assets: {
    asset_name: string;
   };
  }[];
 };
}

const RiskLevel: React.FC<RiskLevelProps> = ({ setPotData, setPotError }) => {
 const history = useHistory();
 const [isModalVisible, setModalVisible] = useState(false);
 const [selectedRiskMandate, setSelectedRiskMandate] =
  useState<RiskMandate | null>(null);
 const potTypeData = useSelector(getPotTypeData);
 const selectedRiskMandateFromStore = useSelector(getSelectedRiskMandate);
 const riskMandates: RiskMandate[] = potTypeData.pot_type_risk_mandates;
 const handleRiskMandateChange = (riskMandate: any) => {
  console.log(riskMandate);
  setSelectedRiskMandate(riskMandate);
 };
 useEffect(() => {
  // If there's a selected risk mandate in the store, use that. Otherwise, use the first risk mandate from riskMandates.
  setSelectedRiskMandate(
   selectedRiskMandateFromStore ||
    (riskMandates && riskMandates.length > 0 ? riskMandates[0] : null)
  );
 }, [selectedRiskMandateFromStore, riskMandates]);

 useEffect(() => {
  if (selectedRiskMandate) {
   setPotData({ riskMandate: selectedRiskMandate });
  }
 }, [selectedRiskMandate, setPotData]);

 const handleSubmit = (e: any) => {
  try {
   e.preventDefault();
   const errors = RiskLevelValidator({ riskMandate: selectedRiskMandate });
   if (Object.keys(errors).length !== 0) throw errors;
   setPotError({}); // Clear errors in the Redux store

   setModalVisible((prev) => !prev);
  } catch (err: any) {
   console.log(err);
   setPotError(err); // Dispatch an action to set errors
  }
 };

 const handleClose = () => {
  setModalVisible(false);
 };

 return (
  <>
   <DashboardComponent pageTitle="Risk level">
    <PotDashboard progress="75">
     <form action="" className="mt-4">
      <div className="row">
       {riskMandates
        .sort((a, b) => a.priority - b.priority)
        .map((mandate, index) => (
         <div key={index} className="col-lg-4 col-4">
          <label className="btn btn-light btn__xl w-100 fw-light">
           <input
            onChange={() => handleRiskMandateChange(mandate)}
            type="radio"
            name="riskMandate"
            value={mandate.risk_mandate_id}
            checked={
             selectedRiskMandate
              ? selectedRiskMandate.risk_mandate_id === mandate.risk_mandate_id
              : false
            }
            style={{ marginRight: "5px" }}
           />
           {mandate.risk_mandates.name}
          </label>
         </div>
        ))}
      </div>
      <div className="col-lg-12 my-3 my-lg-4">
       <p className="small fw-light mt-2 mt-lg-3">
        {selectedRiskMandate && riskMandates
         ? riskMandates.find(
            (mandate) =>
             mandate.risk_mandate_id === selectedRiskMandate.risk_mandate_id
           )?.risk_mandates?.description || ""
         : ""}
       </p>
      </div>
      <div className="row mt-lg-5 mt-3">
       {selectedRiskMandate && riskMandates
        ? (
           riskMandates.find(
            (mandate) =>
             mandate.risk_mandate_id === selectedRiskMandate.risk_mandate_id
           )?.risk_mandates?.risk_mandate_assets || []
          ).map((asset, index) => (
           <div key={index} className="col-md-12">
            <label className="d-flex fw-normal">
             {asset.assets.asset_name}
             <span className="ms-auto fw-lighter text-primary">
              {asset.asset_weight}%
             </span>
            </label>
            <div className="progress mt-2">
             <div
              className="progress-bar bg-primary"
              role="progressbar"
              style={{ width: `${asset.asset_weight}%` }}
              data-valuenow={asset.asset_weight}
              data-valuemin="0"
              data-valuemax="100"
             ></div>
            </div>
           </div>
          ))
        : null}
      </div>

      <div className="mt-3 mt-lg-5">
       <div className="d-flex">
        <Button
         className={"btn-light mt- btn__lg me-4 ms-auto d-none d-lg-block"}
         onClick={() => history.push(PAGES_URL.CREATE_POT)}
        >
         Back
        </Button>
        <Button
         type="button"
         onClick={handleSubmit}
         className={"btn-primary  btn__lg"}
        >
         Continue
        </Button>
       </div>
      </div>
     </form>
    </PotDashboard>
   </DashboardComponent>
   {/*<AmountModal*/}
   {/* name="Amount"*/}
   {/* isVisible={isModalVisible}*/}
   {/* handleClose={handleClose}*/}
   {/*/>*/}
  </>
 );
};

const mapStateToProps = (state: any) => ({
 potTypeData: state.potTypeData,
 error: state.pots.error || {},
});

const mapDispatchToProps = {
 setPotData: setPotData,
 setPotError: setPotError,
};

export default connect(mapStateToProps, mapDispatchToProps)(RiskLevel);
