/** @format */

import React from "react";
import DashboardComponent from "@components/dashboard/DashboardComponent";
import { FcParallelTasks } from "react-icons/fc";
import PAGES_URL from "@routers/routes";
import { Link } from "react-router-dom";

const ViewAllPot = () => {
 return (
  <>
   <DashboardComponent pageTitle="">
    <section className="section-fluid">
     <div className="d-flex g-3 align-items-center mb-3">
      <div className="">
       <h1 className="ls-tight">
        <FcParallelTasks /> Pot's Overview
       </h1>
       <p className="h4 fw-normal">
        Check and track all created pots with your account. A view of your
        journey with us
       </p>
      </div>
     </div>
     <div className="accordion" id="accordionDefault">
      <div className="accordion-item bg-transparent shadow-0">
       <h3 className="accordion-header " id="headingOne">
        <button
         className="accordion-button"
         type="button"
         data-bs-toggle="collapse"
         data-bs-target="#collapseOne"
         aria-expanded="true"
         aria-controls="collapseOne"
        >
         Active (2)
        </button>
       </h3>
       <div
        className="accordion-collapse collapse show"
        id="collapseOne"
        aria-labelledby="headingOne"
        data-bs-parent="#accordionDefault"
       >
        <div className="row">
         <div className="col-lg-4">
          <div
           className={`me-5 card rounded-1`}
           style={{ borderLeft: "3px solid #5f236e" }}
          >
           <div className="card-body p-4">
            <h5 className="h6">
             Moniepoint
             <span className="fw-light">General 1</span>
            </h5>
            <h4 className="pt-3 mb-0 text-primary">$ 500.00</h4>
            <span className="text-success">+34%</span>

            <div className="d-flex mt-4">
             <p>Low Risk General Pot 1</p>
             <Link
              className="ms-auto text-underline"
              to={{
               pathname: PAGES_URL.DETAILS,
               search: `id=1`,
               state: { potId: 1 },
              }}
             >
              View
             </Link>
            </div>
           </div>
          </div>
         </div>
         <div className="col-lg-4">
          <div
           className={`me-5 card rounded-1`}
           style={{ borderLeft: "3px solid #5f236e" }}
          >
           <div className="card-body p-4">
            <h5 className="h6">
             Moniepoint
             <span className="fw-light">General 1</span>
            </h5>
            <h4 className="pt-3 mb-0 text-primary">$ 500.00</h4>
            <span className="text-success">+34%</span>

            <div className="d-flex mt-4">
             <p>Low Risk General Pot 1</p>
             <Link
              className="ms-auto text-underline"
              to={{
               pathname: PAGES_URL.DETAILS,
               search: `id=1`,
               state: { potId: 1 },
              }}
             >
              View
             </Link>
            </div>
           </div>
          </div>
         </div>
        </div>
       </div>
      </div>

      <div className="accordion-item bg-transparent shadow-0">
       <h3 className="accordion-header" id="headingTwo">
        <button
         className="accordion-button collapsed"
         type="button"
         data-bs-toggle="collapse"
         data-bs-target="#collapseTwo"
         aria-expanded="false"
         aria-controls="collapseTwo"
        >
         Inactive (0)
        </button>
       </h3>
       <div
        className="accordion-collapse collapse"
        id="collapseTwo"
        aria-labelledby="headingTwo"
        data-bs-parent="#accordionDefault"
       >
        <div className="accordion-body fs-sm">
         This is the second item's accordion body. It is hidden by default,
         until the collapse plugin adds the appropriate classes that we use to
         style each element.
        </div>
       </div>
      </div>
     </div>
    </section>
   </DashboardComponent>
  </>
 );
};

export default ViewAllPot;
