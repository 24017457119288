/** @format */

import { ClassNames } from "@emotion/react";
import React, { useState } from "react";
import { BsInfoCircleFill, BsPatchQuestion } from "react-icons/bs";
import { HoverText } from "./dashboard/Util";

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
 className?: string;
 name?: string;
 icon?: string;
 errorMessage?: string;
}
const InputField = ({
 className,
 icon,
 errorMessage,
 ...props
}: InputProps) => {
 return (
  <>
   <div className="position-relative">
    {icon && (
     <i
      className={`${icon} position-absolute top-50 start-0 translate-middle-y ms-3`}
     ></i>
    )}
    <input
     className={`form-control form-control-lg ${className} ${
      errorMessage && "border-danger"
     }`}
     {...props}
    />
   </div>
   {errorMessage && (
    <p className=" my-1 small text-danger">
     <BsInfoCircleFill /> {errorMessage}
    </p>
   )}
  </>
 );
};

interface OptionProps extends React.InputHTMLAttributes<HTMLInputElement> {
 className?: string;
 label: string;
}

const InputOption = ({ className, label, ...props }: OptionProps) => {
 return (
  <>
   <input type="radio" className="btn-check" {...props} />
   <label
    htmlFor={props.id}
    className={`border-sm p-3 rounded-1 w-100 ${
     props.checked ? "bg-primary text-white" : ""
    }  ${className}`}
   >
    <span className="">{label}</span>
   </label>
  </>
 );
};

interface InputPasswordFieldProps
 extends React.InputHTMLAttributes<HTMLInputElement> {
 className?: string;
 name?: string;
 icon?: boolean;
 placeholder?: string;
 errorMessage?: string;
 value?: string;
}
const InputPasswordField = ({
 className,
 icon,
 errorMessage,
 ...props
}: InputPasswordFieldProps) => {
 const [toggler, setToggler] = useState(false);

 return (
  <>
   <div className="position-relative">
    {icon && (
     <i
      className={`ai-lock-closed fs-lg position-absolute top-50 start-0 translate-middle-y ms-3`}
     ></i>
    )}
    <div className="password-toggle">
     <input
      className={`form-control form-control-lg ${className}  ${
       errorMessage && "border-danger"
      }`}
      type={toggler ? "text" : "password"}
      placeholder="Password"
      {...props}
     />
     <label className="password-toggle-btn" aria-label="Show/hide password">
      <input className="password-toggle-check" type="checkbox" />
      <span
       className="password-toggle-indicator"
       onClick={() => setToggler(!toggler)}
      ></span>
     </label>
    </div>
   </div>

   {errorMessage && (
    <p className=" my-1 small text-danger">
     <BsInfoCircleFill /> {errorMessage}
    </p>
   )}
  </>
 );
};

//TODO: Give nav a proper type
interface SelectProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
 data: any;
 label?: string;
 selected?: any;
 errorMessage: any;
 infoMessage?: string;
}
const Select = ({
 data,
 label,
 selected,
 errorMessage,
 infoMessage,
 ...props
}: SelectProps) => {
 return (
  <>
   <label className="form-label d-flex" htmlFor="language">
    {label}
    {infoMessage ? (
     <HoverText message={infoMessage} Icon={BsPatchQuestion} />
    ) : (
     ""
    )}
   </label>
   <select className="form-select form-select-lg" {...props}>
    {selected === null && <option>Please select {props.id}</option>};
    {Object.keys(data).length > 0 &&
     data.map((i: any, key: any) =>
      selected?.id == i.id ? (
       <option key={key} value={i.id} selected>
        {i.name}
       </option>
      ) : (
       <option key={key} value={i.id}>
        {i.name}
       </option>
      )
     )}
   </select>
   {errorMessage && (
    <p className=" my-1 small text-danger">
     <BsInfoCircleFill /> {errorMessage}
    </p>
   )}
  </>
 );
};

export { Select, InputOption };
export { InputField, InputPasswordField };
