/** @format */
/** @format */

import FeatherIcon from "feather-icons-react";
import React from "react";
import DashboardComponent from "../../../components/dashboard/DashboardComponent";
import MessageLink from "./components/MessageLink";
import {
 BsChevronBarDown,
 BsDot,
 BsFilePdfFill,
 BsFilterSquareFill,
 BsSearch,
} from "react-icons/bs";
import { Link } from "react-router-dom";
import MessageList from "./components/MessageList";
import MessageBody from "./components/MessageBody";

const Messages = () => {
 return (
  <>
   <DashboardComponent pageTitle={"MESSAGES"}>
    <div className="section-fluid-lg vh-92" style={{ overflow: "hidden" }}>
     <div className="row position-relative vh-92">
      <div className="col-lg-3">
       <div
        className="card rounded-1 border-0  vh-100"
        style={{ overflow: "hidden" }}
       >
        <div className="card-body pe-2">
         <h3 className="mb-5 fw-normal">Messages</h3>
         <div className="d-flex  align-items-center">
          <div className="input-group rounded-1 me-3 p-0">
           <span className="input-group-text">
            <BsSearch size={20} />
           </span>
           <input
            type="search"
            className="form-control "
            placeholder="Search..."
           />
          </div>
          <Link to={""} className=" border-1 ">
           <BsFilterSquareFill size={30} />
          </Link>
         </div>
         <h5 className="fw-medium mt-5">My Open Ticket (6)</h5>
         <div className="pe-3" style={{ overflowY: "scroll", height: "80%" }}>
          <MessageList />
         </div>
        </div>
       </div>
      </div>
      <div className="col-lg-9">
       <div className="row">
        <div className="col-lg-8">
         <div className="card rounded-2">
          <div className="card-body">
           <h4>Isses with creating new investment pot on Senga</h4>
           <div className="d-flex">
            <span className="badge bg-success bg-opacity-10 text-dark me-3">
             Open
            </span>
            <span className="badge bg-danger bg-opacity-10 text-danger me-3">
             <BsDot size={20} className="me-0 pe-0" /> High Priority
            </span>
            <span className="badge bg-warning bg-opacity-10 text-warning">
             Sales Department
            </span>
           </div>
           <hr className="my-5" />
           <div>
            {" "}
            <MessageBody />
           </div>
          </div>
         </div>
        </div>
        <div className="col-lg-4">
         <div className="card rounded-2">
          <div className="card-body">
           <div className="d-flex justify-content-between align-items-center">
            <h4 className="mb-0 ">Ticket Action</h4>
            <BsChevronBarDown />
           </div>
          </div>
         </div>
         <div className="card rounded-2 mt-5">
          <div className="card-body">
           <h4 className="mb-0 h5">Customers Information</h4>
           <div className="d-flex justify-content-between mt-5">
            <h6 className="fw-normal h5 mb-0">Name</h6>
            <span className="h6">Ahmed Ahmed</span>
           </div>
           <div className="d-flex justify-content-between mt-3">
            <h6 className="fw-normal h5 mb-0">Email</h6>
            <span className="text-primary h6">trybemark@gmail.com</span>
           </div>
           <div className="d-flex justify-content-between mt-3">
            <h6 className="fw-normal h5 mb-0">Location</h6>
            <span className="text-primary h6">Nigeria</span>
           </div>
           <div className="d-flex justify-content-between mt-3">
            <h6 className="fw-normal h5 mb-0">Timezone</h6>
            <span className="text-primary h6">2.30 PM (WAT)</span>
           </div>
           <div className="d-flex justify-content-between mt-3">
            <h6 className="fw-normal h5 mb-0">Language</h6>
            <span className="badge bg-success bg-opacity-10 text-dark h6">
             English
            </span>
           </div>
           <hr className="my-5" />
           <div className="d-flex justify-content-between">
            <h6 className="fw-normal h5 mb-0">Status</h6>
            <span className="badge bg-success bg-opacity-10 text-dark">
             Open
            </span>
           </div>
          </div>
         </div>
         <div className="card rounded-2 mt-5">
          <div className="card-body">
           <h4 className="mb-0 h5">Files Shared</h4>
           <hr className="my-4" />
           <div className="d-flex align-items-center">
            <BsFilePdfFill size={40} className="text-danger me-lg-3" />
            <h6 className="fw-normal h6 mb-0">
             error-page.pdf
             <span className="d-block small">
              shared with jeffery on 24th May, 2023
             </span>
            </h6>
           </div>
           <div className="d-flex align-items-center  mt-3">
            <BsFilePdfFill size={40} className="text-danger me-lg-3" />
            <h6 className="fw-normal h6 mb-0">
             error-number.pdf
             <span className="d-block small">
              shared with jeffery on 25th May, 2023
             </span>
            </h6>
           </div>
           <div className="d-flex align-items-center  mt-3 mb-3">
            <BsFilePdfFill size={40} className="text-danger me-lg-3" />
            <h6 className="fw-normal h6 mb-0">
             document..pdf
             <span className="d-block small">
              shared with jeffery on 25th May, 2023
             </span>
            </h6>
           </div>
           <Link to={""} className="mt-4 text-primary fw-bold">
            see more
           </Link>
          </div>
         </div>
        </div>
       </div>
      </div>
     </div>
    </div>
   </DashboardComponent>
  </>
 );
};

export default Messages;
