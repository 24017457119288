/** @format */

import supabase from "@lib/supabase/client";
import {
 LOGIN_FAILURE,
 LOGIN_REQUEST,
 LOGIN_SUCCESS,
 FORGOT_PASSWORD_FAILURE,
 FORGOT_PASSWORD_REQUEST,
 FORGOT_PASSWORD_SUCCESS,
 LOGOUT,
 REGISTER_REQUEST,
 REGISTER_SUCCESS,
 REGISTER_FAILURE,
 SET_USER,
 AUTHENTICATED,
 CLEAR_ERROR,
} from "@redux/reducers/types";
import history from "./history";
import res from "@helpers/response";

/**
 *
 * @param user
 * @returns
 */

export const loginAction = (formData: any) => async (dispatch: any) => {
 dispatch({ type: LOGIN_REQUEST });

 try {
  const response = await supabase.functions.invoke("auth/login", {
   body: formData,
  });
  const { data, error } = response;

  if (error) throw error;

  dispatch({ type: LOGIN_SUCCESS, payload: data.session });
  dispatch({ type: SET_USER, payload: data.userPayload });
  await supabase.auth.setSession(data.session);

  setTimeout(() => {
   dispatch({ type: AUTHENTICATED });
  }, 1000);
 } catch (error: any) {
  dispatch({ type: LOGIN_FAILURE, payload: await res.error(error) });
 } finally {
  dispatch({ type: CLEAR_ERROR });
 }
};

/**
 * Register Action
 * Pass user registration field to supabase
 * @param user
 * @returns
 */

export const registerAction = (formData: any) => async (dispatch: any) => {
 try {
  dispatch({ type: REGISTER_REQUEST });
  const { data, error } = await supabase.functions.invoke("auth/register", {
   body: formData,
  });
  if (error) throw error;
  dispatch({ type: REGISTER_SUCCESS, payload: data });
  history.push("/login");
 } catch (error: any) {
  dispatch({ type: REGISTER_FAILURE, payload: await res.error(error) });

  return;
 }
};

/**
 * Forgot Password Action
 * Validate user email address and send reset token
 * @param email
 * @returns
 */

export const forgotPasswordAction =
 (formData: any) => async (dispatch: any) => {
  dispatch({ type: FORGOT_PASSWORD_REQUEST });
  try {
   const { data, error } = await supabase.functions.invoke(
    "auth/password/reset",
    {
     body: formData,
    }
   );

   if (error) throw error;

   dispatch({ type: FORGOT_PASSWORD_SUCCESS, payload: data });
  } catch (error: any) {
   dispatch({ type: FORGOT_PASSWORD_FAILURE, payload: await res.error(error) });
  }
 };

// /**
//  * Confirm Password Action
//  * Update users password
//  * @param user
//  * @returns
//  */

// export const confirmPasswordAction = (user: any) => async (dispatch: any) => {
//  dispatch({ type: GlobalActionTypes.LOADING, payload: true });

//  try {
//   const { error } = await supabase.auth.updateUser({
//    password: user.confirm,
//   });
//   if (error) {
//    dispatch({ type: GlobalActionTypes.LOADING });
//    toast.error(`${error.name} ${error.message}`);
//    return;
//   }
//   toast.success("Account recovery information has been sent to your email");
//  } catch (err: any) {
//   // log error in browser console
//   console.log(err);
//  }
// };

/**
 * RefreshTokenAction
 * check if the state has almost expired
 * @returns
 */

// export const refreshTokenAction = () => async (dispatch: any) => {};

/**
 * Logout Action
 * dispatch session and authenticated states to default
 * @returns
 */

export const logOutAction = () => async (dispatch: any) => {
 try {
  await supabase.auth.signOut();
  dispatch({ type: LOGOUT });
  history.push("/login");
 } catch (err) {}
};
