/** @format */

const error = async (errorContext: any) => {
 const { error: errorMessage } = await errorContext.context.json();
 const response = {
  message: errorMessage,
  status: errorContext.context.status,
 };

 return response;
};

const res = {
 error,
};

export default res;
