/** @format */

import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PAGES_URL from "./routes";

interface Props {
 // TODO: correct type for this
 component: any;
 isAuthenticated?: boolean;
 exact?: boolean;
 path?: string;
}

const UnauthRoute = ({
 component: Component,
 isAuthenticated,
 ...rest
}: Props) => (
 <>
  <Route
   {...rest}
   render={(props) =>
    !isAuthenticated ? (
     <Route render={() => <Component {...props} />} />
    ) : (
     <Redirect to={PAGES_URL.DASHBOARD_HOME} />
    )
   }
  />
 </>
);

const mapStatetoProps = (state: any) => ({
 isAuthenticated: state.auth.isAuthenticated,
 auth: state.auth.session,
});

export default connect(mapStatetoProps, null)(UnauthRoute);
