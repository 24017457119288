/** @format */
import React from "react";
import { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import AppComponent from "@components/AppComponent";
import { Banner, Hero } from "@assets/images";
import pic1 from "@assets/images/persons/team_images/pic1.jpg";
import pic2 from "@assets/images/persons/team_images/pic2.jpg";
import pic3 from "@assets/images/persons/team_images/pic3.jpg";
import pic4 from "@assets/images/persons/team_images/pic4.jpg";
import pic5 from "@assets/images/persons/team_images/pic3.jpg";
import cardBg from "@assets/images/teamStyle.svg";

import { BsFacebook, BsTwitterX, BsLinkedin } from "react-icons/bs";

import { Link } from "react-router-dom";
import PAGES_URL from "@routers/routes";

const boxVariant = {
 visible: { scale: 1, transition: { duration: 0.5 } },
 hidden: { scale: 0 },
};

const Team = () => {
 const control = useAnimation();
 const [ref, inView] = useInView();

 useEffect(() => {
  if (inView) {
   console.log(control);
   control.start("visible");
  } else {
   control.stop();
  }
 }, [control, inView]);

 return (
  <AppComponent>
   <section className="bg-primary position-relative py-5">
    <div className="container dark-mode position-relative zindex-2 py-5 mb-4 mb-sm-5">
     <div className="row pb-5 mb-2 mb-sm-0 mb-lg-3">
      <div className="col-lg-10 col-xl-9">
       <nav aria-label="breadcrumb ">
        <ol className="pt-lg-3 pb-lg-4 pb-2 breadcrumb">
         <li className="breadcrumb-item">
          <Link to={PAGES_URL.HOME}>Home</Link>
         </li>
         <li className="breadcrumb-item " aria-current="page">
          Our Team
         </li>
        </ol>
       </nav>
       <h1 className="display-2 pb-2 pb-sm-3">Meet Our Founders</h1>
      </div>
     </div>
    </div>
   </section>

   <section
    className="container position-relative zindex-3"
    style={{ marginTop: "-135px" }}
   >
    <motion.div
     ref={ref}
     variants={boxVariant}
     initial="visible"
     animate={control}
     className="founder-block row rounded border"
    >
     <div className="curve1"></div>
     <div className="text-card col-md col-12 order-2 order-md-1">
      <div className=" text-center py-3 ">
       <h1 className="">Giovanni DAPRÀ</h1>
       <p className="">
        <strong>Co-Founder and Chief Executive Officer</strong>
       </p>

       <p className="">
        Giovanni believes in the idea that a better customer experience comes
        from technology and finance working together. He co-founded Senga with
        the aim of delivering the best investment solutions to help protect
       </p>
       <div className="team-social">
        <a className="btn btn-square btn-primary rounded-circle" href="">
         <BsFacebook />
        </a>
        <a className="btn btn-square btn-primary rounded-circle" href="">
         <BsTwitterX />
        </a>
        <a className="btn btn-square btn-primary rounded-circle" href="">
         <BsLinkedin />
        </a>
       </div>
      </div>
     </div>

     <div className="curve2"></div>

     <div
      className=" col-md team-card order-1 order-md-1 wow fadeInUp col-12 "
      data-wow-delay="0.3s"
     >
      <div className="team-item text-center ">
       <img
        className="img-fluid border rounded-circle w-s w-75 p-4 mb-4"
        src={pic3}
        alt=""
       />
      </div>
     </div>
     <div className="curve3"></div>
    </motion.div>
    <motion.div
     ref={ref}
     variants={boxVariant}
     initial="hidden"
     whileInView="visible"
     animate={control}
     className=" founder-block row rounded inverted border mt-4"
    >
     <div className="curve1"></div>
     <div className="text-card col-md col-12 order-2 order-md-2">
      <div className=" text-center py-3 ">
       <h1 className="">Giovanni DAPRÀ</h1>
       <p className="">
        <strong>Co-Founder and Chief Executive Officer</strong>
       </p>

       <p className="">
        Giovanni believes in the idea that a better customer experience comes
        from technology and finance working together. He co-founded Senga with
        the aim of delivering the best investment solutions to help protect
       </p>
       <div className="team-social">
        <a className="btn btn-square btn-primary rounded-circle" href="">
         <BsFacebook />
        </a>
        <a className="btn btn-square btn-primary rounded-circle" href="">
         <BsTwitterX />
        </a>
        <a className="btn btn-square btn-primary rounded-circle" href="">
         <BsLinkedin />
        </a>
       </div>
      </div>
     </div>

     <div className="curve2"></div>

     <div
      className="col-md team-card order-1 order-md-1 wow fadeInUp col-12"
      data-wow-delay="0.3s"
     >
      <div className="team-item text-center ">
       <img
        className="img-fluid border rounded-circle w-s w-75 p-4 mb-4"
        src={pic2}
        alt=""
       />
      </div>
     </div>
     <div className="curve3"></div>
    </motion.div>
    <motion.div
     ref={ref}
     variants={boxVariant}
     initial="hidden"
     animate={control}
     className=" founder-block row rounded border mt-4"
    >
     <div className="curve1"></div>
     <div className="text-card col-md col-12 order-2 order-md-1">
      <div className=" text-center py-3 ">
       <h1 className="">Giovanni DAPRÀ</h1>
       <p className="">
        <strong>Co-Founder and Chief Executive Officer</strong>
       </p>

       <p className="">
        Giovanni believes in the idea that a better customer experience comes
        from technology and finance working together. He co-founded Senga with
        the aim of delivering the best investment solutions to help protect
       </p>
       <div className="team-social">
        <a className="btn btn-square btn-primary rounded-circle" href="">
         <BsFacebook />
        </a>
        <a className="btn btn-square btn-primary rounded-circle" href="">
         <BsTwitterX />
        </a>
        <a className="btn btn-square btn-primary rounded-circle" href="">
         <BsLinkedin />
        </a>
       </div>
      </div>
     </div>

     <div className="curve2"></div>

     <div
      className="col-md team-card order-1 order-md-2 wow fadeInUp col-12"
      data-wow-delay="0.3s"
     >
      <div className="team-item text-center ">
       <img
        className="img-fluid border rounded-circle w-s w-75 p-4 mb-4"
        src={pic3}
        alt=""
       />
      </div>
     </div>
     <div className="curve3"></div>
    </motion.div>
   </section>
  </AppComponent>
 );
};

export default Team;
