/** @format */

import PAGES_URL from "@routers/routes";
import React, { ReactNode } from "react";
import { Link } from "react-router-dom";

interface Props {
 children: ReactNode;
 maxWidth?: string;
 banner?: string;
 align?: string;
}

const AuthComponent = ({ children, banner, align, maxWidth }: Props) => {
 return (
  <div className="page-wrapper min-vh-100">
   <div className={`d-lg-flex ${align} position-relative vh-100`}>
    <Link
     className="text-nav btn btn-icon bg-light border rounded-circle position-absolute top-0 end-0 p-0 mt-3 me-3 mt-sm-4 me-sm-4"
     to={PAGES_URL.HOME}
     data-bs-toggle="tooltip"
     data-bs-placement="left"
     title="Back to home"
    >
     <i className="ai-home"></i>
    </Link>
    <div
     className={`d-flex flex-column align-items-center ${
      banner ? "w-lg-50" : "w-lg-100"
     } h-100 px-3 px-lg-5 pt-5`}
    >
     {children}
     <p
      className="w-100 fs-sm pt-5 mt-auto mb-5"
      style={{ maxWidth: maxWidth ?? "526px" }}
     >
      <span className="text-muted">&copy; All rights reserved. </span>
      <Link
       className="nav-link d-inline-block p-0 ms-1"
       to={PAGES_URL.HOME}
       target="_blank"
       rel="noopener"
      >
       Senga
      </Link>
     </p>
    </div>

    {banner && (
     <div
      className={`w-50 bg-size-cover bg-repeat-0 bg-position-center `}
      style={{ backgroundImage: `url(${banner})` }}
     ></div>
    )}
   </div>
  </div>
 );
};

export default AuthComponent;
