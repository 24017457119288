/** @format */

import React from "react";
import DashboardComponent from "@components/dashboard/DashboardComponent";
import { BsBellFill, BsCurrencyDollar, BsThreadsFill } from "react-icons/bs";

const notifications = [
 {
  id: 1,
  subject: "Your monthly renewal on Tim’s Investment is due today",
  timestamps: "",
  notification_type: {
   name: "wallet",
   icons: BsBellFill,
  },
  read: true,
 },
 {
  id: 2,
  subject:
   "New message from @Yande regarding your ticket ID #3278r838r985694458888",
  timestamps: "",
  notification_type: {
   name: "wallet",
   icons: BsThreadsFill,
  },
  read: true,
 },
 {
  id: 3,
  subject: "You have successfully deposited the sum of $5000.00 to your wallet",
  timestamps: "",
  notification_type: {
   name: "wallet",
   icons: BsCurrencyDollar,
  },
 },
 {
  id: 4,
  subject: "Your monthly renewal on Tim’s Investment is due today",
  timestamps: "",
  notification_type: {
   name: "wallet",
   icons: BsBellFill,
  },
 },
 {
  id: 5,
  subject: "Your monthly renewal on Tim’s Investment is due today",
  timestamps: "",
  notification_type: {
   name: "wallet",
   icons: BsBellFill,
  },
  read: true,
 },
 {
  id: 6,
  subject: "We have credited your bank account with $250.00",
  timestamps: "",
  notification_type: {
   name: "wallet",
   icons: BsCurrencyDollar,
  },
 },
 {
  id: 7,
  subject: "We have credited your bank account with $250.00",
  timestamps: "",
  notification_type: {
   name: "wallet",
   icons: BsCurrencyDollar,
  },
 },
 {
  id: 8,
  subject: "Your monthly renewal on Tim’s Investment is due today",
  timestamps: "",
  notification_type: {
   name: "wallet",
   icons: BsBellFill,
  },
 },
 {
  id: 9,
  subject: "Your monthly renewal on Tim’s Investment is due today",
  timestamps: "",
  notification_type: {
   name: "wallet",
   icons: BsBellFill,
  },
  read: true,
 },
];

const Notifications = () => {
 return (
  <DashboardComponent pageTitle={"NOTIFICATIONS"}>
   <section id="notification" className="section-fluid ">
    <div className="d-flex g-3 align-items-center mb-3">
     <h1 className="ls-tight">Notifications</h1>
    </div>
    <div className="card bg-transparent border-0 shadow-0">
     <div className="card-body p-0 ">
      {notifications.map((notification, idx) => (
       <div key={idx}>
        {notification.id == 4 && (
         <h6 className="fw-medium my-3  text-dark h5 px-3">Yesterday</h6>
        )}

        {notification.id == 8 && (
         <h6 className="fw-medium my-3  text-dark h5 px-3">23rd Jan, 2023</h6>
        )}
        <div
         className={`card rounded-0 border-0 bg-${
          notification.read ? "white" : "read"
         }`}
        >
         <div className="card-body">
          <div className="d-flex align-items-center">
           <div
            className={`d-inline-block bg-primary-light p-lg-3 me-3 rounded-1`}
           >
            <notification.notification_type.icons
             className="text-primary"
             size={25}
            />
           </div>
           <div className="">
            <span className="h5 mb-0 d-block text-sm fw-normal">
             {notification.subject}
            </span>
            <span className="text-dark-50">20 Oct. 2022</span>
           </div>
           {/* <Link className="ms-auto d-inline-block  p-3 me-3 rounded-1" to={""}>
          <BsArrowRight className="text-dark" size={25} />
         </Link> */}
          </div>
         </div>
         <hr />
        </div>
       </div>
      ))}
     </div>
    </div>
    <div className="btn-toolbar mt-4" role="toolbar" aria-label="Pagination">
     <div className="btn-group me-2 mb-2" role="group" aria-label="First group">
      <button type="button" className="btn btn-light">
       Prev
      </button>
      <button type="button" className="btn btn-light">
       .
      </button>
      <button type="button" className="btn btn-light">
       3
      </button>
     </div>
     <div
      className="btn-group me-2 mb-2"
      role="group"
      aria-label="Second group"
     >
      <button type="button" className="btn btn-light">
       4
      </button>
      <button type="button" className="btn btn-light">
       .
      </button>
     </div>
     <div className="btn-group mb-2" role="group" aria-label="Third group">
      <button type="button" className="btn btn-light">
       Next
      </button>
     </div>
    </div>
   </section>
  </DashboardComponent>
 );
};

export default Notifications;
