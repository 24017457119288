/** @format */

import React from "react";
import { Link } from "react-router-dom";
import { iPhone13, Android, iOS, Banner01, iPhone14 } from "@assets/images";
import AppComponent from "@components/AppComponent";
import PAGES_URL from "@routers/routes";

const ContactUs = () => {
 return (
  <>
   <AppComponent>
    <section className="bg-secondary py-5">
     <div className="container pt-5 pb-lg-2 pb-xl-4 py-xxl-5">
      <nav aria-label="breadcrumb">
       <ol className="pt-lg-3 pb-lg-4 pb-2 breadcrumb">
        <li className="breadcrumb-item">
         <a href="index.html">Home</a>
        </li>
        <li className="breadcrumb-item active" aria-current="page">
         Contacts
        </li>
       </ol>
      </nav>
      <h1 className="display-2">Contacts</h1>
      <p className="fs-lg pb-4 mb-2 mb-sm-3">
       Get in touch with us by completing the below form or call us now
      </p>
      <div className="row row-cols-1 row-cols-sm-2 row-cols-xl-4 g-4 pb-2 pb-sm-4 pb-lg-5">
       <div className="col">
        <div className="card border-0 h-100">
         <div className="card-body">
          <h4 className="card-title mb-4">Address</h4>
          <p className="fs-lg fw-medium pb-3 mb-3">
           514 Magnolia Home Office, AA 32806
          </p>
          <a className="btn btn-sm btn-outline-primary" href="#">
           <i className="ai-map-pin me-1"></i>Get directions
          </a>
         </div>
        </div>
       </div>
       <div className="col">
        <div className="card border-0 h-100">
         <div className="card-body">
          <h4 className="card-title mb-4">Phone</h4>
          <ul className="list-unstyled mb-0">
           <li className="pb-1 mb-2">
            <span className="d-block fs-sm text-muted mb-1">Main office</span>
            <a className="nav-link fs-lg p-0" href="tel:+178632256033">
             +44 (786) 322 560 33
            </a>
           </li>
           <li>
            <span className="d-block fs-sm text-muted mb-1">
             Reception room
            </span>
            <a className="nav-link fs-lg p-0" href="tel:+178630056044">
             +44 (786) 300 560 44
            </a>
           </li>
          </ul>
         </div>
        </div>
       </div>
       <div className="col">
        <div className="card border-0 h-100">
         <div className="card-body">
          <h4 className="card-title mb-4">Schedule</h4>
          <ul className="list-unstyled mb-0">
           <li className="pb-1 mb-2">
            <span className="d-block fs-sm text-muted mb-1">Mon - Thu</span>
            <div className="d-flex align-items-center">
             <span className="text-nav fs-lg fw-medium">10:00</span>
             <span
              className="border-top mx-4"
              style={{ width: "36px;", height: "1px;" }}
             ></span>
             <span className="text-nav fs-lg fw-medium">22:00</span>
            </div>
           </li>
           <li>
            <span className="d-block fs-sm text-muted mb-1">Fri - Sat</span>
            <div className="d-flex align-items-center">
             <span className="text-nav fs-lg fw-medium">10:00</span>
             <span
              className="border-top mx-4"
              style={{ width: "36px;", height: "1px;" }}
             ></span>
             <span className="text-nav fs-lg fw-medium">20:00</span>
            </div>
           </li>
          </ul>
         </div>
        </div>
       </div>
       <div className="col">
        <div className="card border-0 h-100">
         <div className="card-body">
          <h4 className="card-title mb-4">Email</h4>
          <ul className="list-unstyled mb-0">
           <li className="pb-1 mb-2">
            <span className="d-block fs-sm text-muted mb-1">Main office</span>
            <a className="nav-link fs-lg p-0" href="mailto:office@example.com">
             office@example.com
            </a>
           </li>
           <li>
            <span className="d-block fs-sm text-muted mb-1">
             Reception room
            </span>
            <a
             className="nav-link fs-lg p-0"
             href="mailto:reception@example.com"
            >
             reception@example.com
            </a>
           </li>
          </ul>
         </div>
        </div>
       </div>
      </div>
     </div>
     <div style={{ height: "250px;" }}></div>
    </section>
    <section
     className="container dark-mode my-5"
     style={{ marginTop: "-260px;" }}
    >
     <div className="card border-0 bg-primary position-relative py-lg-4 py-xl-5">
      <svg
       className="position-absolute end-0 mt-n2"
       width="242"
       height="331"
       viewBox="0 0 242 331"
       fill="none"
       xmlns="http://www.w3.org/2000/svg"
      >
       <path
        d="M172.014 250.127C137.236 227.74 112.349 192.144 144.586 153.591C157.686 137.932 176.759 127.973 196.524 122.046C234.647 110.639 277.027 113.361 313.349 129.576C338.19 140.666 361.129 159.183 369.934 184.502C383.476 223.496 359.75 260.161 321.569 273.118C288.832 284.223 247.685 279.513 214.885 269.837C201.003 265.743 185.745 258.966 172.014 250.127Z"
        fill="#121519"
        fill-opacity=".07"
       ></path>
       <path
        d="M20.3265 69.264C19.7064 43.0736 29.8071 17.1878 62.3851 19.8622C75.6229 20.9505 87.9525 27.2066 98.3563 35.3132C118.426 50.9253 132.424 73.896 136.952 98.6413C140.044 115.562 138.424 134.218 127.978 148C111.901 169.236 83.4531 170.283 62.5246 155.209C44.5807 142.281 32.0983 119.217 25.3391 98.6799C22.4836 89.9885 20.5616 79.6021 20.3265 69.264Z"
        fill="#121519"
        fill-opacity=".07"
       ></path>
      </svg>
      <svg
       className="position-absolute start-0 bottom-0 ms-3"
       width="169"
       height="217"
       viewBox="0 0 169 217"
       fill="none"
       xmlns="http://www.w3.org/2000/svg"
      >
       <path
        d="M34.2574 90.0177C29.666 97.6253 26.6254 106.591 24.9502 114.96C19.9522 140.043 26.4112 168.792 49.6162 181.885C66.0705 191.17 91.0017 189.904 108.062 183.692C125.725 177.266 135.045 168.04 142.29 150.389C151.409 128.174 150.912 99.6904 125.93 91.6429C115.423 88.254 104.723 86.5065 94.2249 82.5889C84.6622 79.0248 74.8545 72.1766 64.4411 71.6149C50.8011 70.8777 40.9122 79.0146 34.2574 90.0177Z"
        fill="#121519"
        fill-opacity="0.07"
       ></path>
       <path
        d="M147.005 75.6331C152.135 70.7783 156.106 64.2374 159.153 57.9073C166.014 43.6372 174.127 22.1368 160.207 9.68505C152.924 3.17188 139.243 3.86644 130.324 5.29774C118.428 7.20428 107.295 8.85077 96.5031 14.783C85.8056 20.6599 79.0155 33.6997 77.0014 45.6686C75.4978 54.5776 79.63 63.6672 84.7391 70.7453C91.8208 80.5571 103.503 84.2003 114.817 84.3975C121.101 84.5081 127.716 84.0527 133.89 82.8121C138.932 81.7962 143.273 79.1597 147.005 75.6331Z"
        fill="#121519"
        fill-opacity="0.07"
       ></path>
      </svg>
      <div className="card-body position-relative zindex-2 py-5">
       <form className="mx-auto" style={{ maxWidth: "800px" }}>
        <h2 className="h1 card-title text-center pb-4">
         Get a free consultation
        </h2>
        <div className="row g-4">
         <div className="col-sm-6">
          <label className="form-label fs-base" htmlFor="name">
           Name
          </label>
          <input
           className="form-control form-control-lg"
           type="text"
           placeholder="Your name"
           required
           id="name"
          />
         </div>
         <div className="col-sm-6">
          <label className="form-label fs-base" htmlFor="company">
           Company
          </label>
          <input
           className="form-control form-control-lg"
           type="text"
           placeholder="Your company name"
           id="company"
          />
         </div>
         <div className="col-sm-6">
          <label className="form-label fs-base" htmlFor="email">
           Email
          </label>
          <input
           className="form-control form-control-lg"
           type="email"
           placeholder="Email address"
           required
           id="email"
          />
         </div>
         <div className="col-sm-6">
          <label className="form-label fs-base" htmlFor="phone">
           Phone
          </label>
          <input
           className="form-control form-control-lg"
           type="text"
           placeholder="Phone number"
           id="phone"
          />
         </div>
         <div className="col-sm-12">
          <label className="form-label fs-base" htmlFor="message">
           How can we help?
          </label>
          <textarea
           className="form-control form-control-lg"
           rows={6}
           placeholder="Enter your message here..."
           required
           id="message"
          ></textarea>
         </div>
         <div className="col-sm-12">
          <div className="form-check form-check-inline">
           <input className="form-check-input" type="checkbox" id="seo" />
           <label className="form-check-label fs-base" htmlFor="seo">
            SEO Website Audit
           </label>
          </div>
          <div className="form-check form-check-inline">
           <input
            className="form-check-input"
            type="checkbox"
            id="email-marketing"
            checked
           />
           <label
            className="form-check-label fs-base"
            htmlFor="email-marketing"
           >
            Email Marketing
           </label>
          </div>
          <div className="form-check form-check-inline">
           <input className="form-check-input" type="checkbox" id="social" />
           <label className="form-check-label fs-base" htmlFor="social">
            Social Networks
           </label>
          </div>
          <div className="form-check form-check-inline">
           <input
            className="form-check-input"
            type="checkbox"
            id="content-marketing"
           />
           <label
            className="form-check-label fs-base"
            htmlFor="content-marketing"
           >
            Content Marketing
           </label>
          </div>
         </div>
         <div className="col-sm-12 text-center pt-4">
          <button className="btn btn-lg btn-light" type="submit">
           Send a request
          </button>
         </div>
        </div>
       </form>
      </div>
     </div>
    </section>
   </AppComponent>
  </>
 );
};

export default ContactUs;
