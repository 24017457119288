/** @format */

import React, { useState } from "react";
import { connect } from "react-redux";
import AuthComponent from "@components/AuthComponent";
import { loginAction } from "@redux/actions/authentication";
import { LoginValidator } from "../../helpers/Validator";
import { Banner } from "@assets/images";
import PAGES_URL from "@routers/routes";
import { Link } from "react-router-dom";
import { InputField, InputPasswordField } from "@components/Inputs";
import Button from "@components/Buttons";
import { AlertResponseComponent } from "@components/AlertComponenr";

type User = {
 email?: string;
 password?: string;
};

type Props = {
 login: typeof loginAction;
 isLoading: boolean;
 response: string;
};

const Login = ({ login, isLoading, response }: Props) => {
 const [state, setState] = useState({} as User);
 const [error, setError] = useState({} as User);

 const handleInputChange = (e: any) => {
  setState((prev) => ({ ...prev, [e.target.name]: e.target.value }));
 };

 const handleSubmit = (e: any) => {
  e.preventDefault();
  const errors = LoginValidator(state);
  if (Object.keys(errors).length !== 0) {
   setError(errors);
   return;
  }
  login(state);
  setError({});
 };

 return (
  <>
   <AuthComponent banner={Banner.SignIn}>
    <div className="w-100 mt-auto" style={{ maxWidth: "526px" }}>
     {response && <AlertResponseComponent context={response} />}

     <h1>Sign in to Senga</h1>
     <p className="pb-3 mb-3 mb-lg-4">
      Don't have an account yet?&nbsp;&nbsp;
      <Link to={PAGES_URL.SIGNUP}>Register here!</Link>
     </p>
     <form className="needs-validation">
      <div className="pb-3 mb-3">
       <InputField
        className="ps-5"
        errorMessage={error.email}
        name="email"
        icon="ai-mail fs-lg"
        placeholder="Email address"
        onChange={handleInputChange}
       />
      </div>
      <div className="mb-4">
       <InputPasswordField
        className="ps-5"
        errorMessage={error.password}
        icon={true}
        name="password"
        onChange={handleInputChange}
       />
      </div>
      <div className="d-flex flex-wrap align-items-center justify-content-between pb-4">
       <div className="my-1">
        <input
         className="form-check-input"
         type="checkbox"
         id="keep-signedin"
        />
        <label className="form-check-label ms-1" htmlFor="keep-signedin">
         Keep me signed in
        </label>
       </div>
       <Link
        className="fs-sm fw-semibold text-decoration-none my-1"
        to={PAGES_URL.FORGOT_PASSWORD}
       >
        Forgot password?
       </Link>
      </div>
      <Button
       className="btn-lg btn-primary w-100"
       loading={isLoading}
       onClick={handleSubmit}
      >
       Sign In
      </Button>
     </form>
    </div>
   </AuthComponent>
  </>
 );
};

const mapStateToProps = (state: any) => ({
 user: state.user,
 isLoading: state.auth.isLoading,
 response: state.auth.response,
});

const mapDispatchToProps = (dispatch: any) => ({
 login: (data: any) => dispatch(loginAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
