/** @format */
import React from "react";
import { Teams } from "@assets/images";

interface AvatarProps extends React.InputHTMLAttributes<HTMLInputElement> {
 label?: string;
 className?: string;
}
export const Avatar = ({ className, ...props }: AvatarProps) => {
 const handleFileUpload = () => {
  React.createElement("label", { htmlFor: "myfile" }, "Select File");
 };
 return (
  <div className="d-flex align-items-center">
   <div className="dropdown">
    <a
     className="d-flex flex-column justify-content-end position-relative overflow-hidden rounded-circle bg-size-cover bg-position-center flex-shrink-0"
     href="#"
     data-bs-toggle="dropdown"
     aria-expanded="false"
     style={{
      width: "80px",
      height: "80px",
      backgroundImage: `url(${props.value})`,
     }}
    >
     <span
      className="d-block text-light text-center lh-1 pb-1"
      style={{ backgroundColor: "rgba(0,0,0,.5)" }}
     >
      <i className="ai-camera"></i>
     </span>
    </a>
    <div className="dropdown-menu my-1">
     <label className="dropdown-item fw-normal" htmlFor={props.id}>
      <input
       type="file"
       className={className}
       accept="image/*"
       style={{ display: "none" }}
       {...props}
      />
      <i className="ai-camera fs-base opacity-70 me-2"></i>Upload new photo
     </label>
     <a className="dropdown-item text-danger fw-normal" href="#">
      <i className="ai-trash fs-base me-2"></i>Delete photo
     </a>
    </div>
   </div>
   <div className="ps-3">
    <h3 className="h6 mb-1">Profile picture</h3>
    <p className="fs-sm text-muted mb-0">
     PNG or JPG no bigger than 1000px wide and tall.
    </p>
   </div>
  </div>
 );
};
