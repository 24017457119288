/** @format */

import { SET_COUNTRY_DATA, SET_GENDER_DATA } from "@redux/reducers/types";

const initialState = {
 countries: {},
 genders: {},
};

export default function globalReducer(state = initialState, action = {}) {
 switch (action.type) {
  case SET_GENDER_DATA:
   return { ...state, genders: action.payload };
  case SET_COUNTRY_DATA:
   return { ...state, countries: action.payload };
  default:
   return state;
 }
}
