/** @format */

import REGISTER from "./register.jpeg";
import CHOOSE from "./choose.jpeg";
import TRACK from "./Track.jpeg";
import ALL from "./All.png";

const HowItWorks = { REGISTER, CHOOSE, TRACK, ALL };

export default HowItWorks;
