/** @format */

import React from "react";
import { Link } from "react-router-dom";

const ToggleAttributes = (status?: any) => {
 const response = { icon: "", brand: "success" };
 if (status === 200) {
  response.icon = "bell";
  response.brand = "success";
 }

 if (status === 400 || status === 401 || status === 500) {
  response.icon = "octagon-alert";
  response.brand = "danger";
 }

 return response;
};

const AlertResponseComponent = ({ context }: any) => {
 console.log(context.message);
 const gt = ToggleAttributes(context.status);
 return (
  <div className={`alert alert-${gt.brand} d-flex mb-0" role="alert`}>
   <i className={`ai-${gt.icon} fs-xl`}></i>
   <div className="ps-2">{context.message}</div>
   {context.link && (
    <Link className="alert-link ms-1" to={context.link.url}>
     {context.link.name}
    </Link>
   )}
  </div>
 );
};

export { AlertResponseComponent };
