/** @format */

import React, { ReactNode } from "react";
import { Link, NavLink } from "react-router-dom";

//TODO: Implement correct npm-types for icon,
interface Props {
 children: ReactNode;
 exact: boolean;
 Icon: any;
 to: string;
 className?: string;
}

const SidebarLink = ({ children, className, exact, Icon, ...props }: Props) => {
 return (
  <li className={` block  ${className}`}>
   <Link className=" row " {...props}>
    <Icon className="col-6" />
    <span className="col-6 d-inline-block">{children}</span>{" "}
    {/* <span className="col badge badge-sm rounded-pill text-success ms-auto"></span> */}
   </Link>
  </li>
 );
};

export default SidebarLink;
