/** @format */

import React from "react";
import DashboardComponent from "@components/dashboard/DashboardComponent";
import ProfileHeader from "@components/dashboard/ProfileHeader";
import { Form } from "react-bootstrap";
import Button from "../../../components/Buttons";

const NotificationSettings = () => {
 return (
  <DashboardComponent pageTitle={"MY ACCOUNT"}>
   <ProfileHeader title={"Notification Settings"} />
   <section className="h-100">
    <div className=" d-flex h-100 flex-column mx-auto w-lg-75 ">
     <div className="d-flex align-items-center">
      <div className="">
       <h6>Disable Email Notification</h6>
       <p className="fw-light text-dark-50">
        Disable the platform from sending notification through mail
       </p>
      </div>
      <Form className="ms-auto">
       <Form.Check
        className="text-primary w-50"
        type="switch"
        id="custom-switch"
        checked
       />
      </Form>
     </div>
     <Button className={"btn-primary btn__lg ms-auto mt-auto"}>
      Update Settings
     </Button>
    </div>
   </section>
  </DashboardComponent>
 );
};

export default NotificationSettings;
