/** @format */

import React from "react";
import AppComponent from "@components/AppComponent";
import { AppstoreLight, PlaystoreLight } from "@assets/images";

const Customers = () => {
 return (
  <AppComponent>
   <section className="overflow-hidden pt-5 mt-lg-3 mt-xl-4 mt-xxl-5">
    <div className="container pt-3 py-sm-4 pt-lg-5 mt-md-3 mt-lg-0">
     <div className="row align-items-center">
      <div className="col-md-5 order-md-2 pb-2 pb-sm-0 mb-4 mb-sm-5 mb-md-0">
       <div className="parallax mx-auto" style={{ maxWidth: "560px" }}>
        <div className="parallax-layer" data-depth="0.1">
         <img
          src="https://around.createx.studio/assets/img/landing/mobile-app-showcase/people/01.png"
          alt="Layer"
         />
        </div>
        <div className="parallax-layer" data-depth="-0.05">
         <img
          src="https://around.createx.studio/assets/img/landing/mobile-app-showcase/people/02.png"
          alt="Layer"
         />
        </div>
        <div className="parallax-layer" data-depth="0.2">
         <img
          src="https://around.createx.studio/assets/img/landing/mobile-app-showcase/people/03.png"
          alt="Layer"
         />
        </div>
        <div className="parallax-layer" data-depth="-0.15">
         <img
          src="https://around.createx.studio/assets/img/landing/mobile-app-showcase/people/04.png"
          alt="Layer"
         />
        </div>
        <div className="parallax-layer" data-depth="0.25">
         <img
          src="https://around.createx.studio/assets/img/landing/mobile-app-showcase/people/05.png"
          alt="Layer"
         />
        </div>
        <div className="parallax-layer" data-depth="0.35">
         <img
          src="https://around.createx.studio/assets/img/landing/mobile-app-showcase/people/06.png"
          alt="Layer"
         />
        </div>
       </div>
      </div>
      <div className="col-md-6 order-md-1">
       <span className="badge bg-light text-primary fs-sm mb-3">
        Take your investment to the next level&nbsp; 🚀
       </span>
       <h2 className="display-4 text-center text-md-start mb-4">
        Open finance tools for businesses of all sizes
       </h2>
       <p className="fs-lg">
        Discover how Sanga customers use open finance tools to digitize and
        launch financial services.
       </p>
       <div className="d-flex mt-2">
        <a
         className="btn  btn-primary w-100 w-sm-auto"
         href="https://calendly.com"
        >
         Book a demo
        </a>
       </div>
       <div className="d-flex justify-content-center justify-content-md-start"></div>
      </div>
     </div>
    </div>
   </section>
   <section className="bg-light py-5 mt-lg-3 mt-xl-4 mt-xxl-5">
    <div className="container">
     <div className="row justify-content-between pt-2 pt-sm-3 pt-md-4 pt-lg-5 pb-xxl-2 mt-md-3 mt-lg-0">
      <div className="col-lg-6 order-2 mb-5 mb-lg-0">
       <div className=" text-center text-lg-start pb-md-2 pb-xl-0 mb-4 mb-xl-5">
        <h1 className="h2 text-gray">
         Helping Sanga customers build the impossible
        </h1>
        <p className="text-gray fs-lg">
         At Sanga, we believe in the potential of our customers to achieve
         remarkable feats. Our mission goes beyond conventional investment
         platforms. We're here to provide you with the tools, insights, and
         opportunities that can turn your dreams into reality.
        </p>
       </div>

       <div className="d-flex justify-content-center justify-content-lg-start">
        <a className="btn btn-dark btn-lg px-3 py-2 me-3" href="#">
         <img
          className="d-dark-mode-none mx-1"
          src={AppstoreLight}
          width="136"
          alt="App Store"
         />
        </a>
        <a className="btn btn-dark btn-lg px-3 py-2" href="#">
         <img
          className="d-dark-mode-none mx-1"
          src={PlaystoreLight}
          width="135"
          alt="Google Play"
         />
        </a>
       </div>
      </div>
      <div className="col-lg-6 order-1  ">
       <div className="row row-cols-1 row-cols-sm-2 g-3 g-md-4">
        <div className="col">
         <div className="d-flex flex-column h-100 bg-faded-primary rounded-3 text-center p-4">
          <div className="h3 display-5 mt-1 mb-0">1,000+</div>
          <div className="fs-lg mb-1">Downloads per day</div>
         </div>
        </div>
        <div className="col">
         <div className="d-flex flex-column h-100 bg-faded-info rounded-3 text-center p-4">
          <div className="h3 display-5 mt-1 mb-0">1 Million</div>
          <div className="fs-lg mb-1">Users per month</div>
         </div>
        </div>
        <div className="col">
         <div className="d-flex flex-column h-100 bg-faded-warning rounded-3 text-center p-4">
          <div className="h3 display-5 mt-1 mb-0">300+</div>
          <div className="fs-lg mb-1">Courses</div>
         </div>
        </div>
        <div className="col">
         <div className="d-flex flex-column h-100 bg-faded-danger rounded-3 text-center p-4">
          <div className="h3 display-5 mt-1 mb-0">134</div>
          <div className="fs-lg mb-1">Countries</div>
         </div>
        </div>
       </div>
      </div>
     </div>
    </div>
   </section>

   <section className="container mt-n3 mt-sm-n2 pb-5 mb-md-2 mb-lg-3 mb-xl-4 mb-xxl-5">
    <h2 className="h1 text-center pb-3 pb-lg-4">Testimonials</h2>
    <div
     className="swiper pb-1 pb-md-2 pb-lg-3 pb-xl-4"
     data-swiper-options='{
      "spaceBetween": 24,
      "loop": true,
      "autoHeight": true,
      "pagination": {
        "el": ".swiper-pagination",
        "clickable": true
      },
      "breakpoints": {
        "576": { "slidesPerView": 2 },
        "992": { "slidesPerView": 3 }
      }
    }'
    >
     <div className="swiper-wrapper">
      <div className="swiper-slide">
       <div className="card border-0 mb-4">
        <div className="card-body">
         <div className="d-flex align-items-center mb-3">
          <img
           className="rounded-circle"
           src="assets/img/avatar/22.jpg"
           width="60"
           alt="Jane Cooper"
          />
          <div className="ps-3">
           <div className="h6 mb-1">Jane Cooper</div>
           <div className="fs-sm text-muted">Medical Assistant</div>
          </div>
         </div>
         <p className="card-text">
          Sit risus metus, vel neque eu lectus duis. Vulputate facilisi at
          feugiat mi aenean nunc enim faucibus arcu. Diam id accumsan sit.
         </p>
        </div>
       </div>
       <div className="card border-0">
        <div className="card-body">
         <div className="d-flex align-items-center mb-3">
          <img
           className="rounded-circle"
           src="assets/img/avatar/25.jpg"
           width="60"
           alt="Cameron Williamson"
          />
          <div className="ps-3">
           <div className="h6 mb-1">Cameron Williamson</div>
           <div className="fs-sm text-muted">Marketing Coordinator</div>
          </div>
         </div>
         <p className="card-text">
          Nam convallis maecenas leo sapien interdum id. Facilisi dictum lacinia
          in phasellus. Nullam id sed tempor, volutpat blandit urna sagittis,
          commodo vestibulum. Adipiscing dictumst nunc enim massa lacus
          lobortis.
         </p>
        </div>
       </div>
      </div>
      <div className="swiper-slide">
       <div className="card border-0 mb-4">
        <div className="card-body">
         <div className="d-flex align-items-center mb-3">
          <img
           className="rounded-circle"
           src="assets/img/avatar/23.jpg"
           width="60"
           alt="Wade Warren"
          />
          <div className="ps-3">
           <div className="h6 mb-1">Wade Warren</div>
           <div className="fs-sm text-muted">President of Sales</div>
          </div>
         </div>
         <p className="card-text">
          Vitae tempor morbi tellus pulvinar. Ut iaculis sit tristique in turpis
          volutpat quam nec. Pretium eu nulla egestas ultrices. Donec in
          pulvinar ut fermentum a, nunc, aliquam. Integer dui dui ornare sed
          lacinia.
         </p>
        </div>
       </div>
       <div className="card border-0">
        <div className="card-body">
         <div className="d-flex align-items-center mb-3">
          <img
           className="rounded-circle"
           src="assets/img/avatar/26.jpg"
           width="60"
           alt="Leslie Alexander"
          />
          <div className="ps-3">
           <div className="h6 mb-1">Leslie Alexander</div>
           <div className="fs-sm text-muted">CEO, Divi</div>
          </div>
         </div>
         <p className="card-text">
          Malesuada at ullamcorper adipiscing lobortis vestibulum. Aliquet
          lobortis justo arcu ut auctor porttitor amet, tortor. Justo.
         </p>
        </div>
       </div>
      </div>
      <div className="swiper-slide">
       <div className="card border-0 mb-4">
        <div className="card-body">
         <div className="d-flex align-items-center mb-3">
          <img
           className="rounded-circle"
           src="assets/img/avatar/24.jpg"
           width="60"
           alt="Esther Howard"
          />
          <div className="ps-3">
           <div className="h6 mb-1">Esther Howard</div>
           <div className="fs-sm text-muted">CEO, Slack</div>
          </div>
         </div>
         <p className="card-text">
          Ante turpis leo dictum adipiscing nisl magnis elementum eu. Integer
          consequat sed ipsum massa egestas integer proin id.
         </p>
        </div>
       </div>
       <div className="card border-0">
        <div className="card-body">
         <div className="d-flex align-items-center mb-3">
          <img
           className="rounded-circle"
           src="assets/img/avatar/27.jpg"
           width="60"
           alt="Brooklyn Simmons"
          />
          <div className="ps-3">
           <div className="h6 mb-1">Brooklyn Simmons</div>
           <div className="fs-sm text-muted">Marketing Coordinator</div>
          </div>
         </div>
         <p className="card-text">
          In quisque dolor, aliquam faucibus. Gravida diam ornare egestas proin
          quis odio suspendisse pellentesque. Condimentum risus, rutrum
          curabitur faucibus mi a turpis morbi. Nulla euismod dolor quis amet
          sed.
         </p>
        </div>
       </div>
      </div>
      <div className="swiper-slide">
       <div className="card border-0 mb-4">
        <div className="card-body">
         <div className="d-flex align-items-center mb-3">
          <img
           className="rounded-circle"
           src="assets/img/avatar/28.jpg"
           width="60"
           alt="Fannie Summers"
          />
          <div className="ps-3">
           <div className="h6 mb-1">Fannie Summers </div>
           <div className="fs-sm text-muted">VP of Sales</div>
          </div>
         </div>
         <p className="card-text">
          In et dui amet amet elementum urna, dictum. Eget leo ornare bibendum a
          fermentum. Rutrum pellentesque ipsum cras purus ac a ullamcorper. Elit
          ac amet quam tellus euismod ac aliquam pharetra lectus.
         </p>
        </div>
       </div>
       <div className="card border-0">
        <div className="card-body">
         <div className="d-flex align-items-center mb-3">
          <img
           className="rounded-circle"
           src="assets/img/avatar/29.jpg"
           width="60"
           alt="Robert Fox"
          />
          <div className="ps-3">
           <div className="h6 mb-1">Robert Fox</div>
           <div className="fs-sm text-muted">Marketing Coordinator</div>
          </div>
         </div>
         <p className="card-text">
          At felis non consequat neque in ultrices tortor purus dui. At sed
          fermentum, egestas amet et donec euismod risus. Nulla mauris.
         </p>
        </div>
       </div>
      </div>
     </div>
     <div className="swiper-pagination position-relative bottom-0 mt-2 mt-md-3 mt-lg-4 pt-4"></div>
    </div>
   </section>

   <section className="container">
    <div className="card bg-primary overflow-hidden py-2 py-sm-3 py-md-4 mb-sm-2 mb-md-4">
     <div className="bg-dark position-absolute top-0 start-0 w-100 h-100 opacity-5"></div>
     <div
      className="position-absolute top-0 start-0 w-100 h-100 opacity-15"
      style={{
       backgroundImage: "url(assets/img/landing/conference/noise.png)",
       mixBlendMode: "soft-light",
      }}
     ></div>
     <div className="card-body position-relative d-sm-flex align-items-center justify-content-between zindex-2 text-center">
      <div className="d-md-flex align-items-center pe-md-4">
       <div className="d-none d-md-block text-warning flex-shrink-0 me-3">
        <svg
         width="24"
         height="24"
         viewBox="0 0 24 24"
         fill="currentColor"
         xmlns="http://www.w3.org/2000/svg"
        >
         <path d="M12 0L12.6798 4.12733C13.2879 7.81883 16.1812 10.7121 19.8727 11.3202L24 12L19.8727 12.6798C16.1812 13.2879 13.2879 16.1812 12.6798 19.8727L12 24L11.3202 19.8727C10.7121 16.1812 7.81882 13.2879 4.12733 12.6798L0 12L4.12733 11.3202C7.81883 10.7121 10.7121 7.81882 11.3202 4.12733L12 0Z"></path>
        </svg>
       </div>
       <h2 className="text-light pb-1 pb-md-0 mb-md-0">
        Do you have an experience you want to share?
       </h2>
      </div>
      <a className="btn btn-lg btn-light me-xl-4" href="#">
       Join our team
      </a>
     </div>
    </div>
   </section>
  </AppComponent>
 );
};

export default Customers;
