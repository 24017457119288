/** @format */

import React, { ReactNode, useState } from "react";
import { Link } from "react-router-dom";
import PAGES_URL from "@routers/routes";
import Sidebar from "./Sidebar";
import { connect } from "react-redux";
import ProfileHeader from "./ProfileHeader";
import { BsChatText, BsChatTextFill, BsHeadset } from "react-icons/bs";

interface Props {
 pageTitle: string;
 children: ReactNode;
 profileHeader?: undefined | false;
}

const DashboardComponent = ({ children, pageTitle, profileHeader }: Props) => {
 const [open, setOpen] = useState(false);
 return (
  <>
   <div id="dashboard" className="bg-lighter">
    <div className="row g-0 min-vh-100">
     <aside className="bg-light col-lg-2 px-lg-5 pe-xl-5 mt-n3">
      <Sidebar open={open} setOpen={() => setOpen(!open)} />
     </aside>

     <div className="px-2 col-lg-10 col-12">
      <ProfileHeader title={pageTitle} />

      <div className="row placeabove g-0 justify-content-center overflow-hidden">
       <div className="col-lg-11 ">
        <section className="container-fluid ">{children}</section>
       </div>
      </div>
     </div>
    </div>
   </div>
   <a className="btn-scroll-top" href="#top" data-scroll>
    <svg
     viewBox="0 0 40 40"
     fill="currentColor"
     xmlns="http://www.w3.org/2000/svg"
    >
     <circle
      cx="20"
      cy="20"
      r="19"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
     ></circle>
    </svg>
    <i className="ai-arrow-up"></i>
   </a>
   <div className="d-none d-lg-block support-action">
    <a href="" className="btn btn-primary btn__lg rounded-5">
     <BsChatTextFill size={30} />
    </a>
   </div>
  </>
 );
};

const mapStateToProps = (state: any) => ({
 user: state.user,
});

export default connect(mapStateToProps, null)(DashboardComponent);
