/** @format */
import React from "react";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { BsFillInfoCircleFill } from "react-icons/bs";

type Props = {
 options?: any;
 seriesData?: any;
};

const EarningChart: React.FC<Props> = () => {
 const options: ApexOptions = {
  chart: {
   height: 350,
   type: "bar",
  },
  plotOptions: {
   bar: {
    columnWidth: "30%",
    borderRadius: 3,
   },
  },
  stroke: {
   curve: "straight",
   colors: ["#5F236E"],
  },
  dataLabels: {
   enabled: false,
  },
  labels: [
   "Jan",
   "Feb",
   "Mar",
   "Apr",
   "May",
   "Jun",
   "Jul",
   "Aug",
   "Sept",
   "Oct",
   "Nov",
   "Dec",
  ],
  fill: {
   colors: ["#5f236e"],
  },

  markers: {
   size: [600, 100, 8900, 2000, 4500, 2000, 3000, 542, 900, 10000, 345, 12000],
   strokeWidth: 4,
   strokeColors: "#000",
   colors: "#000",
  },
  legend: {
   show: true,
   showForSingleSeries: true,
   customLegendItems: ["Monthly Income", "Expected Goals"],
   markers: {
    fillColors: ["#5F236E", "#775DD0"],
   },
  },

  series: [
   {
    name: "Earnings",
    type: "bar",
    data: [
     450, 1000, 40, 10943, 2745, 2345, 2656, 2767, 12876, 2567, 2889, 2877,
    ],
    group: "d",
   },
  ],
 };

 return (
  <div className="card mb-5  border-0">
   <div className="card-body">
    <div id="chart">
     <ReactApexChart
      options={options}
      series={options.series}
      type="bar"
      height={350}
     />
    </div>
   </div>
  </div>
 );
};

export default EarningChart;
