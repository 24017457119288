/** @format */

import React from "react";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import PAGES_URL from "@routers/routes";
import SidebarLink from "./SidebarLink";
import { Logo, Persons, Teams } from "@assets/images";
import {
 BsBarChart,
 BsBell,
 BsChatSquare,
 BsCreditCard2Back,
 BsInfoCircle,
 BsLink45Deg,
 BsPersonSquare,
 BsPower,
 BsStack,
} from "react-icons/bs";
import { logOutAction } from "@redux/actions/authentication";

// import { avatar } from "../../assets/images/images";
// import { logout } from "../../redux/actions";

interface Props {
 open: boolean;
 session?: {
  first_name?: string;
  last_name?: string;
  email?: string;
 };
 logoutUser: typeof logOutAction;

 // TODO: Set correct type for this
 setOpen: any;
}

const Sidebar = ({ open, setOpen, session, logoutUser }: Props) => {
 const { pathname } = useLocation();

 return (
  <div className="sidebar top-0 bg-light">
   <div className="offcanvas-lg offcanvas-start" id="sidebarAccount">
    <button
     className="btn-close position-absolute top-0 end-0 mt-3 me-3 d-lg-none"
     type="button"
     data-bs-dismiss="offcanvas"
     data-bs-target="#sidebarAccount"
    ></button>
    <div className="offcanvas-body vh-100">
     <div className="d-flex flex-column h-100">
      <div className=" align-self-center mt-5">
       <img
        className=" d-lg-block d-sm-none mb-3"
        src={Logo}
        width={120}
        alt=""
       />
      </div>
      <ul className="navbar-nav d-flex flex-column">
       <SidebarLink
        className="sidebar-link my-4 h5"
        to={PAGES_URL.WALLET}
        Icon={BsBarChart}
        exact
       >
        Overview
       </SidebarLink>
       <SidebarLink
        className="my-4 h5"
        to={PAGES_URL.NOTIFICATIONS}
        Icon={BsBell}
        exact
       >
        Notifications
       </SidebarLink>
       <SidebarLink
        className="my-4 h5"
        to={PAGES_URL.ACCOUNT}
        Icon={BsPersonSquare}
        exact
       >
        Account
       </SidebarLink>
       <SidebarLink
        className="my-4 h5"
        to={PAGES_URL.BILLINGS}
        Icon={BsCreditCard2Back}
        exact
       >
        Billing
       </SidebarLink>
       <SidebarLink
        className="my-4 h5"
        to={PAGES_URL.LIST_MESSAGES}
        Icon={BsChatSquare}
        exact
       >
        Messages
       </SidebarLink>
       <SidebarLink
        className="my-4 h5"
        to={PAGES_URL.HELP}
        Icon={BsInfoCircle}
        exact
       >
        Help
       </SidebarLink>
       <li className={`nav-item my-4 h5`}>
        <a
         className="d-flex align-items-center rounded-pill pointer"
         onClick={() => logoutUser()}
        >
         <BsPower className="" size={25} />{" "}
         <span className=" fw-medium my-0 ms-3">Logout</span>{" "}
         <span className="badge badge-sm rounded-pill me-n2 bg-primary text-success ms-auto"></span>
        </a>
       </li>
      </ul>

      <div className="card mt-auto mb-5 slick-item primary d-none d-lg-block">
       <div className="card-body px-2">
        <ul className="d-flex flex-column">
         <div className="zindex-5 mb-3">
          <BsStack className="text-white " size={40} />
         </div>
         <Link
          className="text-light my-2 h6 fw-medium zindex-5"
          to={PAGES_URL.HOWITWORKS}
         >
          <BsLink45Deg /> How it works
         </Link>
         <Link className="text-light my-2 h6 fw-medium" to={PAGES_URL.TERMS}>
          <BsLink45Deg />
          Terms & Conditions
         </Link>
         <Link className="text-light my-2 h6 fw-medium" to={PAGES_URL.POLICY}>
          <BsLink45Deg /> Privacy Policy
         </Link>
        </ul>
       </div>
      </div>
     </div>
    </div>
   </div>
  </div>
 );
};

const mapStateToProps = (state: any) => ({
 auth: state.auth.user,
});

const mapDispatchToProps = (dispatch: any) => ({
 logoutUser: () => dispatch(logOutAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
