/** @format */
import React from "react";
import DashboardComponent from "@components/dashboard/DashboardComponent";
import { HoverText } from "@components/dashboard/Util";
import {
 BsArrowUpRight,
 BsBuildingAdd,
 BsCheckCircleFill,
 BsInfoCircleFill,
 BsPatchQuestion,
} from "react-icons/bs";
import { Icons } from "@assets/images";

const Billings = () => {
 const billings = [
  {
   id: 1,
   name: "Ahmed Ahmed",
   type: "Pot Funded",
   transaction_id: "pot-75483848737452",
   amount: 500,
   date: "Nov 12th, 2023",
   status: "successful",
  },
  {
   id: 2,
   name: "Ahmed Ahmed",
   type: "Wallet Funded",
   transaction_id: "wallet-12344848737452",
   amount: 250,
   date: "Nov 23rd, 2023",
   status: "successful",
  },
  {
   id: 3,
   name: "Ahmed Ahmed",
   type: "Pot Funded",
   transaction_id: "wallet-11113848737452",
   amount: 710,
   date: "Oct 3rd, 2023",
   status: "failed",
  },
  {
   id: 4,
   name: "Ahmed Ahmed",
   type: "Pot Funded",
   transaction_id: "pot-99903848737452",
   amount: 500,
   date: "Aug 2nd, 2023",
   status: "successful",
  },
  {
   id: 5,
   name: "Ahmed Ahmed",
   type: "Transfer",
   transaction_id: "bank-34403848737452",
   amount: 500,
   date: "Oct 11th, 2023",
   status: "failed",
  },
  {
   id: 6,
   name: "Ahmed Ahmed",
   type: "Pot Funded",
   transaction_id: "pot-75483848737452",
   amount: 500,
   date: "Nov 12th, 2023",
   status: "successful",
  },
  {
   id: 7,
   name: "Ahmed Ahmed",
   type: "Pot Funded",
   transaction_id: "pot-75483848737452",
   amount: 500,
   date: "Nov 12th, 2023",
   status: "successful",
  },
  {
   id: 8,
   name: "Ahmed Ahmed",
   type: "Pot Funded",
   transaction_id: "pot-75483848737452",
   amount: 500,
   date: "Nov 12th, 2023",
   status: "successful",
  },
  {
   id: 9,
   name: "Ahmed Ahmed",
   type: "Pot Funded",
   transaction_id: "pot-75483848737452",
   amount: 500,
   date: "Nov 12th, 2023",
   status: "successful",
  },
  {
   id: 10,
   name: "Ahmed Ahmed",
   type: "Pot Funded",
   transaction_id: "pot-75483848737452",
   amount: 500,
   date: "Nov 12th, 2023",
   status: "successful",
  },
  {
   id: 11,
   name: "Ahmed Ahmed",
   type: "Pot Funded",
   transaction_id: "pot-75483848737452",
   amount: 500,
   date: "Nov 12th, 2023",
   status: "successful",
  },
  {
   id: 12,
   name: "Ahmed Ahmed",
   type: "Pot Funded",
   transaction_id: "pot-75483848737452",
   amount: 500,
   date: "Nov 12th, 2023",
   status: "successful",
  },
  {
   id: 13,
   name: "Ahmed Ahmed",
   type: "Pot Funded",
   transaction_id: "pot-75483848737452",
   amount: 500,
   date: "Nov 12th, 2023",
   status: "successful",
  },
 ];
 return (
  <DashboardComponent pageTitle="">
   <div className="section-fluid">
    <div className="d-flex   align-items-center mb-5">
     <div>
      <h1 className="ls-tight mb-1 d-flex ">
       Billings
       <HoverText message="Selected Investment Plan" Icon={BsPatchQuestion} />
      </h1>
      <p className="h4 fw-normal">Have an insight financial reports</p>
     </div>
    </div>
    <div className="row">
     <div className="col-lg-8">
      <div className="card rounded-1">
       <div className="card-body">
        <div className="d-flex">
         <button className="btn rounded-1 btn-primary me-2">All bills</button>
         <button className="btn btn-secondary me-2">Pot bills</button>
         <button className="btn btn-secondary">Wallet bills</button>
        </div>

        <div className="table-responsive mt-4">
         <table className="table table-responsive table-striped">
          <thead>
           <tr>
            <th scope="col">#</th>
            <th scope="col">Name</th>
            <th scope="col">Type</th>
            <th scope="col">Transaction ID</th>
            <th scope="col">Amount</th>
            <th scope="col">Date</th>
            <th scope="col">Status</th>
            <th scope="col"></th>
           </tr>
          </thead>
          <tbody>
           {billings &&
            billings.map((billing, idx) => (
             <tr className="py-5" key={idx}>
              <th scope="row">{billing.id}</th>
              <td>{billing.name}</td>
              <td>{billing.type}</td>
              <td>{billing.transaction_id}</td>
              <td>${billing.amount}.00</td>
              <td>{billing.date}</td>
              <td>
               <span
                className={`badge bg-opacity-25 text-dark ${
                 billing.status == "failed" ? " bg-danger" : "bg-success"
                }`}
               >
                {billing.status == "failed" ? (
                 <BsInfoCircleFill className="me-2" />
                ) : (
                 <BsCheckCircleFill className="me-2" />
                )}
                {billing.status}
               </span>
              </td>
              <td>
               <BsArrowUpRight />
              </td>
             </tr>
            ))}
          </tbody>
         </table>
         <nav aria-label="Page navigation example">
          <ul className="pagination">
           <li className="page-item">
            <a href="#" className="page-link">
             Prev
            </a>
           </li>
           <li className="page-item d-sm-none">
            <span className="page-link pe-none">2 / 5</span>
           </li>
           <li className="page-item d-none d-sm-block">
            <a href="#" className="page-link">
             1
            </a>
           </li>
           <li
            className="page-item active d-none d-sm-block"
            aria-current="page"
           >
            <span className="page-link">
             2<span className="visually-hidden">(current)</span>
            </span>
           </li>
           <li className="page-item d-none d-sm-block">
            <a href="#" className="page-link">
             3
            </a>
           </li>
           <li className="page-item d-none d-sm-block">
            <a href="#" className="page-link">
             4
            </a>
           </li>
           <li className="page-item d-none d-sm-block">
            <a href="#" className="page-link">
             5
            </a>
           </li>
           <li className="page-item">
            <a href="#" className="page-link">
             Next
            </a>
           </li>
          </ul>
         </nav>
        </div>
       </div>
      </div>
     </div>
     <div className="col-lg-4">
      <section className="card rounded-1 border-0 py-1 p-md-2 p-xl-3 p-xxl-4 mb-4">
       <div className="card-body">
        <div className="d-flex align-items-center mt-sm-n1 pb-4 mb-0 mb-lg-1 mb-xl-3">
         <i className="ai-card text-primary lead pe-1 me-2"></i>
         <h2 className="h5 fw-bold mb-0">Payments Method</h2>
        </div>
        <div className="alert alert-danger d-flex mb-4">
         <i className="ai-octagon-alert fs-xl me-2"></i>
         <p className="mb-0">
          Your primary credit card expired on 01/04/2023. Please add a new card
          or update this one.
         </p>
        </div>
        <div className="row">
         <div className="col-lg-12 mb-2">
          <div className="card h-100 rounded-3 p-3 p-sm-4">
           <div className="d-flex align-items-center pb-2 mb-1">
            <h3 className="h6 text-nowrap text-truncate mb-0">
             Isabella Bocouse
            </h3>
            <span className="badge bg-success bg-opacity-25 text-primary fs-xs ms-3">
             Primary
            </span>
            <div className="d-flex ms-auto">
             <button
              className="nav-link fs-xl fw-normal py-1 pe-0 ps-1 ms-2"
              type="button"
              data-bs-toggle="tooltip"
              title="Edit"
              aria-label="Edit"
             >
              <i className="ai-edit-alt"></i>
             </button>
             <button
              className="nav-link text-danger fs-xl fw-normal py-1 pe-0 ps-1 ms-2"
              type="button"
              data-bs-toggle="tooltip"
              title="Delete"
              aria-label="Delete"
             >
              <i className="ai-trash"></i>
             </button>
            </div>
           </div>
           <div className="d-flex align-items-center">
            <svg
             width="52"
             height="42"
             viewBox="0 0 52 42"
             fill="none"
             xmlns="http://www.w3.org/2000/svg"
            >
             <path
              d="M22.6402 28.2865H18.5199L21.095 12.7244H25.2157L22.6402 28.2865ZM15.0536 12.7244L11.1255 23.4281L10.6607 21.1232L10.6611 21.124L9.27467 14.1256C9.27467 14.1256 9.10703 12.7244 7.32014 12.7244H0.8262L0.75 12.9879C0.75 12.9879 2.73586 13.3942 5.05996 14.7666L8.63967 28.2869H12.9327L19.488 12.7244H15.0536ZM47.4619 28.2865H51.2453L47.9466 12.7239H44.6345C43.105 12.7239 42.7324 13.8837 42.7324 13.8837L36.5873 28.2865H40.8825L41.7414 25.9749H46.9793L47.4619 28.2865ZM42.928 22.7817L45.093 16.9579L46.3109 22.7817H42.928ZM36.9095 16.4667L37.4975 13.1248C37.4975 13.1248 35.6831 12.4463 33.7916 12.4463C31.7469 12.4463 26.8913 13.3251 26.8913 17.5982C26.8913 21.6186 32.5902 21.6685 32.5902 23.7803C32.5902 25.8921 27.4785 25.5137 25.7915 24.182L25.1789 27.6763C25.1789 27.6763 27.0187 28.555 29.8296 28.555C32.6414 28.555 36.8832 27.1234 36.8832 23.2271C36.8832 19.1808 31.1331 18.8041 31.1331 17.0449C31.1335 15.2853 35.1463 15.5113 36.9095 16.4667Z"
              fill="#2566AF"
             />
             <path
              d="M10.6611 22.1235L9.2747 15.1251C9.2747 15.1251 9.10705 13.7239 7.32016 13.7239H0.8262L0.75 13.9874C0.75 13.9874 3.87125 14.6235 6.86507 17.0066C9.72766 19.2845 10.6611 22.1235 10.6611 22.1235Z"
              fill="#E6A540"
             />
            </svg>
            <div className="ps-3 fs-sm">
             <div className="text-dark">Visa •••• 9016</div>
             <div className="text-body-secondary">Debit - Expires 03/24</div>
            </div>
           </div>
          </div>
         </div>
        </div>
       </div>
      </section>
      <section className="card rounded-1 border-0 py-1 p-md-2 p-xl-3 p-xxl-4 mb-4">
       <div className="card-body">
        <div className="d-flex align-items-center mt-sm-n1 pb-4 mb-0 mb-lg-1 mb-xl-3">
         <BsBuildingAdd size={30} className="me-3" />
         <h2 className="h5 fw-bold mb-0">Banks</h2>
        </div>
        <div className="row">
         <div className="col-lg-12 mb-2">
          <div className="card h-100 rounded-3 p-3 p-sm-4">
           <div className="d-flex align-items-center pb-2 mb-1">
            <h3 className="h6 text-nowrap text-truncate mb-0">
             Guarantee Trust Bank
            </h3>
            <span className="badge bg-success bg-opacity-25 text-primary fs-xs ms-3">
             Primary
            </span>
            <div className="d-flex ms-auto">
             <button
              className="nav-link fs-xl fw-normal py-1 pe-0 ps-1 ms-2"
              type="button"
              data-bs-toggle="tooltip"
              title="Edit"
              aria-label="Edit"
             >
              <i className="ai-edit-alt"></i>
             </button>
             <button
              className="nav-link text-danger fs-xl fw-normal py-1 pe-0 ps-1 ms-2"
              type="button"
              data-bs-toggle="tooltip"
              title="Delete"
              aria-label="Delete"
             >
              <i className="ai-trash"></i>
             </button>
            </div>
           </div>
           <div className="d-flex align-items-center">
            <img
             src={Icons.GT_BANK}
             width={"50px"}
             className="img-fluid"
             alt=""
            />

            <div className="ps-3 fs-sm">
             <div className="text-dark">02323349494</div>
             <div className="text-body-secondary">Ahmed Ahmed</div>
            </div>
           </div>
          </div>
         </div>

         <div className="col">
          <div className="card h-100 justify-content-center align-items-center border-dashed rounded-3 py-5 px-3 px-sm-4">
           <a
            className="stretched-link d-flex align-items-center fw-semibold text-decoration-none"
            href="#addCard"
            data-bs-toggle="modal"
           >
            <i className="ai-circle-plus fs-xl me-2"></i>
            Add Bank
           </a>
          </div>
         </div>
        </div>
       </div>
      </section>
     </div>
    </div>
   </div>
  </DashboardComponent>
 );
};

export default Billings;
