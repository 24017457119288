/** @format */
import AppComponent from "@components/AppComponent";
import AuthComponent from "@components/AuthComponent";
import PAGES_URL from "@routers/routes";
import React from "react";
import { Link } from "react-router-dom";

const TryItOut = () => {
 return (
  <AuthComponent align="justify-content-center" maxWidth="65%">
   <section className="container py-5 mt-5 mb-md-2 mb-lg-3 mb-xl-4">
    <nav aria-label="breadcrumb">
     <ol className="pt-lg-3 pb-lg-4 pb-2 breadcrumb">
      <li className="breadcrumb-item">
       <Link to={PAGES_URL.HOME}>Home</Link>
      </li>
      <li className="breadcrumb-item active" aria-current="page">
       Try it
      </li>
     </ol>
    </nav>
    <div className="row">
     <div className="col-lg-7 order-lg-2">
      <div className=" h-100">
       <div className="position-relative h-100">
        <div
         className="position-absolute top-0 end-0 h-100 bg-primary rounded-5 d-none d-lg-block"
         style={{ width: "93.9%" }}
        ></div>
        <div
         className="position-absolute top-0 end-0 h-100 bg-primary rounded-4 d-lg-none"
         style={{ width: "93.9%" }}
        ></div>
        <div className="parallax h-100 z-2 overflow-hidden">
         <div
          className="parallax-layer d-flex align-items-end mb-n2 mb-lg-0"
          style={{ marginTop: "4.4%" }}
          data-depth="0.1"
         >
          <img
           src="https://static.vecteezy.com/system/resources/previews/029/284/492/original/happy-black-woman-standing-with-arms-crossed-isolated-png.png"
           alt="Girl"
          />
         </div>
        </div>
        <div className="parallax position-absolute top-0 start-0 w-100 h-100 z-2">
         <div
          className="parallax-layer d-flex align-items-end"
          style={{ paddingRight: "75.6%", paddingBottom: "70%" }}
          data-depth="-0.1"
         >
          <svg
           className="text-warning"
           xmlns="http://www.w3.org/2000/svg"
           width="239"
           height="233"
           viewBox="0 0 239 233"
           fill="none"
           xmlns:v="https://vecta.io/nano"
          >
           <g className="C">
            <g stroke="currentColor" stroke-width="1.923" className="B">
             <path d="M87.071 128.678s-2.681 19.31 1.872 35.431c5.767 20.454 27.526 48.523 31.467 47.495s-2.161-14.445-2.196-25.458c-.069-18.236 8.251-24.568 8.251-24.568l-39.394-32.9zM234.248 231.78c-11.856-4.287-14.572-17.53-16.71-27.584-1.236-5.778-2.357-11.729-6.055-17.022a26.14 26.14 0 0 0-6.714-6.622c-.844-.566-6.668-4.16-7.754-3.027 2.623-2.727 11.232 2.947 14.237 5.119 3.258 2.346 8.955 7.5 10.157 11.417.867-3.859-2.438-11.29-4.148-15.115-2.647-5.905-6.691-13.74-10.944-18.975-7.419-9.152-17.808-14.826-28.185-15.612-2.947-.22-6.148.358-8.967.867-25.539 4.599-35.269 38.493-11.741 60.126 6.286 5.778 13.832 9.302 21.632 10.446 7.385 1.075 17.831.451 22.049 9.418 1.341-5.177-5.986-15.785-11.255-19.541-2.381-1.699-5.131-3.316-7.812-4.079-2.704-.763-4.703-1.098-6.922-1.468 2.577-.658 1.283-.485 4.322-.693 2.323-.15 6.148.936 9.106 1.895 5.142 1.676 14.087 8.817 17.484 13.151 7.5 9.614 16.098 19.09 28.22 17.299z" />
             <path d="M137.176 173.155l34.129-35.448 5.994 5.771-34.129 35.448-5.994-5.771zM125.055 88.59s19.403-1.953 35.339 3.201c20.223 6.529 47.449 29.341 46.282 33.235-1.168 3.906-14.353-2.704-25.366-3.155-18.212-.751-24.857 7.327-24.857 7.327L125.055 88.59z" />
             <path d="M110.021 52.257c43.855 42.226 60.069 77.495 63.616 83.828l-38.25 39.729c-6.472-3.305-42.33-18.166-86.185-60.391C29.88 96.817 17.053 73.705 9.495 52.881L48.716 9.521c21.09 6.772 41.984 24.132 61.305 42.737z" />
             <path d="M9.334 7.859c9.823.381 22.708 2.6 36.69 7.095l-3.536 6.067c-6.448 11.048-15.381 20.432-26.105 27.4l-6.887 4.472C4.48 39.084 1.776 26.291 1.025 16.492.655 11.708 4.538 7.675 9.334 7.86zM123.31 126.077l.023-.023c2.577-2.681 8.159-.751 13.336 4.23s35.373 35.2 31.64 39.071l-.011.011-.012.012c-3.721 3.871-35.061-25.158-40.238-30.138s-7.315-10.482-4.738-13.163zM53.269 58.475c7.234-7.512 19.194-7.743 26.706-.509s7.743 19.195.508 26.706-19.195 7.742-26.706.508-7.742-19.195-.508-26.706z" />
            </g>
            <path
             d="M11.795 1.007c9.823.381 22.708 2.6 36.69 7.095l-3.536 6.067c-6.448 11.048-15.381 20.431-26.105 27.4l-6.887 4.472c-5.016-13.81-7.72-26.602-8.471-36.402a8.01 8.01 0 0 1 8.309-8.632zm113.977 118.218l.023-.023c2.577-2.681 8.158-.751 13.335 4.229 3.467 3.34 18.143 17.982 26.348 28.463 4.045 5.165 6.518 9.326 5.293 10.608l-.012.012-.011.011c-1.248 1.295-5.617-1.109-11.06-5.073-10.77-7.835-25.746-21.76-29.178-25.065-5.189-4.98-7.327-10.481-4.738-13.162z"
             className="D E B"
            />
            <use xlink:href="#B" />
           </g>
           <use xlink:href="#B" className="B D E" />
           <use xlink:href="#C" className="C" />
           <use
            xlink:href="#C"
            stroke-linecap="round"
            stroke-linejoin="round"
            className="B D E"
           />
           <path d="M165.466 151.443l14.284-14.838-5.998-5.767" className="C" />
           <path
            d="M165.466 151.443l14.284-14.838-5.998-5.767"
            className="B D E"
           />
           <path d="M139.627 166.293l5.986 5.766 14.064-14.607" className="C" />
           <path
            d="M139.627 166.293l5.986 5.766 14.064-14.607"
            className="B D E"
           />
           <use xlink:href="#D" className="C" />
           <use xlink:href="#D" className="B D E" />
           <use xlink:href="#E" className="C" />
           <use xlink:href="#E" className="B D E" />
           <use xlink:href="#F" className="C" />
           <g className="B D E">
            <use xlink:href="#F" />
            <path
             d="M55.73 51.622c7.234-7.511 19.194-7.742 26.706-.508s7.742 19.195.508 26.706-19.194 7.743-26.706.508-7.743-19.195-.508-26.706z"
             className="C"
            />
           </g>
           <defs>
            <path
             id="B"
             d="M88.839 139.945c.243 5.512.948 11.614 2.554 17.311 5.766 20.454 27.526 48.523 31.467 47.495s-2.161-14.445-2.196-25.458c-.023-7.627 1.41-13.162 3.097-17.033"
            />
            <path
             id="C"
             d="M146.526 171.123c.393 9.984 3.872 18.675 13.359 27.399 6.287 5.778 13.833 9.303 21.633 10.447 7.384 1.075 17.831.451 22.049 9.418 1.34-5.177-5.986-15.785-11.256-19.541-2.38-1.699-5.13-3.317-7.811-4.079-2.705-.763-4.704-1.098-6.923-1.468 2.577-.659 1.283-.485 4.322-.693 2.323-.151 6.148.936 9.107 1.895 5.142 1.676 14.086 8.817 17.484 13.151 7.511 9.591 16.109 19.067 28.231 17.276-11.856-4.287-14.572-17.531-16.71-27.584-1.236-5.778-2.357-11.73-6.055-17.022-1.814-2.589-4.091-4.842-6.714-6.622-.844-.566-6.564-3.213-7.754-3.028 2.993-.462 11.232 2.947 14.237 5.12 3.259 2.346 8.956 7.5 10.157 11.417.867-3.86-2.438-11.29-4.148-15.115-2.646-5.905-6.691-13.74-10.944-18.975-7.742-9.546-18.778-15.069-29.572-15.474"
            />
            <path
             id="D"
             d="M145.509 81.726c5.524.439 11.649 1.375 17.334 3.213 20.223 6.529 47.449 29.341 46.282 33.235-1.167 3.906-14.352-2.704-25.365-3.155-6.067-.254-10.851.474-14.538 1.561"
            />
            <path
             id="E"
             d="M153.448 152.761l-15.6 16.201c-2.023-1.028-6.888-3.189-13.972-6.945-8.724-4.634-20.8-11.695-35.026-22.084-11.256-8.228-23.852-18.535-37.187-31.374-19.322-18.605-32.149-41.717-39.706-62.541"
            />
            <path
             id="F"
             d="M48.486 8.09c21.09 6.772 44.664 18.698 63.998 37.314 43.855 42.226 60.068 77.494 63.616 83.827l-15.613 16.225"
            />
           </defs>
          </svg>
         </div>
         <div
          className="parallax-layer d-flex align-items-end"
          style={{ padding: "0 59.35% 70.4% 30.4%" }}
          data-depth="0.2"
         >
          <svg
           className="text-white"
           width="100"
           height="90"
           viewBox="0 0 100 90"
           fill="currentColor"
           xmlns="http://www.w3.org/2000/svg"
          >
           <path d="M25.8715 89.2559L28.5411 88.5681L25.5817 77.117L38.7756 75.0037L35.4411 62.0913L48.6485 59.9721L45.3139 47.0597L58.5079 44.9464L55.1673 32.0206L68.3747 29.9013L65.0402 16.989L78.2551 14.8502L74.553 0.457076L71.8699 1.15091L74.8352 12.6155L61.6008 14.7467L64.9549 27.6666L51.7339 29.7918L55.082 42.6982L41.8746 44.8175L45.2091 57.7299L32.0017 59.8491L35.3497 72.7555L22.1423 74.8748L25.8715 89.2559Z" />
          </svg>
         </div>
         <div
          className="parallax-layer d-flex align-items-end"
          style={{ padding: "0 11.58% 89.64% 81.28%" }}
          data-depth="-0.15"
         >
          <svg
           className="text-warning"
           width="69"
           height="55"
           viewBox="0 0 69 55"
           fill="currentColor"
           xmlns="http://www.w3.org/2000/svg"
          >
           <path d="M56.2836 55L68.9438 53.6761L68.704 51.3308L58.6187 52.3774L58.5303 40.9663L47.1702 42.1515L47.0692 30.7279L35.709 31.9131L35.6207 20.4895L24.2479 21.6747L24.1469 10.251L12.7741 11.4363L12.6731 0L0.000184965 1.29872L0.240014 3.64399L10.338 2.61004L10.4389 14.0463L21.8117 12.8611L21.9001 24.2847L33.2729 23.1121L33.3612 34.5232L44.734 33.3379L44.8224 44.7616L56.1952 43.5764L56.2836 55Z" />
          </svg>
         </div>
         <div
          className="parallax-layer d-flex align-items-end"
          style={{ padding: "0 70.94% 54.29% 24.4%" }}
          data-depth="-0.35"
         >
          <svg
           className="text-warning"
           width="45"
           height="45"
           viewBox="0 0 45 45"
           fill="currentColor"
           xmlns="http://www.w3.org/2000/svg"
          >
           <path d="M22.5089 45C34.9147 45 45 34.9187 45 22.5C45 10.0991 34.9147 0 22.5089 0C10.1031 0 -1.8272e-06 10.0813 -1.8272e-06 22.5C-1.8272e-06 34.9187 10.1031 45 22.5089 45ZM22.5089 3.35449C33.0762 3.35449 41.662 11.9548 41.662 22.5178C41.662 33.0809 33.0583 41.6812 22.5089 41.6812C11.9417 41.6812 3.33795 33.0809 3.33795 22.5178C3.33795 11.9548 11.9417 3.35449 22.5089 3.35449Z" />
          </svg>
         </div>
         <div
          className="parallax-layer d-flex align-items-end"
          style={{ padding: "0 69.6% 92.57% 26.37%" }}
          data-depth="-0.25"
         >
          <svg
           className="text-white"
           width="39"
           height="38"
           viewBox="0 0 39 38"
           fill="currentColor"
           xmlns="http://www.w3.org/2000/svg"
          >
           <path d="M27.6989 38L39 11.0113L11.3011 0L7.10921e-07 26.9886L27.6989 38ZM35.6207 12.4013L26.2724 34.7074L3.37926 25.5987L12.7276 3.2926L35.6207 12.4013Z" />
          </svg>
         </div>
         <div
          className="parallax-layer d-flex align-items-end"
          style={{ padding: "0 17.48% 74.58% 79.94%" }}
          data-depth="0.2"
         >
          <div className="ratio ratio-1x1 border border-white border-2 rounded-circle"></div>
         </div>
         <div
          className="parallax-layer d-flex align-items-end"
          style={{ padding: "0 87.38% 66.96% 11.27%" }}
          data-depth="0.15"
         >
          <div className="ratio ratio-1x1 bg-white rounded-circle"></div>
         </div>
         <div
          className="parallax-layer d-flex align-items-end"
          style={{ padding: "0 46.23% 91.84% 52.43%" }}
          data-depth="0.35"
         >
          <div className="ratio ratio-1x1 bg-warning rounded-circle"></div>
         </div>
         <div
          className="parallax-layer d-flex align-items-end"
          style={{ padding: "0 3.6% 56.17% 94.93%" }}
          data-depth="0.4"
         >
          <div className="ratio ratio-1x1 bg-warning rounded-circle"></div>
         </div>
        </div>
       </div>
      </div>
     </div>

     <div className="col-lg-5 order-lg-1">
      <div className="w-100 text-center text-lg-start mt-auto pt-lg-5 pb-5">
       <h1 className="display-3 mb-4">Try out Senga</h1>
       <p className="fs-lg pb-3 mb-4 mb-xl-5">
        We can't wait to unveil what we've been working on, and we appreciate
        your patience. Thanks for being a part of our journey!
       </p>
       <form className="">
        <select
         name=""
         id=""
         className="form-control form-control-lg mb-4 text-muted text-sm"
        >
         <option value="---------">Choose preferred interest</option>
         <option value="Investor">Investor</option>
         <option value="Customer">Customer</option>
        </select>
        <div className="input-group mb-4">
         <input
          type="text"
          className="form-control  "
          placeholder="First Name"
          required
         />
         <input
          type="text"
          className="form-control  "
          placeholder="Last Name"
          required
         />
        </div>
        <div className="input-group">
         <input
          type="email"
          className="form-control"
          placeholder="Enter your email"
          required
         />
         <button type="submit" className="btn btn-primary">
          Notify me
         </button>
        </div>
       </form>
      </div>

      <div className="w-100 mt-auto pb-5" style={{ maxWidth: "420px;" }}>
       <div className="d-flex justify-content-center justify-content-lg-start gap-3">
        <a
         className="btn btn-icon btn-sm btn-secondary btn-facebook rounded-circle"
         href="#"
         aria-label="Facebook"
        >
         <i className="ai-facebook"></i>
        </a>
        <a
         className="btn btn-icon btn-sm btn-secondary btn-instagram rounded-circle"
         href="#"
         aria-label="Instagram"
        >
         <i className="ai-instagram"></i>
        </a>
        <a
         className="btn btn-icon btn-sm btn-secondary btn-linkedin rounded-circle"
         href="#"
         aria-label="LinkedIn"
        >
         <i className="ai-linkedin"></i>
        </a>
       </div>
      </div>
     </div>
    </div>
   </section>
  </AuthComponent>
 );
};

export default TryItOut;
