/** @format */

import About from "./About.jpeg";
import _01 from "./01.jpeg";
import _02 from "./02.jpg";
import _03 from "./03.jpg";
import _04 from "./04.jpg";

const Persons = { _01, _02, _03, _04 };

export default Persons;
