/** @format */

const HOME = "/";
const ABOUT = "/about-us";
const OUR_TEAM = "/our_team";
const CUSTOMERS = "/customers";
const CAREERS = "/careers";
const FEATURES = "/features";
const FEATURES_SINGLE = "/features/single";
const FAQ = "/faq";

const HOWITWORKS = "/how-it-works";

const TERMS = "/terms";
const POLICY = "/policy";
const SECURITY = "/security";

const CONTACT_US = "/contact-us";
const LOGIN = "/login";
const SIGNUP = "/signup";
const FORGOT_PASSWORD = "/password/email";
const CONFIRM_PASSWORD = "/password/confirm";
const TRY_IT_OUT = "/try-it";

const DASHBOARD_HOME = "/dashboard";
const NOTIFICATIONS = "/dashboard/notifications";
const HELP = "/dashboard/help";
const WALLET = "/dashboard/wallet";
const DEPOSIT = "/dashboard/wallet/deposit";
const WITHDRAW = "/dashboard/wallet/withdraw";

const ACCOUNT = "/dashboard/account";
const PROFILE = "/dashboard/account/profile";
const NOTIFICATION_SETTINGS = "/dashboard/account/notification";
const PASSWORD_CHANGE = "/dashboard/account/password";
const VERIFICATION = "/dashboard/account/verification";

const BILLINGS = "/dashboard/billings";

// Invest
const EXISTING_INVESTMENT = "/dashboard/pot/existing";
const NEW_INVESTMENT = "/dashboard/pot/new";
const CREATE_POT = "/dashboard/pot/create";
const VIEW_ALL_POT = "/dashboard/pot";
const RISK_LEVEL = "/dashboard/pot/risk-level";
const REVIEW = "/dashboard/pot/review";
const POT_SUCCESS = "/dashboard/pot/success";
const DETAILS = "/dashboard/pot/details";

// Messaging
// const INBOX = "/dashboard/messages/inbox";
// const SENT = "/dashboard/messages/sent";
// const DRAFT = "/dashboard/messages/draft";
const LIST_MESSAGES = "/dashboard/messages";
// const CREATE_MESSAGE = "/dashboard/messages/new";

const PAGES_URL = {
 HOME,
 ABOUT,
 CUSTOMERS,
 CAREERS,
 FAQ,
 HOWITWORKS,
 TERMS,
 POLICY,
 SECURITY,
 CONTACT_US,
 FEATURES,
 FEATURES_SINGLE,
 LOGIN,
 SIGNUP,
 FORGOT_PASSWORD,
 CONFIRM_PASSWORD,
 TRY_IT_OUT,
 DASHBOARD_HOME,
 NOTIFICATIONS,
 BILLINGS,
 HELP,
 WALLET,
 DEPOSIT,
 WITHDRAW,
 ACCOUNT,
 PROFILE,
 VERIFICATION,
 NOTIFICATION_SETTINGS,
 PASSWORD_CHANGE,
 EXISTING_INVESTMENT,
 NEW_INVESTMENT,
 CREATE_POT,
 RISK_LEVEL,
 POT_SUCCESS,
 REVIEW,
 DETAILS,
 VIEW_ALL_POT,
 OUR_TEAM,
 //  INBOX,
 //  SENT,
 //  DRAFT,
 //  CREATE_MESSAGE,
 LIST_MESSAGES,
};

export default PAGES_URL;
