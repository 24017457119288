/** @format */

import { combineReducers } from "redux";
import authReducer from "./authReducer";
import userReducer from "./userReducer";
import globalReducer from "./globalReducer";
import potsReducer from "@redux/reducers/potsReducer";

const rootReducer = combineReducers({
 auth: authReducer,
 user: userReducer,
 global: globalReducer,
 pots: potsReducer,
});

export default rootReducer;
