/** @format */

import React from "react";
import AppComponent from "@components/AppComponent";

const Terms = () => {
 return (
  <AppComponent>
   <section className="container py-5 mt-5 mb-md-2 mb-lg-3 mb-xl-4">
    <nav aria-label="breadcrumb">
     <ol className="pt-lg-3 pb-lg-4 pb-2 breadcrumb">
      <li className="breadcrumb-item">
       <a href="index.html">Home</a>
      </li>
      <li className="breadcrumb-item">
       <a href="blog-grid.html">Support</a>
      </li>
      <li className="breadcrumb-item active" aria-current="page">
       Terms & Condition
      </li>
     </ol>
    </nav>
   </section>

   <section className="container  ">
    <div className="row justify-content-center pt-xxl-2">
     <div className="col-lg-6 col-xl-6">
      <p className="text-gray">TERMS OF USE</p>
      <h1 className=" pb-2 pb-lg-3">
       Guidelines That Govern Our Relationship With Our Partners.
      </h1>
      <p>Effective Date: January, 2020.</p>
      <br />
      <br />
      <div className="section mt-5">
       <h4 className="text-gray">Overview</h4>
       <p className="fs-lg ">
        Welcome to Sanga Investment Platform ("us," "we," or "our"). We are
        committed to respecting your privacy and safeguarding your personal
        information. This Privacy Policy outlines how we collect, use, share,
        and protect your personal data when you use our investment platform. By
        utilizing our services, you agree to the terms outlined in this Privacy
        Policy.
       </p>
      </div>
      <div className="section mt-5">
       <h4 className="text-gray">Information We Collect</h4>
       <p className="fs-lg ">
        Welcome to Sanga Investment Platform ("us," "we," or "our"). We are
        committed to respecting your privacy and safeguarding your personal
        information. This Privacy Policy outlines how we collect, use, share,
        and protect your personal data when you use our investment platform. By
        utilizing our services, you agree to the terms outlined in this Privacy
        Policy.
       </p>
      </div>
      <div className="section mt-5">
       <h4 className="text-gray">
        Lawful Use And Compliance With Applicable Laws
       </h4>
       <p className="fs-lg ">
        Welcome to Sanga Investment Platform ("us," "we," or "our"). We are
        committed to respecting your privacy and safeguarding your personal
        information. This Privacy Policy outlines how we collect, use, share,
        and protect your personal data when you use our investment platform. By
        utilizing our services, you agree to the terms outlined in this Privacy
        Policy.
       </p>
      </div>
      <div className="section mt-5">
       <h4 className="text-gray">Data Storage</h4>
       <p className="fs-lg ">
        Welcome to Sanga Investment Platform ("us," "we," or "our"). We are
        committed to respecting your privacy and safeguarding your personal
        information. This Privacy Policy outlines how we collect, use, share,
        and protect your personal data when you use our investment platform. By
        utilizing our services, you agree to the terms outlined in this Privacy
        Policy.
       </p>
      </div>
      <div className="section mt-5">
       <h4 className="text-gray">Customer Service Responsibilities</h4>
       <p className="fs-lg ">
        Welcome to Sanga Investment Platform ("us," "we," or "our"). We are
        committed to respecting your privacy and safeguarding your personal
        information. This Privacy Policy outlines how we collect, use, share,
        and protect your personal data when you use our investment platform. By
        utilizing our services, you agree to the terms outlined in this Privacy
        Policy.
       </p>
      </div>

      <div className="section mt-5">
       <h4 className="text-gray">Prohibited Use</h4>
       <p className="fs-lg ">
        Welcome to Sanga Investment Platform ("us," "we," or "our"). We are
        committed to respecting your privacy and safeguarding your personal
        information. This Privacy Policy outlines how we collect, use, share,
        and protect your personal data when you use our investment platform. By
        utilizing our services, you agree to the terms outlined in this Privacy
        <br />
        Policy. Welcome to Sanga Investment Platform ("us," "we," or "our"). We
        are committed to respecting your privacy and safeguarding your personal
        information. This Privacy Policy outlines how we collect, use, share,
        and protect your personal data when you use our investment platform. By
        utilizing our services, you agree to the terms outlined in this Privacy
        Policy.
       </p>
      </div>
      <div className="section mt-5">
       <h4 className="text-gray">Disclaimer</h4>
       <p className="fs-lg ">
        Welcome to Sanga Investment Platform ("us," "we," or "our"). We are
        committed to respecting your privacy and safeguarding your personal
        information. This Privacy Policy outlines how we collect, use, share,
        and protect your personal data when you use our investment platform. By
        utilizing our services, you agree to the terms outlined in this Privacy
        Policy.
       </p>
      </div>
     </div>
    </div>
   </section>
  </AppComponent>
 );
};

export default Terms;
