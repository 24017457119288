/** @format */

import React, { useState } from "react";
import AuthComponent from "@components/AuthComponent";
import { connect } from "react-redux";
import { registerAction } from "@redux/actions/authentication";
import { RegisterValidator } from "../../helpers/Validator";
import { hasValues } from "../../helpers/String";
import { Banner } from "@assets/images";
import { Link } from "react-router-dom";
import PAGES_URL from "@routers/routes";
import { InputField, InputPasswordField } from "@components/Inputs";
import Button from "@components/Buttons";
import { AlertResponseComponent } from "@components/AlertComponenr";

type User = {
 first_name?: string;
 last_name?: string;
 email?: string;
 password?: string;
};

const SignUp = (props: any) => {
 const [state, setState] = useState<User>({});
 const [error, setError] = useState<User>({});

 const handleInputChange = (e: any) => {
  setState((prev) => ({ ...prev, [e.target.name]: e.target.value }));
 };

 const handleSubmit = (e: any) => {
  e.preventDefault();
  const checkForErrors = RegisterValidator(state);

  if (!hasValues(checkForErrors)) {
   setError(checkForErrors);
   console.log(error);
   return;
  }

  props.register(state);
  setError({});
 };

 return (
  <>
   <AuthComponent banner={Banner.SignUp}>
    <div className="w-100 mt-auto" style={{ maxWidth: "526px" }}>
     {props.response && <AlertResponseComponent context={props.response} />}

     <h1>No account? Sign up</h1>
     <p className="pb-3 mb-3 mb-lg-4">
      Have an account already?&nbsp;&nbsp;
      <Link to={PAGES_URL.LOGIN}>Sign in here!</Link>
     </p>
     <form className="needs-validation" onSubmit={handleSubmit}>
      <div className="row row-cols-1 row-cols-sm-2">
       <div className="col mb-4">
        <InputField
         placeholder="First name"
         errorMessage={error.first_name}
         name="first_name"
         onChange={handleInputChange}
        />
       </div>
       <div className="col mb-4">
        <InputField
         placeholder="Last name"
         errorMessage={error.last_name}
         name="last_name"
         onChange={handleInputChange}
        />
       </div>
      </div>
      <div className=" mb-4">
       <InputField
        placeholder="Email address"
        errorMessage={error.email}
        name="email"
        onChange={handleInputChange}
       />
      </div>
      <div className=" mb-4">
       <InputPasswordField
        placeholder="Password"
        errorMessage={error.password}
        name="password"
        onChange={handleInputChange}
       />
      </div>

      <div className="pb-4">
       <div className="form-check my-2">
        <input className="form-check-input" type="checkbox" id="terms" />
        <label className="form-check-label ms-1" htmlFor="terms">
         I agree to{" "}
         <Link target="_blank" to={PAGES_URL.TERMS}>
          Terms &amp; Conditions
         </Link>
        </label>
       </div>
      </div>
      <Button
       className="btn-lg btn-primary w-100"
       loading={props.loading}
       onClick={handleSubmit}
      >
       Sign Up
      </Button>
     </form>
    </div>
   </AuthComponent>
  </>
 );
};

const mapStateToProps = (state: any) => ({
 loading: state.auth.isLoading,
 response: state.auth.response,
});

const mapDispatchToProps = (dispatch: any) => ({
 register: (data: any) => dispatch(registerAction(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
